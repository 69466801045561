function isJsonParsable(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function formatUserSettingsValue(value) {
  if (value === null || value === undefined || typeof value === 'undefined') {
    return null;
  }
  if (isJsonParsable(value)) {
    return JSON.parse(value);
  }
  return value;
}