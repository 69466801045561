import { FONT_SIZES, FONT_STYLES } from '../constants/fontConstants';
import { isMarkActive } from './markUtils';
export const getDefaultFontProps = editorConfig => {
  const {
    defaultFontSize,
    defaultFontStyle,
    defaultTextColor,
    usePlaintextMode
  } = editorConfig.GLOBAL_VARS || {};
  const parsedSize = defaultFontSize && parseAvailableFontSize(defaultFontSize);
  return {
    defaultFontSize: parsedSize && `${parsedSize}pt` || defaultFontSize,
    defaultFontStyle: defaultFontStyle && parseAvailableFont(defaultFontStyle) || defaultFontStyle,
    defaultTextColor,
    usePlaintextMode
  };
};
export const getDefaultFontPropsWithFallback = editorConfig => {
  const {
    defaultFontSize,
    defaultFontStyle,
    defaultTextColor
  } = getDefaultFontProps(editorConfig);
  return {
    defaultFontSize: defaultFontSize || '11pt',
    defaultFontStyle: defaultFontStyle || '',
    defaultTextColor: defaultTextColor || '#444444'
  };
};
export const createDefaultFontStyleString = ({
  defaultFontStyle,
  defaultTextColor
}) => {
  let style = '';
  style += defaultFontStyle ? `font-family: ${defaultFontStyle};` : '';
  style += defaultTextColor ? `color: ${defaultTextColor};` : '';
  return style;
};
export const getDefaultEditorStyle = editorConfig => {
  let otherStyles = 'white-space: pre-wrap;';
  const fontInfo = getDefaultFontPropsWithFallback(editorConfig);
  otherStyles += editorConfig.GLOBAL_VARS && editorConfig.GLOBAL_VARS.defaultFontSize ? '' : 'font-size: 11pt;';
  return otherStyles + createDefaultFontStyleString(fontInfo);
};
export function hasTextColor(state) {
  return isMarkActive(state, state.schema.marks.text_color);
}
export function hasFontStyle(state) {
  return isMarkActive(state, state.schema.marks.font_style);
}
export function hasFontSize(state) {
  return isMarkActive(state, state.schema.marks.font_size);
}
export function parseAvailableFont(fontStyle) {
  const foundStyle = FONT_STYLES.find(style => style.fontKey === fontStyle || style.value === fontStyle);
  return foundStyle && foundStyle.value;
}
export function parseAvailableFontSize(fontSize) {
  const sizeStr = `${fontSize}`.replace('pt', '');
  const foundSize = FONT_SIZES.find(size => size.value === sizeStr || size.text === sizeStr);
  return foundSize && foundSize.points;
}