import getIn from 'transmute/getIn';
import * as Identifiable from '../protocol/Identifiable';
import { fromJS, Record as ImmutableRecord } from 'immutable';
import PropertyValueRecord from '../property/PropertyValueRecord';
import identity from 'transmute/identity';
const EMPTY_TOSTRING = '-';
const mapProperty = (record, property) => {
  if (!record || !property) {
    return undefined;
  }
  return record.getIn(['properties', property, 'value']);
};
const join = arr => {
  const joined = arr.join(' ').trim();
  if (joined === '') {
    return null;
  }
  return joined;
};
const prepStrings = (record, {
  primary,
  secondary
}) => {
  if (!primary) {
    primary = [];
  }
  if (!secondary) {
    secondary = [];
  }
  const mapPropertyForRecord = mapProperty.bind(null, record);
  const primaryString = typeof primary === 'function' ? primary(record) : join(primary.map(mapPropertyForRecord).filter(identity));
  const secondaryString = typeof secondary === 'function' ? secondary(record) : join(secondary.map(mapPropertyForRecord).filter(identity));
  return {
    primary: primaryString,
    secondary: secondaryString
  };
};

// This interface is the shared fields that are available on all objects, both
// legacy objects and new CrmObjects. It represents the most basic level of an
// object definition and all fields in it should always be defined on an objects.
//
// This interface should directly mirror it's pure JS counterpart
// BaseObjectContents
export default function makeObjectRecord({
  idKey,
  recordName,
  objectType,
  defaults
}, toStringOptions = {}) {
  const BaseObjectRecordFactory = ImmutableRecord(defaults, recordName);
  const ObjectRecordFactory = Object.assign(BaseObjectRecordFactory, {
    _idKey: idKey,
    _objectType: objectType,
    toString(record) {
      if (!record) {
        return EMPTY_TOSTRING;
      }
      const {
        primary,
        secondary
      } = prepStrings(record, toStringOptions);
      return primary || secondary || EMPTY_TOSTRING;
    },
    toStringExpanded(record) {
      if (!record) {
        return EMPTY_TOSTRING;
      }
      const {
        primary,
        secondary
      } = prepStrings(record, toStringOptions);
      let returnString = '';
      if (primary) {
        returnString = primary;
        if (secondary) {
          return `${returnString} (${secondary})`;
        }
        return returnString;
      }
      if (secondary) {
        return secondary;
      }
      return EMPTY_TOSTRING;
    },
    fromJS(json) {
      if (json === null || json === undefined) {
        return json;
      }
      let immutable = fromJS(json);
      if (immutable.has('properties')) {
        immutable = immutable.update('properties', properties => properties.map(PropertyValueRecord.fromJS));
      }
      return ObjectRecordFactory(immutable);
    }
  });

  // getIn's type requires a mutable array
  Identifiable.getId.implement(ObjectRecordFactory, getIn(idKey));
  return ObjectRecordFactory;
}