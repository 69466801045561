'use es6';

import { COMPANY, CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { List } from 'immutable';
import { TABLE_ACTIONS } from './tableReducer';
import { isGenericAssociation } from '../associations/utils/isGenericAssociation';
import { getColumnForProperty } from '../tableFunctions';
import { objectRecords } from 'customer-data-table/constants/ObjectTypes';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';
import { useMemo, useEffect, useState } from 'react';
import AssociationCell from 'customer-data-table/cells/AssociationCell';
import CompanyRecord from 'customer-data-objects/company/CompanyRecord';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import DealRecord from 'customer-data-objects/deal/DealRecord';
import QuoteRecord from 'customer-data-objects/quote/QuoteRecord';
import Raven from 'raven-js';
import TaskRecord from 'customer-data-objects/task/TaskRecord';
import TicketRecord from 'customer-data-objects/ticket/TicketRecord';
import always from 'transmute/always';
import associationColumn from 'customer-data-table/columns/associationColumn';
import avatarColumn from '../columns/avatarColumn';
import devLogger from 'react-utils/devLogger';
import emptyFunction from 'react-utils/emptyFunction';
import get from 'transmute/get';
import isEmpty from 'transmute/isEmpty';
import memoize from 'transmute/memoize';
import protocol from 'transmute/protocol';
import genericAssociationColumn from '../columns/genericAssociationColumn';
export const getLabelColumnFromObjectType = memoize((objectType, props) => {
  const noColumn = always(undefined);
  const objectRecord = objectRecords[objectType];
  const getLabelColumn = protocol({
    name: 'getLabelColumn',
    args: [protocol.TYPE],
    fallback: () => {
      devLogger.warn({
        message: `Missing column for objectType: ${objectType}`
      });
      return noColumn();
    }
  });
  getLabelColumn.implement(CompanyRecord, () => avatarColumn(Object.assign({}, props, {
    order: 0,
    draggable: false,
    objectType: COMPANY
  })));
  getLabelColumn.implement(ContactRecord, () => avatarColumn(Object.assign({}, props, {
    order: 0,
    draggable: false,
    objectType: CONTACT
  })));
  getLabelColumn.implement(DealRecord, noColumn);
  getLabelColumn.implement(TicketRecord, noColumn);
  getLabelColumn.implement(QuoteRecord, noColumn);
  getLabelColumn.implement(TaskRecord, noColumn);
  const labelColumn = getLabelColumn(objectRecord);
  return labelColumn;
});
export const getColumnRecord = memoize((column, objectProperties, getColumnProps = emptyFunction, objectType, getIsUngated, restrictedProperties = new List()) => {
  const columnName = get('name', column);
  const order = get('order', column);
  const sortable = get('sortable', column);
  const property = get(columnName, objectProperties);
  // HACK: For more info see: https://git.hubteam.com/HubSpot/customer-data-table/pull/341
  const UNSAFE_flexWidth = get('UNSAFE_flexWidth', column);
  const width = get('width', column);
  const isRestrictedProperty = restrictedProperties.includes(columnName);
  const options = {
    UNSAFE_flexWidth,
    getColumnProps,
    name: columnName,
    objectType,
    order,
    property,
    sortable,
    width,
    getIsUngated,
    isRestrictedProperty
  };
  if (property) {
    return getColumnForProperty(options);
  }
  if (isGenericAssociation(columnName)) {
    return genericAssociationColumn(Object.assign({
      associationDefinition: get('associationDefinition', column),
      label: get('label', column)
    }, options));
  }
  if (columnName === 'relatesTo') {
    return associationColumn(Object.assign({}, options, {
      Cell: AssociationCell,
      Header: propertyLabelTranslator('Associated With'),
      // hard-coded, HubSpot-defined
      accessor: record => get('associations', record),
      id: 'relatesTo',
      sortable: false
    }));
  }
  if (columnName === 'relatesToCompany') {
    return associationColumn(Object.assign({}, options, {
      Cell: AssociationCell,
      Header: propertyLabelTranslator('Associated Company'),
      // hard-coded, HubSpot-defined
      accessor: record => get('associations', record),
      id: 'relatesToCompany',
      objectType: COMPANY,
      sortable: false
    }));
  }
  if (columnName === 'relatesToContact') {
    return associationColumn(Object.assign({}, options, {
      Cell: AssociationCell,
      Header: propertyLabelTranslator('Associated Contacts'),
      // hard-coded, HubSpot-defined
      accessor: record => get('associations', record),
      id: 'relatesToContact',
      objectType: CONTACT,
      sortable: false
    }));
  }
  return null;
});
export const usePropertyColumns = ({
  objectProperties,
  objectType,
  dispatcher,
  columns,
  getColumnProps = emptyFunction,
  propertyColumns: propertyColumnNamesList,
  restrictedProperties,
  columnWidths,
  minColumnWidth,
  columnsProp,
  labelColumn: _labelColumn,
  getIsUngated
}) => {
  if (!objectProperties) {
    devLogger.warn('usePropertyColumns requires objectProperties');
    Raven.captureException('usePropertyColumns requires objectProperties', {
      extra: {
        columns,
        propertyColumns: propertyColumnNamesList
      }
    });
  }
  const [createColumnRecord, setCreateColumnRecord] = useState(() => column => getColumnRecord(column, objectProperties, getColumnProps, objectType, getIsUngated, restrictedProperties));
  useEffect(() => {
    return setCreateColumnRecord(() => column => getColumnRecord(column, objectProperties, getColumnProps, objectType, getIsUngated, restrictedProperties));
  }, [objectProperties, objectType, getColumnProps, getIsUngated, restrictedProperties]);
  const [propertyColumns] = useState(() => List(propertyColumnNamesList.map(createColumnRecord)).filter(Boolean));
  useEffect(() => {
    if (!isEmpty(columns) && propertyColumnNamesList) {
      const uniqueColumnNames = [];
      const changedColumns = propertyColumnNamesList.reduce((acc, _propertyColumn) => {
        const name = _propertyColumn.get('name');
        // HACK: https://git.hubteam.com/HubSpot/customer-data-table/pull/233
        if (uniqueColumnNames.includes(name)) {
          return acc;
        } else {
          uniqueColumnNames.push(name);
        }
        const existingColumn = columns.find(c => c && c.get('id') === name);
        const hasNewColumnOrder = existingColumn && _propertyColumn.get('order') !== existingColumn.get('order');
        if ((!existingColumn || hasNewColumnOrder) && name !== 'name') {
          let newColumn = createColumnRecord(_propertyColumn);
          if (existingColumn) {
            newColumn = newColumn.set('width', existingColumn.get('width'));
          }
          if (newColumn) {
            return acc.push(newColumn);
          }
        }
        return acc;
      }, List());
      if (changedColumns.count() > 0) {
        dispatcher({
          type: TABLE_ACTIONS.UPDATE,
          value: changedColumns
        });
      }
    }
  }, [columns, createColumnRecord, dispatcher, propertyColumnNamesList]);
  const labelColumnFromObjectType = useMemo(() => getLabelColumnFromObjectType(objectType, getColumnProps('label')), [getColumnProps, objectType]);
  const labelColumn = _labelColumn === undefined ? labelColumnFromObjectType : _labelColumn;
  useEffect(() => {
    dispatcher({
      type: TABLE_ACTIONS.INITIALIZE,
      value: {
        widths: columnWidths,
        minColumnWidth,
        columns: propertyColumns,
        labelColumn
      }
    });
  }, [minColumnWidth, columnWidths, dispatcher, labelColumn, propertyColumns]);
  useEffect(() => {
    if (propertyColumnNamesList && (propertyColumnNamesList.size || !columnsProp.isEmpty()) && !columns.isEmpty()) {
      const columnsToRemove = columns.reduce((acc, column) => {
        const id = column.get('id');
        if (id !== '_selector' && id !== 'name' && !propertyColumnNamesList.find(x => x.get('name') === id) && !columnsProp.find(x => x.get('id') === id)) {
          return acc.push(id);
        }
        return acc;
      }, List());
      if (!columnsToRemove.isEmpty()) {
        dispatcher({
          type: TABLE_ACTIONS.REMOVE,
          value: columnsToRemove
        });
      }
    }
  }, [columnWidths, columns, columnsProp, dispatcher, labelColumn, propertyColumnNamesList, propertyColumns]);
};