import { Plugin } from 'prosemirror-state';
import Raven from 'raven-js';
import { addTemporaryImageNodes, isImageFile } from '../utils/imageUtils';
export function createImagePastePlugin({
  fileManagerAccess
}) {
  const ImagePastePlugin = new Plugin({
    props: {
      handlePaste: (view, event) => {
        if (event.clipboardData) {
          const files = event.clipboardData.files;
          if (files.length && !event.clipboardData.getData('text')) {
            const file = files.item(0);
            if (file && isImageFile(file)) {
              void addTemporaryImageNodes(view.state, view.dispatch, [file], {
                fileManagerAccess
              }).catch(e => {
                Raven.captureException(e);
              });
              return true;
            }
          }
        }
        return false;
      }
    }
  });
  return ImagePastePlugin;
}