import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { RELATIVE_REMINDERS, REMINDERS, SEQUENCE_STEP_ENROLLMENT_ID } from 'customer-data-objects/task/TaskPropertyNames';
import { fromJS } from 'immutable';
import { reminderCategoryToUnitAndCount } from 'task-forms-lib/utils/formatRelativeDatePresets';
import memoize from 'transmute/memoize';
import merge from 'transmute/merge';
import { SequencesApi } from '../api/sequences';
import { bulkFetchTasks as bulkFetchTasksRequest } from '../api/tasks';
export const TASK_DEPENDENCY_EMPTY = null;
const checkIsTaskPausingSequence = taskUids => {
  return SequencesApi.getStepEnrollmentsSummaries(taskUids);
};
const getSequenceStepEnrollmentIdsFromTasks = memoize(tasks => {
  return tasks.reduce((sequenceStepIds, task) => {
    const sequenceStepEnrollmentId = getProperty(task, SEQUENCE_STEP_ENROLLMENT_ID);
    if (!sequenceStepEnrollmentId) {
      return sequenceStepIds;
    }
    return Object.assign({}, sequenceStepIds, {
      [task.objectId]: sequenceStepEnrollmentId
    });
  }, {});
});
export const checkTaskDependencyPausingSequence = (tasks = [], logFunction = () => {}) => {
  if (tasks.length === 0) {
    return Promise.resolve(TASK_DEPENDENCY_EMPTY);
  }
  const taskSequenceStepEnrollmentIds = Object.values(getSequenceStepEnrollmentIdsFromTasks(tasks));
  return checkIsTaskPausingSequence(taskSequenceStepEnrollmentIds).then(taskDependencyStep => {
    return taskDependencyStep;
  }).catch(error => {
    console.error(error);
    logFunction(false, {
      error,
      functionName: 'useTaskDependencyPausingSequenceCheck'
    });
    return TASK_DEPENDENCY_EMPTY;
  });
};
export const bulkFetchTasksAndSequenceCheck = (taskIds, count, {
  logFunction,
  onFailure
}) => {
  return bulkFetchTasksRequest(taskIds, [SEQUENCE_STEP_ENROLLMENT_ID]).then(tasks => {
    return checkTaskDependencyPausingSequence(tasks, logFunction);
  }).catch(error => {
    if (logFunction) {
      logFunction(false, {
        error,
        count,
        functionName: 'bulkFetchTasks'
      });
    }
    onFailure({
      count,
      error
    });
  });
};

// task form utils
export const setReminder = ({
  task: __task,
  field: __field,
  event,
  updates,
  value
}) => {
  const {
    category
  } = event;
  const reminders = reminderCategoryToUnitAndCount([category]);
  const newUpdates = fromJS({
    [REMINDERS]: value,
    [RELATIVE_REMINDERS]: reminders
  });
  const mergedUpdates = merge(newUpdates, updates);
  return mergedUpdates;
};