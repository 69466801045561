import { useCallback } from 'react';
import { OPEN_MODAL } from '../constants/actionTypes';
import { useBulkActionsDispatch } from './useBulkActionsDispatch';
export const useBulkActionsOpenModal = () => {
  const dispatch = useBulkActionsDispatch();
  return useCallback(args => {
    dispatch({
      type: OPEN_MODAL,
      payload: args
    });
  }, [dispatch]);
};