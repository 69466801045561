import { COMPLETED } from 'customer-data-objects/engagement/EngagementStatusTypes';
import { getId, getProperty } from 'customer-data-objects/model/ImmutableModel';
import { STATUS } from 'customer-data-objects/task/TaskPropertyNames';
import { mergeTasksWithAssociations } from '../utils/mergeTask';
import { DEFAULT_PAGE_SIZE } from '../../constants/configConstants';
export const MERGE_TASK_QUEUE_BAR_STATE = 'MERGE_TASK_QUEUE_BAR_STATE';
export const MERGE_TASK_IDS = 'MERGE_TASK_IDS';
export const GO_TO_TASK = 'GO_TO_TASK';
export const EXIT_QUEUE = 'EXIT_QUEUE';
export const RESCHEDULE_TASK = 'RESCHEDULE_TASK';
export const REACHED_END_OF_TASKS = 'REACHED_END_OF_TASKS';

// See customer-data-tracking/tracking/tasks.yaml
export const DEFAULT_QUEUE_TYPE = 'view';
export const QUEUE_BAR_BASE_CONFIG = {
  crmSearchQuery: null,
  // Used for adjusting the CRMSearch query offset if need be. Generally avoid setting this directly within the action payload and just have reducer handle updating it
  // No guaranteed ordering
  completedTaskIds: [],
  currentTask: null,
  currentTaskId: null,
  pageSize: DEFAULT_PAGE_SIZE,
  queueProgressIndex: -1,
  queueType: DEFAULT_QUEUE_TYPE,
  redirectUrl: null,
  rescheduledTaskIds: [],
  taskIds: [],
  title: '',
  total: 0
};
function getIdInt(task) {
  const taskId = getId(task);
  if (taskId) {
    return typeof taskId === 'string' ? parseInt(taskId, 10) : taskId;
  } else {
    return null;
  }
}
function mergeTaskIds(state, action) {
  const {
    taskIds: newTaskIds
  } = action;
  const {
    taskIds: originalTaskIds
  } = state;
  const newTaskIdsMinusOriginalTaskIds = newTaskIds.filter(taskId => !originalTaskIds.includes(taskId));
  return Object.assign({}, state, {
    taskIds: [...originalTaskIds, ...newTaskIdsMinusOriginalTaskIds]
  });
}
function mergeCompletedTaskIds(originalCompletedTaskIds, newCompletedTaskIds) {
  if (!newCompletedTaskIds) {
    return originalCompletedTaskIds;
  }
  return Array.from(new Set([...originalCompletedTaskIds, ...newCompletedTaskIds]));
}
function adjustCompletedTaskIdsForTask(originalCompletedTaskIds, task) {
  const taskId = getIdInt(task);
  const isComplete = getProperty(task, STATUS) === COMPLETED;
  if (isComplete) {
    return taskId ? mergeCompletedTaskIds(originalCompletedTaskIds, [taskId]) : originalCompletedTaskIds;
  } else {
    if (taskId) {
      return originalCompletedTaskIds.filter(completedTaskId => completedTaskId !== taskId);
    }
    return originalCompletedTaskIds;
  }
}
function adjustCompletedTaskIds(oldCompletedTaskIds, newState) {
  let newCompletedTaskIds = oldCompletedTaskIds;
  if (!oldCompletedTaskIds || !newState) {
    return oldCompletedTaskIds;
  }
  if (newState.completedTaskIds) {
    newCompletedTaskIds = mergeCompletedTaskIds(newCompletedTaskIds, newState.completedTaskIds);
  }
  if (newState.currentTask) {
    newCompletedTaskIds = adjustCompletedTaskIdsForTask(newCompletedTaskIds, newState.currentTask);
  }
  return newCompletedTaskIds;
}
export function taskQueueBarReducer(state, action) {
  switch (action.type) {
    case REACHED_END_OF_TASKS:
      {
        // We have reached the end of our search results so we reset the total to the number of tasks we have
        return Object.assign({}, state, {
          total: state.taskIds.length
        });
      }
    case MERGE_TASK_QUEUE_BAR_STATE:
      {
        const completedTaskIds = adjustCompletedTaskIds(state.completedTaskIds, action.state);
        if (action.state && action.state.currentTask) {
          const currentTaskId = getIdInt(action.state.currentTask);
          if (!currentTaskId) {
            return state;
          }
          const queueProgressIndex = (action.state.taskIds || state.taskIds).indexOf(currentTaskId);
          if (queueProgressIndex < 0) {
            return state;
          }

          // If currentTask in action is different task than what's current in state, should override values in state and not merge associations
          if (currentTaskId !== state.currentTaskId) {
            return Object.assign({}, state, action.state, {
              completedTaskIds,
              currentTaskId,
              queueProgressIndex
            });
          }
          // If currentTask in action is same ID as that in state, should merge association data and ensure currentTaskId, queueProgressIndex are updated correctly
          return Object.assign({}, state, action.state, {
            completedTaskIds,
            currentTask: state.currentTask ? mergeTasksWithAssociations(state.currentTask, action.state.currentTask) : action.state.currentTask,
            currentTaskId: getIdInt(action.state.currentTask),
            queueProgressIndex
          });
        }
        return Object.assign({}, state, action.state, {
          completedTaskIds
        });
      }
    case MERGE_TASK_IDS:
      return mergeTaskIds(state, action);
    case GO_TO_TASK:
      {
        const currentTaskId = getIdInt(action.task);
        if (!currentTaskId) {
          return state;
        }
        const queueProgressIndex = state.taskIds.indexOf(currentTaskId);
        if (queueProgressIndex < 0) {
          return state;
        }
        return Object.assign({}, state, {
          currentTask: action.task,
          currentTaskId,
          queueProgressIndex
        });
      }
    case RESCHEDULE_TASK:
      return Object.assign({}, state, {
        rescheduledTaskIds: [...state.rescheduledTaskIds, action.taskId]
      });
    case EXIT_QUEUE:
      return QUEUE_BAR_BASE_CONFIG;
    default:
      return state;
  }
}