import { fetchSearchTasksWithAssociations } from '../api/fetchSearchTasks';
import { DEFAULT_PAGE_SIZE } from '../constants/configConstants';
import { getStateByKey } from '../state/getState';
import { createTaskQueueConfig } from '../storage/createTaskQueueConfig';
import { validateStorageShape } from '../storage/validateStorageShape';
import { getSessionStorageKey } from '../TaskQueueBar/utils/getSessionStorageKey';
/**
 * Call this function to run a search and store the resulting task
 * queue config in the given storage mechanism.
 * @param {Object} apiClient - An API Client with a post, put, get, and delete method.
 * @param {*} storage - an implementation of the Storage interface with a setItem, getItem, and removeItem function
 * @returns {function(object)} - A curried function you should call to execute the search.
 *    Takes an object with keys for a crmSearchQuery, title, firstTaskId, pageSize, and redirectUrlForExitQueue.
 */
export const startTaskQueue = (apiClient, storage) => ({
  crmSearchQuery,
  firstTaskId,
  pageSize = DEFAULT_PAGE_SIZE,
  queueType,
  // this URL will be used to provide a link back to the originating app after a queue is finished
  redirectUrlForExitQueue,
  title
}) => {
  validateStorageShape(storage);
  return fetchSearchTasksWithAssociations(apiClient)(Object.assign({}, crmSearchQuery, {
    count: pageSize
  })).then(crmSearchResponse => {
    createTaskQueueConfig(storage)({
      crmSearchQuery,
      crmSearchResponse,
      firstTaskId,
      pageSize,
      queueType,
      redirectUrlForExitQueue,
      title
    });
    return getStateByKey(storage, getSessionStorageKey());
  });
};