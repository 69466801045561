import { createSelector } from 'reselect';
import memoize from 'transmute/memoize';
import { isFilterSetNonDefault } from '../../utils/crmSearchFilters';
export const tempViewStateSelector = state => state.tempViewState;
export const getHasNonDefaultFiltersSelector = createSelector(tempViewStateSelector, tempViewState => memoize(viewId => {
  if (!(viewId in tempViewState)) {
    return false;
  }
  const {
    filters,
    defaultFilters
  } = tempViewState[viewId];
  return isFilterSetNonDefault(filters, defaultFilters);
}));