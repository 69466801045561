import { createSelector } from 'reselect';
import memoize from 'transmute/memoize';
export const tasksSelector = state => state.tasks;
export const getTasksByIdsSelector = createSelector(tasksSelector, tasksState => memoize(taskIds => {
  if (!taskIds) {
    return [];
  }
  return taskIds.map(taskId => tasksState[taskId]).filter(task => Boolean(task));
}));
export const getTaskByIdSelector = createSelector(getTasksByIdsSelector, getTasksByIds => memoize(taskId => {
  const [first] = getTasksByIds([taskId]);
  return first;
}));