import { CONTACT_TIMEZONE } from 'customer-data-objects/task/TaskPropertyNames';
import memoize from 'transmute/memoize';
import getIn from 'transmute/getIn';
import { CONTACT_TIMEZONE_PROPERTY_NAME } from '../api/properties';

/**
 * Update property definitions to populate rollup property options and update property types
 * Currently only updates the task's associated contact timezone property definition
 */
export const updateTimezonePropertyDefinition = memoize(properties => {
  // hs_task_contact_timezone is a rollup property of the CONTACT hs_timezone property
  // - The property type comes back from API as string but should be enumeration
  // - As it's a rollup property, the options array is empty and so need to populate with hs_timezone options
  const updatedContactTimezoneProperty = properties[CONTACT_TIMEZONE] ? properties[CONTACT_TIMEZONE_PROPERTY_NAME] ? properties[CONTACT_TIMEZONE].set('type', 'enumeration').set('options', getIn([CONTACT_TIMEZONE_PROPERTY_NAME, 'options'], properties)) : properties[CONTACT_TIMEZONE].set('type', 'enumeration') : undefined;
  if (updatedContactTimezoneProperty) {
    return Object.assign({}, properties, {
      [CONTACT_TIMEZONE]: updatedContactTimezoneProperty
    });
  } else {
    return properties;
  }
});