import { Record } from 'immutable';
import QuoteInboundDbRecord from './QuoteInboundDbRecord';
import QuoteAssociatedObjects from './QuoteAssociatedObjects';
import QuoteAssociations from './QuoteAssociations';
import { mergeQuoteProperties } from './model/QuoteModel';
const defaults = {
  quote: QuoteInboundDbRecord(),
  associations: new QuoteAssociations(),
  quoteAssociatedObjects: new QuoteAssociatedObjects()
};
export default class QuoteRecord extends Record(defaults) {
  static fromJS(json) {
    return new QuoteRecord({
      quote: QuoteInboundDbRecord.fromJS(json.quote),
      associations: QuoteAssociations.fromJS(json.associations),
      quoteAssociatedObjects: QuoteAssociatedObjects.fromJS(json.quoteAssociatedObjects)
    });
  }
  static hydrateQuote(quote, CRMContacts, CRMCompany, lineItems) {
    return mergeQuoteProperties(quote, {
      recipientContacts: CRMContacts,
      recipientCompany: CRMCompany || null,
      lineItems
    });
  }
}