import { EQUAL, IN, LESS_OR_EQUAL, NOT_EQUAL, NOT_IN, NOT_KNOWN } from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import { COMPLETED } from 'customer-data-objects/engagement/EngagementStatusTypes';
import { LINKED_IN, LINKED_IN_MESSAGE } from 'customer-data-objects/engagement/TaskTypes';
import { CREATED_DATE, DUE_DATE, OWNER_ID, QUEUE_IDS, SEQUENCE_STEP_ORDER, STATUS, TYPE } from 'customer-data-objects/task/TaskPropertyNames';
import { NOW_PLACEHOLDER } from '../constants/filters';
import { ME_OWNER } from '../constants/viewFilters';
import { processFiltersForSearchRequest } from './processFiltersForSearchRequest';
export const INCOMPLETE_TASK = {
  property: STATUS,
  operator: NOT_IN,
  values: [COMPLETED]
};
export const COMPLETED_TASK = {
  property: STATUS,
  operator: IN,
  values: [COMPLETED]
};
export function getCreatedBeforeNowFilter() {
  const timestamp = Date.now();
  return {
    property: CREATED_DATE,
    operator: LESS_OR_EQUAL,
    value: timestamp
  };
}
export function getOwnerIdFilter(ownerIds) {
  if (Array.isArray(ownerIds)) {
    return {
      property: OWNER_ID,
      operator: IN,
      values: ownerIds
    };
  }
  return {
    property: OWNER_ID,
    operator: IN,
    values: [ownerIds]
  };
}
export const OWNED_BY_CURRENT_USER = getOwnerIdFilter(ME_OWNER);
export function getNoOwnerFilter() {
  return {
    property: OWNER_ID,
    operator: NOT_KNOWN
  };
}
export function getTaskTypeFilter(taskTypeValue) {
  if (Array.isArray(taskTypeValue)) {
    return {
      property: TYPE,
      operator: IN,
      values: taskTypeValue
    };
  }
  if (taskTypeValue === LINKED_IN_MESSAGE) {
    return {
      property: TYPE,
      operator: IN,
      values: [LINKED_IN_MESSAGE, LINKED_IN]
    };
  }
  return {
    property: TYPE,
    operator: IN,
    values: [taskTypeValue]
  };
}
export function getDueDateFilter(dueDateFilter) {
  return Object.assign({}, dueDateFilter, {
    property: DUE_DATE
  });
}
export function getQueueMembershipIdsFilter(queueIds) {
  return {
    property: QUEUE_IDS,
    operator: IN,
    values: queueIds
  };
}

/**
 * Returns true if input represents a list of filters that does not filter on task status
 * Assumes that filters are combined via AND
 * @param {Array[Object]} filters list of crm filters of format { property: ..., operator: ..., value: ...}
 * @returns true if task status filter is not applied
 */
export function isTaskStatusFilterApplied(filters) {
  return filters.some(filter => filter.property === STATUS);
}
export function getObjectIdInListFilter(taskIds) {
  return {
    property: 'hs_object_id',
    operator: IN,
    values: taskIds
  };
}

/**
 * Returns true if input represents a list of filters that filters out completed tasks
 * Assumes that filters are combined via AND
 * @param {Array[Object]} filters list of crm filters of format { property: ..., operator: ..., value: ...}
 * @returns true if completed tasks will be filtered out by list of filters
 */
export function isIncompleteTasksFilterApplied(filters) {
  if (!filters || !isTaskStatusFilterApplied(filters)) {
    return false;
  }
  const filterExcludingCompletedTasks = filters.filter(filter => {
    return filter && filter.property === STATUS;
  }).find(filter => {
    switch (filter.operator) {
      case EQUAL:
        return filter.value !== COMPLETED;
      case NOT_EQUAL:
        return filter.value === COMPLETED;
      case IN:
        return filter.values && !filter.values.includes(COMPLETED);
      case NOT_IN:
        return filter.values && filter.values.includes(COMPLETED);
      default:
        return false;
    }
  });
  return Boolean(filterExcludingCompletedTasks);
}
export function isFilteringForOnlyCompletedTasks(filters) {
  return filters.some(filter => {
    if (filter.property !== STATUS) {
      return false;
    }
    switch (filter.operator) {
      case EQUAL:
        return filter.value === COMPLETED;
      case IN:
        return filter.values && filter.values.length === 1 && filter.values[0] === COMPLETED;
      default:
        return false;
    }
  });
}
function arraysAreEqual(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return arr1 === arr2;
  }
  return arr1.length === arr2.length && arr1.every(value => arr2.includes(value));
}

// This comparison does not do deep comparisons of filter value arrays
export function areFiltersDifferent(filterObject1, filterObject2) {
  if (!filterObject1 || !filterObject2) {
    return false;
  }
  return filterObject1.operator !== filterObject2.operator || filterObject1.value !== filterObject2.value || filterObject1.highValue !== filterObject2.highValue || !arraysAreEqual(filterObject1.values, filterObject2.values);
}
export function isFilterSetNonDefault(currentCrmSearchFilters, defaultCrmSearchFilters) {
  // The filters are definitely non-default if the arrays have different lengths
  if (currentCrmSearchFilters.length !== defaultCrmSearchFilters.length) {
    return true;
  }
  const currentFiltersWithDifferentReferences = currentCrmSearchFilters.filter(filter => !defaultCrmSearchFilters.includes(filter));

  // the filters are definitely the default ones if the filters have referential equality
  if (currentFiltersWithDifferentReferences.length === 0) {
    return false;
  }

  // doing this ahead of time keeps this function O(n) instead of O(n^2)
  // process placeholders before comparing as default placeholders are converted to actual
  // date values before getting passed to the filter panel
  const defaultFiltersAsMap = processFiltersForSearchRequest({
    filters: defaultCrmSearchFilters,
    excludePlaceholders: [NOW_PLACEHOLDER],
    addTaskFamily: true
  }).reduce((filterMap, curFilter) => {
    return Object.assign({}, filterMap, {
      [curFilter.property]: curFilter
    });
  }, {});

  // return true when you find the first filter that has different values
  return Boolean(currentFiltersWithDifferentReferences.find(filter => {
    const defaultFilter = defaultFiltersAsMap[filter.property];
    if (!defaultFilter) {
      return true;
    }
    return areFiltersDifferent(filter, defaultFilter);
  }));
}

// Sequence steps are zero-indexed on the backend, which isn't intuitive to end users.
// This function allows us to switch between 0-based and 1-based sequence step indicies.
function switchSequenceStepFilterIndexing(filters, toZeroIndex) {
  const shiftVal = toZeroIndex ? -1 : 1;
  return filters.map(filter => {
    if (filter.property === SEQUENCE_STEP_ORDER) {
      const shiftedValues = Object.assign({}, filter);
      if ('highValue' in filter) {
        shiftedValues.highValue = filter.highValue + shiftVal;
      }
      if ('value' in filter) {
        shiftedValues.value = filter.value + shiftVal;
      }
      return shiftedValues;
    }
    return filter;
  });
}
export function zeroIndexSequenceStepFilter(filters) {
  return switchSequenceStepFilterIndexing(filters, true);
}
export function oneIndexSequenceStepFilter(filters) {
  return switchSequenceStepFilterIndexing(filters, false);
}