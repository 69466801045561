import { parse } from 'hub-http/helpers/params';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export function useQueryParams() {
  const {
    search
  } = useLocation();
  const queryParams = useMemo(() => {
    if (!search) {
      return {};
    }
    return parse(search.substring(1));
  }, [search]);
  return queryParams;
}

/**
 * @returns {String} A formatted string starting with a "?" to be appended to a URL
 */
export function useQueryParamString() {
  const {
    search
  } = useLocation();
  return search || '';
}