'use es6';

import { fromJS, Map as ImmutableMap, Record, Seq } from 'immutable';
import invariant from 'react-utils/invariant';
import isFunction from 'transmute/isFunction';
import isInstanceOf from 'transmute/isInstanceOf';
import isObject from 'transmute/isObject';
import PaginatedSearchResult from './PaginatedSearchResult';
import get from 'transmute/get';
function _enforceFunction(name, value) {
  invariant(isFunction(value), 'expected `%s` to be a function but got `%s`', name, value);
  return value;
}
const PaginatedSearchResponse = Record({
  objects: ImmutableMap(),
  searchResult: PaginatedSearchResult()
}, 'PaginatedSearchResponse');
const defaultGetHasMore = get('has-more');
const defaultGetOffset = get('offset');
const defaultGetTotal = get('total');

/**
 * Creates a PaginatedSearchResponse from the JSON structure returned by hubspot
 * elastic search endpoints.
 *
 * @param {(response: Object) => Array<Object>} options.getObjects
 * @param {(object: Object) => string} options.getObjectId
 * @param {?Function} options.objectFromJS
 * @return {(response: Object) => PaginatedSearchResponse}
 */
PaginatedSearchResponse.fromElasticSearch = ({
  getHasMore = defaultGetHasMore,
  getOffset = defaultGetOffset,
  getTotal = defaultGetTotal,
  getObjects,
  getObjectId,
  objectFromJS = fromJS
}) => {
  _enforceFunction('getObjects', getObjects);
  _enforceFunction('getObjectId', getObjectId);
  _enforceFunction('objectFromJS', objectFromJS);
  return query => response => {
    if (!response) {
      return response;
    }
    const objects = getObjects(response);
    return PaginatedSearchResponse({
      objects: objects.reduce((acc, object) => acc.set(`${getObjectId(object)}`, objectFromJS(object)), ImmutableMap()),
      searchResult: PaginatedSearchResult({
        query,
        results: Seq(objects).map(getObjectId).toList(),
        hasMore: getHasMore(response),
        offset: getOffset(response),
        total: getTotal(response)
      })
    });
  };
};
PaginatedSearchResponse.fromJS = json => {
  if (!isObject(json)) {
    return json;
  }
  return PaginatedSearchResponse({
    objects: fromJS(json.objects) || ImmutableMap(),
    searchResult: PaginatedSearchResult.fromJS(json.searchResult)
  });
};
PaginatedSearchResponse.isPaginatedSearchResponse = isInstanceOf(PaginatedSearchResponse);
export default PaginatedSearchResponse;