import http from 'hub-http/clients/apiClient';
import { formatFetchUserSettingsResponse } from '../formatters/formatFetchUserSettingsResponse';
import { formatUserSettingsValue } from '../formatters/formatUserSettingsValue';
import { GROUP_NAMES, reportNewTasksError } from '../tracking/errors';
import { UserAttributesClient } from 'frontend-preferences-client';
export const userAttributesClient = UserAttributesClient.forCaller('crm-tasks-ui');
const BASE_URL = 'users/v1/app/attributes';

/**
 * Taken from crm_data/settings/UserSettingsSerialization
 * The backend enforces a character limit each value.
 */
const MAX_DATA_LENGTH = 1000;
export function _encodeSettingsValue(value) {
  try {
    value = JSON.stringify(value);
  } finally {
    const length = typeof value === 'string' ? value.length : 0;
    if (length > MAX_DATA_LENGTH) {
      reportNewTasksError(new Error(`Expected encodedValue.length to be less than ${MAX_DATA_LENGTH}`), {
        groupName: GROUP_NAMES.USER_SETTINGS,
        functionName: '_encodeSettingsValue',
        extra: {
          value
        }
      });
    }
  }
  return value;
}
export function fetchUserSettings(keys) {
  const keysToFetch = Object.fromEntries(keys.map(key => [key, '']));
  return userAttributesClient.batchFetch(keysToFetch).then(formatFetchUserSettingsResponse);
}
export function saveUserSetting(key, value) {
  return http.post(`${BASE_URL}`, {
    data: {
      key,
      value: _encodeSettingsValue(value)
    }
  }).then(resp => {
    return Object.assign({}, resp, {
      value: formatUserSettingsValue(resp.value)
    });
  });
}