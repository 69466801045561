// @ts-expect-error untyped
import SidekickExtensionInstallHelpers from 'ExtensionInstallHelpers.SidekickExtensionInstallHelpers';
import requiredData from 'customer-data-tracking/constants/requiredData';
const {
  CHROME_EXTENSION_INSTALLED
} = requiredData;
const isWordPress = () => /(^|\?|&)wp=/.test(window.location.search);
export function get() {
  return {
    [CHROME_EXTENSION_INSTALLED]: SidekickExtensionInstallHelpers.hasExtension(),
    'wordpress-plugin': isWordPress()
  };
}