import { Plugin } from 'prosemirror-state';
export const initialState = {
  scrollToPos: null
};
export const ScrollViewPlugin = new Plugin({
  state: {
    init() {
      return initialState;
    },
    apply(tr) {
      const meta = tr.getMeta(ScrollViewPlugin);
      if (meta) {
        return meta;
      }
      return initialState;
    }
  },
  view() {
    return {
      update: view => {
        const pluginState = ScrollViewPlugin.getState(view.state);
        if (pluginState && pluginState.scrollToPos) {
          const dom = view.domAtPos(pluginState.scrollToPos);
          if (dom.node.scrollIntoView) {
            dom.node.scrollIntoView();
          }
        }
      }
    };
  }
});