import http from 'hub-http/clients/apiClient';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import map from 'transmute/map';

// only fetches contacts if they are in portal
export function fetchContactsByEmail(emails) {
  return http.post('contacts/v2/contact/emails/batch', {
    data: emails
  }).then(contacts => {
    return map(contact => ContactRecord.fromJS(contact))(contacts);
  });
}