export const FONT_SIZES = [{
  text: '8',
  points: 8,
  value: 'FONT-SIZE-8'
}, {
  text: '9',
  points: 9,
  value: 'FONT-SIZE-9'
}, {
  text: '10',
  points: 10,
  value: 'FONT-SIZE-10'
}, {
  text: '11',
  points: 11,
  value: 'FONT-SIZE-11'
}, {
  text: '12',
  points: 12,
  value: 'FONT-SIZE-12'
}, {
  text: '14',
  points: 14,
  value: 'FONT-SIZE-14'
}, {
  text: '18',
  points: 18,
  value: 'FONT-SIZE-18'
}, {
  text: '24',
  points: 24,
  value: 'FONT-SIZE-24'
}, {
  text: '36',
  points: 36,
  value: 'FONT-SIZE-36'
}];
export const FONT_STYLES = [{
  text: 'Sans Serif',
  value: 'sans-serif',
  fontKey: 'FONT-SANS-SERIF'
}, {
  text: 'Serif',
  value: 'serif',
  fontKey: 'FONT-SERIF'
}, {
  text: 'Monospace',
  value: 'monospace',
  fontKey: 'FONT-MONOSPACE'
}, {
  text: 'Georgia',
  value: 'Georgia',
  fontKey: 'FONT-GEORGIA'
}, {
  text: 'Tahoma',
  value: 'Tahoma',
  fontKey: 'FONT-TAHOMA'
}, {
  text: 'Trebuchet MS',
  value: "'Trebuchet MS'",
  fontKey: 'FONT-TREBUCHET-MS'
}, {
  text: 'Verdana',
  value: 'Verdana',
  fontKey: 'FONT-VERDANA'
}];