import { Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
import { ENGAGEMENT_TO_COMPANY, ENGAGEMENT_TO_CONTACT, ENGAGEMENT_TO_DEAL, ENGAGEMENT_TO_TICKET, TASK_TO_COMPANY, TASK_TO_CONTACT, TASK_TO_DEAL, TASK_TO_TICKET } from '../associations/AssociationTypes';
import AssociationTypeToAssociationTypeId from '../associations/AssociationTypeToAssociationTypeId';
import { ObjectTypesToIds } from '../constants/ObjectTypeIds';
import { HUBSPOT_DEFINED } from '../associations/AssociationCategoryTypes';
const getAssociationTypeId = associationType => get(associationType, AssociationTypeToAssociationTypeId);

// Needed for formatting associations retrieved from tasks API
// The default association type ids can be found here: https://git.hubteam.com/HubSpot/customer-data-objects/blob/da9830763de0dc3be21dc2a22813a47f967ecab7/static/js/associations/AssociationTypeToAssociationTypeId.js
export const TASK_ASSOCIATION_SPECS = {
  [ObjectTypesToIds.CONTACT]: ImmutableMap({
    associationTypeId: getAssociationTypeId(TASK_TO_CONTACT),
    associationCategory: HUBSPOT_DEFINED
  }),
  [ObjectTypesToIds.COMPANY]: ImmutableMap({
    associationTypeId: getAssociationTypeId(TASK_TO_COMPANY),
    associationCategory: HUBSPOT_DEFINED
  }),
  [ObjectTypesToIds.DEAL]: ImmutableMap({
    associationTypeId: getAssociationTypeId(TASK_TO_DEAL),
    associationCategory: HUBSPOT_DEFINED
  }),
  [ObjectTypesToIds.TICKET]: ImmutableMap({
    associationTypeId: getAssociationTypeId(TASK_TO_TICKET),
    associationCategory: HUBSPOT_DEFINED
  })
};

// TODO add support for custom object (can use isObjectTypeId) https://git.hubteam.com/HubSpot/customer-data-objects/blob/da9830763de0dc3be21dc2a22813a47f967ecab7/static/js/constants/ObjectTypeIds.ts#L95-L97
export const SIMPLE_TO_API_ASSOCIATION_KEYS = ImmutableMap({
  [ObjectTypesToIds.CONTACT]: ENGAGEMENT_TO_CONTACT,
  [ObjectTypesToIds.COMPANY]: ENGAGEMENT_TO_COMPANY,
  [ObjectTypesToIds.DEAL]: ENGAGEMENT_TO_DEAL,
  [ObjectTypesToIds.TICKET]: ENGAGEMENT_TO_TICKET
});