import { MENTIONED_OWNER_IDS, NOTES, RELATIVE_REMINDERS, REMINDERS } from 'customer-data-objects/task/TaskPropertyNames';
import { fromJS, Map as ImmutableMap } from 'immutable';
import { reminderCategoryToUnitAndCount } from 'task-forms-lib/utils/formatRelativeDatePresets';
import filter from 'transmute/filter';
import keySeq from 'transmute/keySeq';
import merge from 'transmute/merge';
import pick from 'transmute/pick';
import { isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { VALID_ASSOCIATIONS } from '../formatters/associations';
import { getProperty as immutableGetProperty } from 'customer-data-objects/model/ImmutableModel';
import { convertToHtml, getAtMentions } from 'hubspot-prosemirror/prosemirror/ProsemirrorUtils';
export function getNotesProsemirror({
  task,
  updates,
  field: __field
}) {
  return updates && updates.has(NOTES) && updates.get(NOTES) || immutableGetProperty(task, NOTES) || '';
}
export function setNotesProsemirror({
  task: __task,
  field: __field,
  updates,
  value
}) {
  const atMentionedOwnerIds = value ? getAtMentions(value) : [];
  return updates.set(NOTES, value ? convertToHtml(value) : null).set(MENTIONED_OWNER_IDS, atMentionedOwnerIds);
}
export function setReminder({
  task: __task,
  field: __field,
  event,
  updates,
  value
}) {
  const {
    category
  } = event;
  const reminders = reminderCategoryToUnitAndCount([category]);
  const newUpdates = fromJS({
    [REMINDERS]: value,
    [RELATIVE_REMINDERS]: reminders
  });
  const mergedUpdates = merge(newUpdates, updates);
  return mergedUpdates;
}
export function cleanAssociations(associations) {
  const validAssociationKeys = filter(associationKey => {
    const isHubSpotDefinedAssociationKey = VALID_ASSOCIATIONS.includes(associationKey);
    return isHubSpotDefinedAssociationKey || isObjectTypeId(associationKey);
  }, keySeq(associations));
  // TODO fix unknown conversion, possibly through removing use of filter above
  return pick(validAssociationKeys, associations);
}
export function getAssociationsWithUpdates(task, updates) {
  return task.get('associations', ImmutableMap()).merge(updates.get('associations', ImmutableMap()));
}
export function getAssociations({
  task,
  updates = ImmutableMap(),
  field: __field
}) {
  return cleanAssociations(getAssociationsWithUpdates(task, updates));
}
export function setAssociations({
  task: __task,
  field: __field,
  updates,
  value
}) {
  // Expects all associations to be in simple format
  return updates.mergeIn(['associations'], value);
}