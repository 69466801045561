import I18n from 'I18n';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { registerMutation, registerQuery, useMutation, useQuery } from 'data-fetching-client';
import enviro from 'enviro';
import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EditorConfigContext } from '../../contexts/EditorConfigContext';
import useDebouncedEditorStateContext from '../../contexts/useDebouncedEditorStateContext';
import { insertContent } from '../../utils/documentUtils';
import { convertToPlaintextState } from '../../utils/plaintextUtils';
import { getGlobalVars } from '../../utils/globalVarUtil';
const POLL_INTERVAL = 200;
const requestThreadSummary = ({
  threadId
}) => http.post(`conversations-summaries/v1/${threadId}/async`);
const REQUEST_THREAD_SUMMARY = registerMutation({
  fieldName: 'threadSummaryRequest',
  args: ['threadId'],
  fetcher: requestThreadSummary
});
const fetchThreadSummaryExecutionResults = ({
  executionId
}) => http.get(`content-assistance/v3/command-executions/${executionId}`);
const FETCH_SUMMARY_EXECUTION_RESULTS = registerQuery({
  fieldName: 'threadSummaryExecutionResults',
  args: ['executionId'],
  fetcher: fetchThreadSummaryExecutionResults
});
export const useSummaryCommand = () => {
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const {
    editorState,
    setEditorState
  } = useDebouncedEditorStateContext();
  const editorConfig = useContext(EditorConfigContext);
  const {
    usePlaintextMode,
    plaintextOptions
  } = useMemo(() => getGlobalVars(editorConfig), [editorConfig]);
  const {
    AI_SUMMARY,
    AI_ASSISTANT
  } = editorConfig;
  const summaryConfig = AI_ASSISTANT || AI_SUMMARY;
  const insertSummary = useCallback(summary => {
    const newState = insertContent({
      htmlBody: summary,
      editorState,
      from: editorState.selection.from,
      to: editorState.selection.to
    });
    setEditorState(usePlaintextMode ? convertToPlaintextState(newState, plaintextOptions) : newState);
    if (summaryConfig && summaryConfig.onSummaryGenerated) {
      summaryConfig.onSummaryGenerated();
    }
  }, [summaryConfig, editorState, setEditorState, usePlaintextMode, plaintextOptions]);
  const handleError = useCallback(error => {
    setIsGeneratingSummary(false);
    FloatingAlertStore.addAlert({
      message: I18n.text('hubspot-prosemirror.errors.insertSummary.fetchError.message'),
      titleText: I18n.text('hubspot-prosemirror.errors.insertSummary.fetchError.title'),
      type: 'warning'
    });
    Raven.captureException(error);
    console.warn('Failed to generate AI Summary');
  }, [setIsGeneratingSummary]);
  const [requestSummary, {
    data: summaryRequestData
  }] = useMutation(REQUEST_THREAD_SUMMARY, {
    onError: handleError
  });
  const {
    data: executionResults,
    error: fetchSummaryExecutionError,
    stopPolling
  } = useQuery(FETCH_SUMMARY_EXECUTION_RESULTS, {
    skip: !summaryRequestData,
    pollInterval: POLL_INTERVAL,
    variables: {
      executionId: summaryRequestData && summaryRequestData.threadSummaryRequest.executionId
    },
    onError: handleError
  });
  useEffect(() => {
    if (fetchSummaryExecutionError) stopPolling();
    if (!executionResults) return;
    if (!executionResults.threadSummaryExecutionResults.results) return;
    const {
      value: summary
    } = executionResults.threadSummaryExecutionResults.results[0];
    if (isGeneratingSummary && summary) {
      stopPolling();
      setIsGeneratingSummary(false);
      insertSummary(summary);

      // @ts-expect-error hsFeedback does indeed exist
      if (window.hsFeedback && enviro.isProd()) {
        // @ts-expect-error hsFeedback does indeed exist
        window.hsFeedback.loadSurvey('CSAT', 933);
      }
    }
  }, [executionResults, fetchSummaryExecutionError, insertSummary, isGeneratingSummary, summaryRequestData, stopPolling]);
  const generateSummary = useCallback(() => {
    if (!summaryConfig) return;
    const {
      onSummaryRequested,
      threadId
    } = summaryConfig;
    setIsGeneratingSummary(true);
    if (onSummaryRequested) {
      onSummaryRequested();
      if (threadId) {
        requestSummary({
          variables: {
            threadId
          }
        }).catch(handleError);
      }
    }
  }, [handleError, requestSummary, summaryConfig]);
  return {
    isLoading: isGeneratingSummary,
    generateSummary
  };
};