import setIn from 'transmute/setIn';
import { COMPLETED } from 'customer-data-objects/engagement/EngagementStatusTypes';
import { STATUS } from 'customer-data-objects/task/TaskPropertyNames';
import { getTaskQueueConfig } from '../storage/getTaskQueueConfig';
import { updateTaskQueueConfig } from '../storage/updateTaskQueueConfig';
import { validateStorageShape } from '../storage/validateStorageShape';
export const setCurrentTaskCompletedWithoutProgressing = storage => () => {
  validateStorageShape(storage);
  const {
    completedTaskIds = [],
    currentTask,
    currentTaskId
  } = getTaskQueueConfig(storage)();
  const newCompletedTasks = [...completedTaskIds, currentTaskId];
  if (!currentTask) {
    return;
  }
  const completedCurrentTask = setIn(['properties', STATUS, 'value'], COMPLETED, currentTask);
  updateTaskQueueConfig(storage)({
    completedTaskIds: newCompletedTasks,
    currentTask: completedCurrentTask
  });
};