export function personalizationTokenStylesToString(tokenStyles) {
  return Object.keys(tokenStyles).map(key => `${key}: ${tokenStyles[key]}`).join('; ') || undefined;
}
export function personalizationTokenStylesFromString(tokenStylesStr) {
  const tokenStyles = {};
  tokenStylesStr === null || tokenStylesStr === void 0 || tokenStylesStr.split(';').forEach(styleStr => {
    const [__, key, value] = styleStr.match(/(fontColor|borderColor|backgroundColor): ([\w\-#()]+)/) || [];
    if (key) tokenStyles[key] = value;
  });
  return tokenStyles;
}