'use es6';

import memoize from 'transmute/memoize';
import getIn from 'transmute/getIn';
import get from 'transmute/get';
import PortalIdParser from 'PortalIdParser';
import { CALL_TYPE_ID, isObjectTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
export const createLinkFromIdAndObjectType = (id, objectType) => {
  if (objectType === CALL_TYPE_ID) {
    return `/calls/${PortalIdParser.get()}/review/${id}`;
  }
  const prefix = isObjectTypeId(objectType) ? 'record/' : '';
  return id && objectType ? `/contacts/${PortalIdParser.get()}/${prefix}${objectType.toLowerCase()}/${id}/` : null;
};
export const getIdFromRecordOrValue = record => get('id', record) || get('vid', record) || get('company-id', record) || get('companyId', record) || get('ownerId', record) || get('objectId', record) || get('dealId', record);

// HACK: This should be expanded into a full "Get Label" function
export const getTicketSubject = record => get('subject', record) || getIn(['properties', 'subject', 'value'])(record) || undefined;
export const hasDealSplit = original => Number(getIn(['properties', 'hs_num_associated_deal_splits', 'value'], original)) > 1;
export const getPropertyType = memoize(property => property.get('type'));
export const getPropertyFieldType = memoize(property => property.get('fieldType'));