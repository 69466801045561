import { createSelector } from 'reselect';
const userSettingsSelector = state => state.userSettings;
export const createUserSettingWithLoadingSelector = key => createSelector(userSettingsSelector, userSettingsState => {
  const isLoading = userSettingsState.loading;
  const value = userSettingsState.keys[key];
  const hasError = userSettingsState.error;
  return {
    isLoading,
    hasError,
    value
  };
});