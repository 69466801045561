'use es6';

import { useCallback, useMemo } from 'react';
import { TASK_INTERACTION_TYPES } from '../constants/table';
import { useDispatch } from '../context/Redux';
import { temporarilyExcludeTaskIds } from '../redux/actions/temporarilyExcludeIds';
import { isIncompleteTasksFilterApplied, isTaskStatusFilterApplied } from '../utils/crmSearchFilters';
import { useTempFilters } from './useTempFilters';
import { useActiveViewId } from './viewTabs';
const ALWAYS_EXCLUDE_INTERACTIONS = [TASK_INTERACTION_TYPES.DELETE_TASKS, TASK_INTERACTION_TYPES.FOLLOW_UP_TASK];
export function useTemporarilyExcludeIds() {
  const viewId = useActiveViewId();
  const dispatch = useDispatch();
  const filters = useTempFilters(viewId);
  const isStatusFilterApplied = isTaskStatusFilterApplied(filters);
  const isOnlyIncompleteTasksShown = useMemo(() => isIncompleteTasksFilterApplied(filters), [filters]);
  return useCallback((taskIds, action) => {
    // if there is no status filter, then completed and uncompleted tasks are shown
    // in the same view and we don't ever want to exclude tasks
    if (!isStatusFilterApplied) {
      return;
    } else if (ALWAYS_EXCLUDE_INTERACTIONS.includes(action) ||
    // if only showing incomplete tasks and user completes tasks
    action === TASK_INTERACTION_TYPES.COMPLETE_TASKS && isOnlyIncompleteTasksShown ||
    // if only showing complete tasks and user uncomplete tasks
    action === TASK_INTERACTION_TYPES.UNCOMPLETE_TASKS && !isOnlyIncompleteTasksShown) {
      dispatch(temporarilyExcludeTaskIds({
        taskIds,
        viewId
      }));
    }
  }, [dispatch, viewId, isStatusFilterApplied, isOnlyIncompleteTasksShown]);
}