import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import formatName from 'I18n/utils/formatName';
import getIn from 'transmute/getIn';
export const getObjectLabel = ({
  objectTypeId,
  primaryDisplayLabelPropertyName
}, object) => {
  if (objectTypeId === CONTACT_TYPE_ID) {
    return formatName({
      firstName: getIn(['properties', 'firstname', 'value'], object),
      lastName: getIn(['properties', 'lastname', 'value'], object),
      email: getIn(['properties', 'email', 'value'], object)
    });
  }
  return getIn(['properties', primaryDisplayLabelPropertyName, 'value'], object);
};