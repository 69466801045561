import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { REPEAT_INTERVAL } from 'customer-data-objects/task/TaskPropertyNames';
import { useCallback, useReducer, useRef } from 'react';
export function useRepeatingTaskModalOnSave(onSave) {
  const [isModalOpen, toggleModal] = useReducer(isOpen => !isOpen, false);

  // these are refs because they don't influence the rendered output
  const interceptedOnSaveArgsRef = useRef([null, null]);
  const onSavePromiseCallbacksRef = useRef({
    resolve() {},
    reject() {}
  });
  const handleDecisionMade = useCallback(({
    updateTemplate
  }) => {
    // This is where we make use of the arguments that we intercepted earlier.
    // We call the actual unwrapped onSave with those arguments plus the
    // additional updateTemplate argument and then resolve the dummy promise
    // that we returned to TaskFormInner with the result so that TaskFormInner
    // can run its .then on the promise which closes the panel
    const [updatedTask, updates] = interceptedOnSaveArgsRef.current;
    const {
      resolve,
      reject
    } = onSavePromiseCallbacksRef.current;
    toggleModal();
    if (updatedTask && updates) {
      return onSave(updatedTask, updates, {
        updateTemplate
      }).then(resolve, reject);
    }
    return Promise.resolve();
  }, [onSave]);
  const handleCloseModal = useCallback(() => {
    toggleModal();
    interceptedOnSaveArgsRef.current = [null, null];
  }, []);
  const interceptSave = useCallback((updatedTask, updates) => {
    const updatedTaskIsRepeating = getProperty(updatedTask, REPEAT_INTERVAL);
    const repeatIntervalWasUpdated = updates.has(REPEAT_INTERVAL);
    if (repeatIntervalWasUpdated) {
      // if the repeat interval was updated,
      // the template will have to be recreated no matter what.
      return onSave(updatedTask, updates, {
        updateTemplate: true
      });
    } else if (updatedTaskIsRepeating) {
      // repeating task but repeat interval is unchanged
      // intercept args, return dummy promise, save the resolver/rejector
      // so that we can resolve the dummy promise later on
      interceptedOnSaveArgsRef.current = [updatedTask, updates];
      toggleModal();
      return new Promise((resolve, reject) => {
        onSavePromiseCallbacksRef.current = {
          resolve,
          reject
        };
      });
    } else {
      // non-repeating task
      return onSave(updatedTask, updates);
    }
  }, [onSave]);
  return {
    showModal: isModalOpen,
    onSave: interceptSave,
    onDecisionMade: handleDecisionMade,
    onClose: handleCloseModal
  };
}