// Converted from https://github.com/ProseMirror/prosemirror-tables/blob/bfd086662c578e10092a8b673186836f00dc2d6b/src/schema.js

function getCellAttrs(dom, extraAttrs) {
  // @ts-expect-error Function exists at runtime
  const widthAttr = dom.getAttribute('data-colwidth');
  const widths = widthAttr && /^\d+(,\d+)*$/.test(widthAttr) ? widthAttr.split(',').map(s => Number(s)) : null;
  // @ts-expect-error Function exists at runtime
  const colspan = Number(dom.getAttribute('colspan') || 1);
  const result = {
    colspan,
    // @ts-expect-error Function exists at runtime
    rowspan: Number(dom.getAttribute('rowspan') || 1),
    colwidth: widths && widths.length === colspan ? widths : null
  };
  // eslint-disable-next-line guard-for-in
  for (const prop in extraAttrs) {
    const getter = extraAttrs[prop].getFromDOM;
    const value = getter && getter(dom);
    // @ts-expect-error Any type
    if (value != null) result[prop] = value;
  }
  return result;
}
function setCellAttrs(node, extraAttrs) {
  const attrs = {
    colspan: node.attrs.colspan,
    rowspan: node.attrs.rowspan,
    ['data-colwidth']: node.attrs.colwidth ? node.attrs.colwidth.join(',') : null
  };
  for (const prop in extraAttrs) {
    if (extraAttrs[prop].setDOMAttr) extraAttrs[prop].setDOMAttr(node.attrs[prop], attrs);
  }
  return attrs;
}
export function tableNodes(options) {
  const extraAttrs = options.cellAttributes || {};
  const cellAttrs = {
    colspan: {
      default: 1
    },
    rowspan: {
      default: 1
    },
    colwidth: {
      default: null
    }
  };

  // eslint-disable-next-line guard-for-in
  for (const prop in extraAttrs) {
    // @ts-expect-error Any type
    cellAttrs[prop] = {
      default: extraAttrs[prop].default
    };
  }
  return {
    table: {
      content: 'table_row+',
      tableRole: 'table',
      isolating: true,
      group: options.tableGroup,
      parseDOM: [{
        tag: 'table'
      }],
      toDOM() {
        return ['table', ['tbody', 0]];
      }
    },
    table_row: {
      content: '(table_cell | table_header)*',
      tableRole: 'row',
      parseDOM: [{
        tag: 'tr'
      }],
      toDOM() {
        return ['tr', 0];
      }
    },
    table_cell: {
      content: options.cellContent,
      attrs: cellAttrs,
      tableRole: 'cell',
      isolating: true,
      parseDOM: [{
        tag: 'td',
        getAttrs: dom => getCellAttrs(dom, extraAttrs)
      }],
      toDOM(node) {
        return ['td', setCellAttrs(node, extraAttrs), 0];
      }
    },
    table_header: {
      content: options.cellContent,
      attrs: cellAttrs,
      tableRole: 'header_cell',
      isolating: true,
      parseDOM: [{
        tag: 'th',
        getAttrs: dom => getCellAttrs(dom, extraAttrs)
      }],
      toDOM(node) {
        return ['th', setCellAttrs(node, extraAttrs), 0];
      }
    }
  };
}

// :: (OrderedMap<NodeSpec>, string, ?string) → OrderedMap<NodeSpec>
// Convenience function for adding table-related node types to a map
// specifying the nodes for a schema.
function addTableNodes(nodes) {
  return nodes.append(tableNodes({
    tableGroup: 'block',
    cellContent: 'block+',
    cellAttributes: {
      background: {
        default: null,
        getFromDOM(dom) {
          return dom.style.backgroundColor || null;
        },
        setDOMAttr(value, attrs) {
          if (value) attrs.style = `${attrs.style || ''}background-color: ${value};`;
        }
      }
    }
  }));
}
export { addTableNodes };