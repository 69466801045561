'use es6';

import { AssociationCategoryTypesToIds, HUBSPOT_DEFINED } from 'customer-data-objects/associations/AssociationCategoryTypes';
import { OrderedMap } from 'immutable';
import { useMemo } from 'react';
import get from 'transmute/get';
import { reportNewTasksError } from '../tracking/errors';
import { getAssociationColumnKey } from '../utils/associationColumnNames';
import { useAllAssociationDefinitionsQuery } from './useAllAssociationDefinitionsQuery';

/**
 * Fetches task to custom object association definitions and user-defined association labels
 * from tasks to other objects
 *
 * @returns map from association definition column names (eg "association-1-204") to additional data
 * about the custom object or association label
 */
export const useCustomAssociationColumnNames = () => {
  const {
    loading,
    error,
    data
  } = useAllAssociationDefinitionsQuery();
  return useMemo(() => {
    if (loading || error) {
      return OrderedMap();
    }
    try {
      const {
        allAssociationTypesFromObjectType
      } = data;
      return allAssociationTypesFromObjectType.reduce((acc, definition) => {
        const {
          associationCategory,
          associationTypeId,
          /**
           * Expected to only be defined for user-defined association labels (flexible associations)
           * Associations generated by HubSpot are expected not to have this value. This includes associations to
           * HubSpot-defined objects. In addition, when a user defines a custom object, an association to that custom object is also
           * created by default; the user doesn't have to manually define the association. Because of this, these associations are also not expected
           * to have the 'label' property (though their associationCategory is USER_DEFINED because of the custom object)
           */
          label,
          toObjectTypeDefinition
        } = definition;
        const {
          singularForm
        } = toObjectTypeDefinition;

        // Only get custom objects with singular form defined to avoid showing missing text
        if (!singularForm || associationCategory === HUBSPOT_DEFINED) {
          return acc;
        }
        const columnName = getAssociationColumnKey(Number(get(associationCategory, AssociationCategoryTypesToIds)), associationTypeId);
        return acc.set(columnName, {
          associationLabel: label,
          singularForm
        });
      }, OrderedMap())
      // always show alphabetical based on singular form
      .sortBy(definition => definition.singularForm);
    } catch (e) {
      reportNewTasksError(e, {
        functionName: 'useCustomAssociationColumnNames'
      });
      return OrderedMap();
    }
  }, [error, data, loading]);
};