import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import { COMPANY, CONTACT, DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import { getFullUrl } from 'hubspot-url-utils';
import { stringify } from 'hub-http/helpers/params';
import { ObjectTypeFromIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { VIEW_ID_KEYS } from './views';
const PATHNAMES = {
  BOARD: '/board',
  ADVANCED_FILTERS_PANEL: '/filters',
  MANAGE_QUEUES_PANEL: '/queues',
  CREATE_QUEUE_PANEL: '/queues/new',
  REORDER_VIEWS_PANEL: '/reorder'
};
export function fullHubSpotUrl(pathname = '') {
  const subdomain = enviro.deployed() ? 'app' : 'local';
  return `${getFullUrl(subdomain)}${pathname}`;
}
export function tasksBaseRoute() {
  const portalId = PortalIdParser.get();
  return `/tasks/${portalId}`;
}
export const crmBaseUrl = () => `/contacts/${PortalIdParser.get()}`;
export const getQueuesLink = () => '/queues';
export function editTaskLink(currentPathname, taskId, fullLink = true) {
  let relativeBase = '';
  if (currentPathname.includes(PATHNAMES.BOARD)) {
    relativeBase = PATHNAMES.BOARD;
  }
  const relativePathname = `${relativeBase}/task/${taskId}`;
  if (fullLink) {
    return fullHubSpotUrl(`${tasksBaseRoute()}${relativePathname}`);
  }
  return relativePathname;
}
export function createTaskLink(currentPathname, viewId = VIEW_ID_KEYS.OPEN_TASKS_ALL) {
  const taskPathSegmentRegex = /\/task\/.+$/;
  if (taskPathSegmentRegex.test(currentPathname)) {
    return currentPathname.replace(taskPathSegmentRegex, '/task/new');
  }
  if (currentPathname.includes(PATHNAMES.BOARD)) {
    return `${currentPathname}/task/new`;
  }
  return `/view/${viewId}/task/new`;
}
function getViewLinkWithPanel({
  currentPathname = '',
  queueId,
  viewId
}) {
  if (currentPathname.includes(PATHNAMES.ADVANCED_FILTERS_PANEL)) {
    return `/view/${viewId}${PATHNAMES.ADVANCED_FILTERS_PANEL}`;
  }
  if (currentPathname.includes(PATHNAMES.CREATE_QUEUE_PANEL)) {
    return `/view/${viewId}${PATHNAMES.CREATE_QUEUE_PANEL}`;
  }
  if (queueId) {
    // Close edit queue panel unless clicking on the same tab
    return String(queueId) === String(viewId) ? `/view/${viewId}/queues/${queueId}` : `/view/${viewId}`;
  }
  if (currentPathname.endsWith(PATHNAMES.MANAGE_QUEUES_PANEL)) {
    return `/view/${viewId}${PATHNAMES.MANAGE_QUEUES_PANEL}`;
  }
  if (currentPathname.includes(PATHNAMES.REORDER_VIEWS_PANEL)) {
    return `/view/${viewId}${PATHNAMES.REORDER_VIEWS_PANEL}`;
  }
  return `/view/${viewId}`;
}
export function viewLink({
  closeAllPanels = false,
  currentPathname = '',
  queueId,
  taskId,
  viewId
}) {
  if (taskId) {
    return `/view/${viewId}/task/${taskId}`;
  }
  if (closeAllPanels) {
    return `/view/${viewId}`;
  }
  return getViewLinkWithPanel({
    currentPathname,
    queueId,
    viewId
  });
}
export const activityFeedEmbeddedLink = () => fullHubSpotUrl(`/activity-feed-embedded/${PortalIdParser.get()}?source=tasks`);
export const getImportLink = () => fullHubSpotUrl(`/import/${PortalIdParser.get()}`);
export const navigateToURL = url => {
  window.location.href = url;
};
export const getContactLink = objectId => {
  return fullHubSpotUrl(`${crmBaseUrl()}/contact/${objectId}`);
};
export const getCompanyLink = objectId => {
  return fullHubSpotUrl(`${crmBaseUrl()}/company/${objectId}`);
};
export const getDealLink = objectId => {
  return fullHubSpotUrl(`${crmBaseUrl()}/deal/${objectId}`);
};
export const getTicketLink = objectId => {
  return fullHubSpotUrl(`${crmBaseUrl()}/ticket/${objectId}`);
};
export const getCustomRecordLink = (objectTypeId, objectId) => {
  return fullHubSpotUrl(`${crmBaseUrl()}/record/${objectTypeId}/${objectId}`);
};
export const getRecordPageLink = ({
  objectType: objectTypeArg,
  objectTypeId,
  objectId
}) => {
  const objectType = objectTypeArg || ObjectTypeFromIds[objectTypeId];
  switch (objectType) {
    case CONTACT:
      return getContactLink(objectId);
    case COMPANY:
      return getCompanyLink(objectId);
    case DEAL:
      return getDealLink(objectId);
    case TICKET:
      return getTicketLink(objectId);
    default:
      return getCustomRecordLink(objectTypeId, objectId);
  }
};
export const getCrmSettingsTasksRemindersLink = () => {
  const portalId = PortalIdParser.get();
  const settingsUrl = `/settings/${portalId}/user-preferences/tasks`;
  return fullHubSpotUrl(settingsUrl);
};
export const getIsHoldingMetaKey = () => {
  const clickEvent = window.event;
  const isHoldingMetaKey = clickEvent && clickEvent.metaKey;
  return isHoldingMetaKey;
};
export const navigateToURLInNewTab = url => {
  window.open(url, '_blank');
};
export const getSequencesPage = sequenceId => {
  const portalId = PortalIdParser.get();
  const sequencePath = `/sequences/${portalId}/sequence/${sequenceId}`;
  return fullHubSpotUrl(sequencePath);
};
export const getProspectingUrl = () => {
  const portalId = PortalIdParser.get();
  return fullHubSpotUrl(`/prospecting/${portalId}`);
};
export function toQueryString(queryParams) {
  if (queryParams) {
    return `?${stringify(queryParams)}`;
  }
  return '';
}
export function getViewManagementUrl() {
  const portalId = PortalIdParser.get();
  return fullHubSpotUrl(`/manage-views/${portalId}/all?namespace=TASKS&objectTypeId=0-27`);
}
export function getTaskHistoryUrl(taskId) {
  const portalId = PortalIdParser.get();
  return fullHubSpotUrl(`/contacts/${portalId}/record/0-27/${taskId}/history`);
}