// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { getTypedTaskProperty } from 'customer-data-objects/task/mapTaskAPIResponseToTaskRecord';
import TaskRecord from 'customer-data-objects/task/TaskRecord';
import { Map as ImmutableMap } from 'immutable';
import { getCrmObjectKeyFromObject } from '../utils/crmObjectHelpers';
export function getCrmObjectsAsIdMaps(objectsArray) {
  return objectsArray.reduce((objectDataMaps, object) => {
    const objectKey = getCrmObjectKeyFromObject(object);
    return Object.assign({}, objectDataMaps, {
      [objectKey]: object
    });
  }, {});
}

// Expect array of properties ( eg. [ { name: "hs_task_subject", value: "title", ... }])
// and transforms into map of name to value
export function formatGraphQLCrmSearchTaskPropertiesWithNames(properties) {
  return properties.reduce((allProperties, property) => {
    return allProperties.set(property.name, getTypedTaskProperty(property.name, property));
  }, ImmutableMap());
}

/**
 * @param {Array[Object]} crmObjects task property and association data, formatted like
 * [
 *   {
 *    properties: [
 *      ...
 *    ],
 *    associations: {
 *      engagementToContact: {
 *        edges: [
 *          ...
 *        ]
 *      }
 *      ...
 *    }
 *  }
 * ]
 * see crm-tasks-ui/new/hooks/useCrmSearchQuery for detailed format
 */
export function formatTasksFromGraphQL(taskCrmObjects) {
  return taskCrmObjects.reduce((taskRecordsArray, taskResult) => {
    return [...taskRecordsArray, TaskRecord.fromJS(Object.assign({}, taskResult, {
      objectId: taskResult.id,
      properties: formatGraphQLCrmSearchTaskPropertiesWithNames(taskResult.properties)
    }))];
  }, []);
}