import invariant from 'react-utils/invariant';
import isString from 'transmute/isString';
import { reportNewTasksWarning } from '../tracking/errors';
export const LOCAL_SETTINGS_PREFIX = 'LocalSettings:Sales:';
export function _makePrefixedKey(key) {
  invariant(isString(key) && key.length >= 1, 'expected `key` to be a non-empty string but got `%s`', key);
  return `${LOCAL_SETTINGS_PREFIX}${key}`;
}

/**
 * Adds `value` to `storage` at `key`.
 * JSON.stringify's `value` before writing to storage.
 * Returns `value` if set successfully; otherwise `undefined`.
 *
 * @example
 * setFrom(localStorage, 'setting', true);
 *
 * @param  {Object} storage type of storage (i.e. localStorage, sessionStorage)
 * @param  {String} key the key that will reference value in storage prefixed by LOCAL_SETTINGS_PREFIX
 * @param  {any} value the value saved at key
 * @return {any} returns the value if successful, undefined otherwise
 */
export function setFrom(storage, key, value) {
  value = JSON.stringify(value);
  try {
    storage[_makePrefixedKey(key)] = value;
    return value;
  } catch (error) {
    reportNewTasksWarning('Failed to set data in local settings', {
      groupName: 'LOCAL_SETTINGS',
      functionName: 'setFrom',
      extra: {
        message: `Failed to set data in storage. Key: ${key}, Value: ${value}`
      }
    });
    return undefined;
  }
}

/**
 * Retrieves `key` from `storage`.
 * Returns the result of JSON.parse'ing the string found in storage.
 * Returns `undefined` if storage access or JSON.parse fail.
 *
 * @example
 * getFrom(localStorage, 'setting') === true
 */
export function getFrom(storage, key) {
  try {
    const entry = storage[_makePrefixedKey(key)];
    if (!entry) {
      return entry;
    }
    return JSON.parse(entry);
  } catch (error) {
    reportNewTasksWarning('Failed to get data from local settings', {
      groupName: 'LOCAL_SETTINGS',
      functionName: 'getFrom',
      extra: {
        message: `Failed to get data in storage. Key: ${key}`
      }
    });
    return undefined;
  }
}