import { Plugin } from 'prosemirror-state';

// only one image focus stored at a time
export const initialState = {
  imagePos: null
};
export const ImageFocusPlugin = new Plugin({
  state: {
    init() {
      return initialState;
    },
    apply(tr, currentPluginState) {
      const meta = tr.getMeta(ImageFocusPlugin);
      if (meta) {
        return meta;
      }
      return currentPluginState;
    }
  }
});