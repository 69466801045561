import { Plugin } from 'prosemirror-state';
import { setTrSelectionPosition } from '../utils/documentUtils';
/**
 * TODO: move this into state for the FocusPlugin
 */ // eslint-disable-next-line no-restricted-syntax
let focusPluginState;
export const FocusPlugin = new Plugin({
  state: {
    init() {
      return {};
    },
    apply(tr, currentPluginState) {
      const meta = tr.getMeta(FocusPlugin);
      if (meta) {
        focusPluginState = meta;
        return meta;
      }
      return currentPluginState;
    }
  },
  view() {
    return {
      update: view => {
        if (focusPluginState && focusPluginState.focusView) {
          // only focus if it isn't currently focused
          window.requestAnimationFrame(() => {
            if (!view.hasFocus()) {
              view.focus();
              view.dispatch(setTrSelectionPosition(view.state.tr, focusPluginState.focusViewPos));
              focusPluginState = {};
            }
          });
        }
      }
    };
  }
});