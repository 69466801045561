import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { DUE_DATE, IS_ALL_DAY } from 'customer-data-objects/task/TaskPropertyNames';
import I18n from 'I18n';
export function isTaskOverdue(task) {
  const dueDateMs = getProperty(task, DUE_DATE);
  if (dueDateMs === null || dueDateMs === undefined || dueDateMs === '') {
    return false;
  }
  const dueDateAsNumber = typeof dueDateMs === 'string' ? parseInt(dueDateMs, 10) : dueDateMs;
  const isAllDay = getProperty(task, IS_ALL_DAY);
  if (isAllDay) {
    const startOfTodayMs = I18n.moment.userTz().startOf('day').valueOf();
    return dueDateAsNumber < startOfTodayMs;
  }
  return dueDateAsNumber <= I18n.moment.userTz().valueOf();
}