import I18n from 'I18n';
import { TASK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import TaskRecord from 'customer-data-objects/task/TaskRecord';
import { BASE_TASK_ASSOCIATIONS, CRM_ASSOCIATIONS_TO_TASK_ASSOCIATONS } from '../constants/taskAssociations';
import { TASK_PROPERTIES_TO_FETCH } from '../constants/taskProperties';
import { validateApiClientShape } from './validateApiClientShape';
function mapCRMObjectAssociationsToTaskAssociations(crmObjectAssociatedObjects) {
  if (!crmObjectAssociatedObjects) {
    return BASE_TASK_ASSOCIATIONS;
  }
  return Object.keys(crmObjectAssociatedObjects).reduce((associations, associationType) => {
    // skip custom object associations
    // custom objects should not be getting returned from this endpoint since we're only requesting the association types in MINIMAL_ASSOCIATION_PREVIEW
    if (!(associationType in CRM_ASSOCIATIONS_TO_TASK_ASSOCIATONS)) {
      return associations;
    }
    const associationIds = crmObjectAssociatedObjects[associationType].map(association => association.objectId);
    const associationTypeId = CRM_ASSOCIATIONS_TO_TASK_ASSOCIATONS[associationType];
    return Object.assign({}, associations, {
      [associationTypeId]: Object.assign({}, associations[associationTypeId], {
        objectIds: associationIds
      })
    });
  }, BASE_TASK_ASSOCIATIONS);
}
function formatCRMObjectsAsTaskRecords(crmObjects) {
  return crmObjects.map(taskResult => TaskRecord.fromJS(Object.assign({}, taskResult, {
    associations: mapCRMObjectAssociationsToTaskAssociations(taskResult.associatedObjects)
  })));
}
const MINIMAL_ASSOCIATION_PREVIEWS = {
  TASK_TO_CONTACT: {
    properties: []
  },
  TASK_TO_COMPANY: {
    properties: []
  },
  TASK_TO_DEAL: {
    properties: []
  },
  TASK_TO_TICKET: {
    properties: []
  }
};
export const fetchSearchTasksWithAssociations = apiClient => crmSearchQuery => {
  validateApiClientShape(apiClient);

  // Interpret all date filters in user's timezone
  const timeZoneId = I18n.moment.userTz().format('Z') || 'UTC';
  return apiClient.post('sales-views/v2/crm-search/with-placeholders', {
    data: Object.assign({
      objectTypeId: TASK_TYPE_ID,
      associationPreviews: MINIMAL_ASSOCIATION_PREVIEWS,
      requestOptions: {
        properties: TASK_PROPERTIES_TO_FETCH
      },
      timeZoneId
    }, crmSearchQuery)
  }).then(response => {
    return Object.assign({}, response, {
      results: formatCRMObjectsAsTaskRecords(response.results)
    });
  });
};