import partial from 'react-utils/partial';
import get from './transmute';

/**
 * Retrieves the filter value for a property from a crmSearchQuery.
 * Assumes only one filter per property.
 * @param {Object} filterState: A CRMSearch Query object. E.g. {sorts: [], filterGroups: [{ filters: [] }], query: ''}
 * @param {String} propertyName
 * @param {func} returnValueStrategy: A function which determines how to return the value and what fields to return
 * @returns The first value retrieved from the crmSearch query object
 */
function baseGetPropertyFilterValue(returnValueStrategy, crmSearchFilters, propertyName) {
  const filterObject = crmSearchFilters.find(
  // @ts-expect-error  get returns void here; possibly remove use of get
  filter => get('property', filter) === propertyName);
  if (!filterObject) {
    return undefined;
  }
  return returnValueStrategy(filterObject);
}
const returnFilterObject = filterObject => filterObject;
export const returnSingleValueOrArray = filterObject => filterObject.value || filterObject.values;
export const getPropertyFilterValue = partial(baseGetPropertyFilterValue, returnSingleValueOrArray);
export const getPropertyFilter = partial(baseGetPropertyFilterValue, returnFilterObject);