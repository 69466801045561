import { GROUP_NAMES, reportNewTasksError } from './errors';
// Example taken from https://git.hubteam.com/HubSpot/content-detail-ui/pull/851
// Show the CMS wootric survey (via global that comes from nav)
export const triggerWootricSurvey = () => {
  try {
    if (window.wootric) {
      window.wootric('run');
    }
  } catch (error) {
    reportNewTasksError(error, {
      groupName: GROUP_NAMES.TRACKING,
      functionName: 'triggerWootricSurvey'
    });
  }
};