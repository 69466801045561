import { validateStorageShape } from './validateStorageShape';
/**
 * Acts as a wrapper around a storage interface implementation like localStorage, sessionStorage, etc.
 * because accessing storage is typically expensive and unsafe.
 * The preferred usage of this is to catch errors from setItem, getItem, etc. in your app, but we'll also
 * do it here just in case.
 * See the storage interface here: https://developer.mozilla.org/en-US/docs/Web/API/Storage
 */
export class StorageWrapper {
  constructor(storageImplementation) {
    validateStorageShape(storageImplementation, false);
    this._storage = storageImplementation;
    this.cache = {};
    this.isStorageWrapper = true;
  }
  setItem(key, value) {
    try {
      this._storage.setItem(key, value);
      this.cache[key] = value;
    } catch (e) {
      console.error(e);
    }
  }
  getItem(key, options = {
    force: false
  }) {
    const {
      force
    } = options;
    if (!(key in this.cache) || force) {
      try {
        this.cache[key] = this._storage.getItem(key);
      } catch (e) {
        console.error(e);
      }
    }
    return this.cache[key];
  }
  removeItem(key) {
    try {
      this._storage.removeItem(key);
    } finally {
      delete this.cache[key];
    }
  }
}