import { useQuery } from 'data-fetching-client';
import { TASK_QUEUES_POLL_INTERVAL } from '../constants/polling';
import { taskQueues } from '../query/taskQueues';
export function useTaskQueues(ids) {
  const {
    data,
    loading,
    refetch
  } = useQuery(taskQueues, {
    variables: {
      ids
    },
    pollInterval: TASK_QUEUES_POLL_INTERVAL
  });
  return {
    loading,
    queues: data && data.taskQueues ? data.taskQueues : null,
    refetchQueues: refetch
  };
}
export function useTaskQueue(id) {
  const {
    loading,
    queues,
    refetchQueues
  } = useTaskQueues([id]);
  return {
    loading,
    queue: queues && queues.length > 0 ? queues[0] : null,
    refetchQueue: refetchQueues
  };
}