'use es6';

import { useCallback, useEffect, useState } from 'react';
import { useIsMounted } from './useIsMounted';
import { AssociationsPreviewClient } from '../clients/AssociationsPreviewClient';
import get from 'transmute/get';
const defaultData = [];

/**
 * Usage:
 *
 * const { data, loading, error } = useFetchAssociatedObjects({
 *   associationDefinition: ContactToCompanyAssociationDefinition,
 *   objectId: 12345
 * })
 *
 * if (loading) {
 *   // render loading state
 *   return <UILoadingSpinner />
 * }
 *
 * if (error) {
 *   // render error state
 *   return renderError(error);
 * }
 *
 * if (data) {
 *   // render data
 * }
 *
 * @param objectId - The objectId whose associated objects will be fetched
 * @param associationDefinition - The association definition to fetch associated
 * objects for
 * @param skip - If true, will not make any requests. Defaults to false.
 */
export const usePreviewAssociatedObjects = ({
  objectId,
  associationDefinition,
  skip = false
}) => {
  const isMounted = useIsMounted();
  const [state, setRawState] = useState({
    loading: !skip,
    error: false,
    data: defaultData
  });
  const setState = useCallback(nextValues => {
    if (isMounted.current) {
      setRawState(currentState => Object.assign({}, currentState, nextValues));
    }
  }, [isMounted]);
  useEffect(() => {
    if (skip || !associationDefinition) {
      return;
    }
    setState({
      loading: true,
      data: defaultData
    });
    AssociationsPreviewClient.fetch({
      objectIds: [objectId],
      associationDefinition
    }).then(results => {
      const resultsForObject = get(objectId, results) || [];
      setState({
        loading: false,
        data: resultsForObject
      });
    }).catch(responseError => {
      setState({
        loading: false,
        error: responseError
      });
    });
  }, [associationDefinition, objectId, setState, skip]);
  return state;
};