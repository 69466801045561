import { combineReducers } from '@reduxjs/toolkit';
import { auth } from './auth';
import { crmObjects } from './crmObjects';
import { overlay } from './overlay';
import { scopedUsers } from './scopedUsers';
import { tasks } from './tasks';
import { tasksSearchResults } from './tasksSearchResults';
import { temporarilyExcludeIds } from './temporarilyExcludeIds';
import { tempViewState } from './tempViewState';
import { userSettings } from './userSettings';
export const rootReducer = combineReducers({
  auth,
  crmObjects,
  tasks,
  tasksSearchResults,
  userSettings,
  overlay,
  scopedUsers,
  tempViewState,
  temporarilyExcludeIds
});