import { createReducer } from '@reduxjs/toolkit';
import { fetchLinkedInIntegrationStatus, initAuth, setJitaPretendOwnerId } from '../actions/auth';
const initialState = {
  // these initial states will be overwritten immediately
  user: undefined,
  gates: [],
  portal: undefined,
  // Loaded directly from auth and is never changed, let's us determine if a user is a JITA user, even after updating the ownerId
  originalOwnerId: null,
  ownerId: null,
  // these may come later
  marketplaceIntegrations: {}
};
export const auth = createReducer(initialState, builder => {
  builder.addCase(initAuth, (state, action) => {
    state.user = action.payload.user;
    state.gates = action.payload.gates;
    state.portal = action.payload.portal;
    state.ownerId = action.payload.ownerId;
    state.originalOwnerId = action.payload.ownerId;
  }).addCase(setJitaPretendOwnerId, (state, action) => {
    state.ownerId = action.payload.ownerId;
  }).addCase(fetchLinkedInIntegrationStatus.fulfilled, (state, action) => {
    state.marketplaceIntegrations = state.marketplaceIntegrations || {};
    state.marketplaceIntegrations[action.payload.appId] = action.payload.status;
  });
});