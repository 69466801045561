import { useEffect, useMemo } from 'react';
// @ts-expect-error untyped
import { useDispatch, useSelector } from '../context/Redux';
import { fetchLinkedInIntegrationStatus } from '../redux/actions/auth';
import { didFetchLinkedInIntegrationStatusSelector, gatesSelector, hasCrmImportScope, hasLinkedInIntegrationSelector, isJitaUserSelector, ownerIdSelector, userScopesSelector, userSelector, userTeamsSelector } from '../redux/selectors/auth';
export const useOwnerId = () => {
  return useSelector(ownerIdSelector);
};
export const useUserScopes = () => useSelector(userScopesSelector);
export const useUserGates = () => useSelector(gatesSelector);
export const useUserTeamIds = () => {
  const teams = useSelector(userTeamsSelector);
  const teamsIds = useMemo(() => teams.map(team => team.id), [teams]);
  return teamsIds;
};
export const useUserId = () => {
  const user = useSelector(userSelector);
  return user.user_id;
};
export function useLinkedInIntegrationStatus() {
  const dispatch = useDispatch();
  const didFetchStatus = useSelector(didFetchLinkedInIntegrationStatusSelector);
  useEffect(() => {
    if (!didFetchStatus) {
      dispatch(fetchLinkedInIntegrationStatus());
    }
  }, [dispatch, didFetchStatus]);
  return useSelector(hasLinkedInIntegrationSelector);
}
export function useIsJitaUser() {
  return useSelector(isJitaUserSelector);
}
export function useCanImportTasks() {
  return useSelector(hasCrmImportScope);
}