export const SLASH_COMMANDS = {
  inbox_write_for_me_v1: {
    labelKey: 'inlineAutocompleteMenu.draft.label',
    commandId: 'inbox_write_for_me_v1',
    id: 'inbox_write_for_me_v1',
    headerKey: 'inlineAutocompleteMenu.draft.label',
    placeholder: 'inlineAutocompleteMenu.draft.placeholder'
  },
  generate_summary: {
    labelKey: 'inlineAutocompleteMenu.summarizeConv.label',
    commandId: 'inbox_draft_message_v1',
    id: 'generate_summary',
    headerKey: 'inlineAutocompleteMenu.summarizeConv.label',
    placeholder: 'inlineAutocompleteMenu.summarizeConv.placeholder'
  }
};
export const SELECTION_COMMANDS = [{
  labelKey: 'inlineAutocompleteMenu.changeTone.label',
  commandId: 'inbox_selection_change_tone_v1',
  headerKey: 'inlineAutocompleteMenu.changeTone.label',
  subMenu: [{
    labelKey: 'inlineAutocompleteMenu.changeTone.friendly.label',
    headerKey: 'inlineAutocompleteMenu.changeTone.friendly.label',
    parameters: {
      toneOfVoice: 'friendly'
    }
  }, {
    labelKey: 'inlineAutocompleteMenu.changeTone.heartfelt.label',
    headerKey: 'inlineAutocompleteMenu.changeTone.heartfelt.label',
    parameters: {
      toneOfVoice: 'heartfelt'
    }
  }, {
    labelKey: 'inlineAutocompleteMenu.changeTone.professional.label',
    headerKey: 'inlineAutocompleteMenu.changeTone.professional.label',
    parameters: {
      toneOfVoice: 'professional'
    }
  }]
}, {
  labelKey: 'inlineAutocompleteMenu.rewrite.label',
  commandId: 'inbox_selection_rewrite_v1',
  headerKey: 'inlineAutocompleteMenu.rewrite.label'
}, {
  labelKey: 'inlineAutocompleteMenu.expand.label',
  commandId: 'inbox_selection_expand_v1',
  headerKey: 'inlineAutocompleteMenu.expand.label'
}, {
  labelKey: 'inlineAutocompleteMenu.shorten.label',
  commandId: 'inbox_selection_shorten_v1',
  headerKey: 'inlineAutocompleteMenu.shorten.label'
}, {
  labelKey: 'inlineAutocompleteMenu.proofread.label',
  commandId: 'inbox_selection_fix_v1',
  headerKey: 'inlineAutocompleteMenu.proofread.label'
}];