// This duplicates the view ids declared in sales.proto
// https://git.hubteam.com/HubSpot/Sales/blob/master/SalesCore/src/main/protobuf/sales.proto#L22-40
const VIEW_NAME_TO_ID = {
  all: -2,
  due_today: -18,
  overdue: -19,
  upcoming: -20,
  most_engaged: -21,
  completed: -22
};
const VIEW_ID_TO_NAME = Object.keys(VIEW_NAME_TO_ID).reduce((acc, key) => Object.assign({}, acc, {
  [String(VIEW_NAME_TO_ID[key])]: key
}), {});
export const get = viewId => Object.prototype.hasOwnProperty.call(VIEW_NAME_TO_ID, viewId) ? VIEW_NAME_TO_ID[viewId] : viewId;
export const lookup = viewId => Object.prototype.hasOwnProperty.call(VIEW_ID_TO_NAME, viewId) ? VIEW_ID_TO_NAME[viewId] : viewId;