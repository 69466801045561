import { fetchContactsByEmail } from '../../api/contacts';
import { reportNewTasksError, GROUP_NAMES } from '../../tracking/errors';
import { getCrmObjectsAsIdMaps } from '../../formatters/graphQLResults';
import { createAsyncThunk } from '@reduxjs/toolkit';
export const getContactRecordsByEmail = createAsyncThunk('getContactRecordsByEmail', emails => {
  return fetchContactsByEmail(emails).then(contacts => {
    const crmObjectContacts = getCrmObjectsAsIdMaps(Object.values(contacts));
    return {
      objects: crmObjectContacts
    };
  }, error => {
    reportNewTasksError(error, {
      groupName: GROUP_NAMES.CRM_OBJECT,
      functionName: 'getContactRecordsByEmail'
    });
    return {
      objects: {}
    };
  });
});