import { COMPANY, CONTACT, DEAL, ENGAGEMENT, FEEDBACK_SUBMISSION, LINE_ITEM, OBJECT_LIST, PRODUCT, QUOTE, TASK, TICKET, VISIT, FOLDER } from './ObjectTypes';
export default {
  [COMPANY]: ['companyId'],
  [CONTACT]: ['vid'],
  [DEAL]: ['dealId'],
  [ENGAGEMENT]: ['engagement', 'id'],
  [FEEDBACK_SUBMISSION]: ['objectId'],
  [LINE_ITEM]: ['objectId'],
  [OBJECT_LIST]: ['listId'],
  [PRODUCT]: ['objectId'],
  [QUOTE]: ['objectId'],
  [TASK]: ['engagement', 'id'],
  [TICKET]: ['objectId'],
  [VISIT]: ['domain'],
  [FOLDER]: ['folderId']
};