import filter from './filter';
function isLinkAtPos(pos) {
  return state => {
    const node = state.doc.nodeAt(pos);
    return !!node && state.schema.marks.link.isInSet(node.marks);
  };
}

// https://github.com/YacheLee/Aditor/blob/master/src/packages/Aditor/plugins/LinkPlugin/setLinkText.js
export function setLinkText(text, pos, to) {
  return filter([isLinkAtPos(pos)], (state, dispatch) => {
    const $pos = state.doc.resolve(pos);
    const node = state.doc.nodeAt(pos);
    if (!node) return false;
    const mark = state.schema.marks.link.isInSet(node.marks);
    if (node && text.length > 0 && text !== node.text && mark) {
      const rightBound = to && pos !== to ? to : pos - $pos.textOffset + node.nodeSize;
      const {
        tr
      } = state;
      tr.insertText(text, pos, rightBound);
      tr.addMark(pos, pos + text.length, mark);
      if (dispatch) {
        dispatch(tr);
      }
      return true;
    }
    return false;
  });
}
export function getLinkText(doc, markBounds) {
  const {
    from,
    to
  } = markBounds;
  return doc.textBetween(from, to);
}
function isTextAtPos(pos) {
  return function (state) {
    const node = state.doc.nodeAt(pos);
    return !!node && node.isText;
  };
}

// modified from https://github.com/YacheLee/Aditor/blob/master/src/packages/Aditor/plugins/LinkPlugin/setLinkHref.js
export function setLinkMark(markProps, pos, to) {
  return filter([isTextAtPos(pos)], (state, dispatch) => {
    const $pos = state.doc.resolve(pos);
    const node = state.doc.nodeAt(pos);
    const linkMark = state.schema.marks.link;
    if (!node) return false;
    const mark = linkMark.isInSet(node.marks);
    const url = markProps.href;
    if (mark && mark.attrs.href === url && mark.attrs.isTargetBlank === markProps.isTargetBlank) {
      return false;
    }
    const rightBound = to && pos !== to ? to : pos - $pos.textOffset + node.nodeSize;
    const tr = state.tr.removeMark(pos, rightBound, linkMark);
    if (url.trim()) {
      const linkMarkProps = Object.assign({}, mark && mark.attrs || {}, markProps);
      tr.addMark(pos, rightBound, linkMark.create(linkMarkProps));
    }
    if (dispatch) {
      dispatch(tr);
    }
    return true;
  });
}
export function getLinkAttrs(state, markBounds) {
  const {
    from
  } = markBounds;
  const node = state.doc.nodeAt(from);
  const linkMark = state.schema.marks.link;
  if (!node) return null;
  const mark = linkMark.isInSet(node.marks);
  return mark ? mark.attrs : null;
}
function checkSelectionInsideLink(state) {
  return state.schema.marks.link.isInSet(state.selection.$from.marks());
}
function checkSelectionAroundLink(state) {
  const {
    $from
  } = state.selection;
  const nodeAfter = $from.nodeAfter;
  const nodeBefore = $from.nodeBefore;
  let marks = nodeAfter ? nodeAfter.marks : [];
  marks = nodeBefore ? marks.concat(nodeBefore.marks) : marks;
  if ($from.textOffset === 0) {
    return state.schema.marks.link.isInSet(marks);
  }
  return undefined;
}
export function getActiveLinkMark(state) {
  return checkSelectionInsideLink(state) || checkSelectionAroundLink(state);
}
export function makeLinkNode(schema, href, linkText, isTargetBlank, noFollow, extraMarks = []) {
  return schema.text(linkText, schema.marks.link.create({
    href,
    title: linkText,
    isTargetBlank,
    rel: noFollow ? 'nofollow' : undefined
  }).addToSet(extraMarks));
}