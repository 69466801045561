const isInteger = value => typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
export default function convertToInt(unknown) {
  if (unknown === undefined || unknown === null) return unknown;
  if (isInteger(unknown)) return unknown;

  // floats, bools, objects
  if (typeof unknown !== 'string') return null;
  const parsed = Number(unknown);
  if (!isInteger(parsed)) return null;
  return parsed;
}