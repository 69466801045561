import { useEffect, useReducer } from 'react';
export function useForceUpdate() {
  const [count, forceUpdate] = useReducer(x => x + 1, 0);
  return {
    count,
    forceUpdate
  };
}
export function useRerenderOnWindowResize() {
  // Trigger a rerender when window is resized to recalculate table height
  const {
    count,
    forceUpdate
  } = useForceUpdate();
  useEffect(() => {
    window.addEventListener('resize', forceUpdate);
    // cleans up event listener
    return () => window.removeEventListener('resize', forceUpdate);
  }, [forceUpdate]);
  return count;
}