import { createReducer } from '@reduxjs/toolkit';
import merge from 'transmute/merge';
import set from 'transmute/set';
import setIn from 'transmute/setIn';
import omit from 'transmute/omit';
import { cleanAssociations } from '../../utils/taskForms';
import { addNewTask, createFollowUpTask, deleteAllTasks, deleteTask, deleteTasks, getTaskById, mergeTaskPropertyUpdates, updateTask } from '../actions/tasksCRUD';
import { graphQLFetchTasksSucceeded } from '../actions/tasksSearch';
const initialState = {};
function mergeTask(state, task) {
  const taskId = task.objectId;
  const existingTask = state[taskId];
  let taskToSave = task;
  if (existingTask) {
    const oldProperties = existingTask.properties;
    const mergedProperties = merge(task.properties, oldProperties);
    const oldAssociations = existingTask.associations;
    // we don't want merge id arrays, just the top level association keys
    // filter incoming associations for valid ones only
    const shallowMergedAssociations = merge(cleanAssociations(task.associations), oldAssociations);
    taskToSave = merge(task, existingTask);
    taskToSave = set('properties', mergedProperties, taskToSave);
    // @ts-expect-error TODO clean up errors due to association formats
    taskToSave = set('associations', shallowMergedAssociations, taskToSave);
  }
  return Object.assign({}, state, {
    [taskId]: taskToSave
  });
}
function mergePropertyUpdates(state, taskId, propertyUpdates) {
  const task = state[taskId];
  if (!task) {
    return state;
  }
  const updatedTask = task.update('properties', properties => propertyUpdates.reduce((newProperties, {
    name,
    value
  }) => {
    return setIn([name, 'value'], value, newProperties);
  }, properties));
  return Object.assign({}, state, {
    [taskId]: updatedTask
  });
}
export const tasks = createReducer(initialState, builder => builder.addCase(graphQLFetchTasksSucceeded, (state, action) => {
  return action.payload.tasks.reduce((newState, task) => {
    return mergeTask(newState, task);
  }, state);
}).addCase(addNewTask, (state, action) => {
  return mergeTask(state, action.payload.task);
}).addCase(createFollowUpTask.fulfilled, (state, action) => {
  if (action.payload) {
    return mergeTask(state, action.payload.task);
  }
  return state;
}).addCase(updateTask.fulfilled, (state, action) => {
  if (action.payload) {
    return mergeTask(state, action.payload.task);
  }
  return state;
}).addCase(getTaskById.fulfilled, (state, action) => {
  if (action.payload) {
    return mergeTask(state, action.payload.task);
  }
  return state;
}).addCase(mergeTaskPropertyUpdates, (state, action) => {
  if (action.payload.taskIds) {
    const updatedState = action.payload.taskIds.reduce((newState, taskId) => {
      return mergePropertyUpdates(newState, taskId, action.payload.propertyUpdates);
    }, state);
    return updatedState;
  }
  return state;
}).addCase(deleteAllTasks, () => {
  return {};
}).addCase(deleteTasks, (state, action) => {
  return omit(action.payload.taskIds.map(String), state);
}).addCase(deleteTask.fulfilled, (state, action) => {
  if (action.payload) {
    delete state[String(action.payload.taskId)];
  }
}));