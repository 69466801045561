import { KNOWN, NOT_EQUAL, NOT_IN } from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import InboundDbImportProperty from 'customer-data-objects/property/special/InboundDbImportProperty';
import { COMPLETION_DATE, CONTACT_TIMEZONE, CREATED_BY, CREATED_DATE, DUE_DATE, ENGAGEMENT_SOURCE, ENGAGEMENT_SOURCE_ID, LAST_ENGAGEMENT, LAST_TOUCH, OWNER_ASSIGNED_DATE, OWNER_ID, PRIORITY, QUEUE_IDS, REPEAT_STATUS, SEQUENCE_STEP_ORDER, STATUS, TYPE } from 'customer-data-objects/task/TaskPropertyNames';
export const TASK_TEMPLATE = 'TASK_TEMPLATE';
// this property is on the TASK_TEMPLATE object and rolls up to hs_repeat_status on TASK
// we need to fetch this property in order to get the options available
export const TASK_REPEAT_STATUS = 'hs_task_repeat_status';
export const FILTER_PROPERTIES_ALLOW_LIST = [STATUS, PRIORITY, TYPE, DUE_DATE, COMPLETION_DATE, CONTACT_TIMEZONE, CREATED_BY, CREATED_DATE, ENGAGEMENT_SOURCE, ENGAGEMENT_SOURCE_ID,
// HACK: This is a psuedo-property (magic) that is accepted by crmsearch but is not a real property.
// Can be used to filter by things like import ID, but should not be used as table columns
InboundDbImportProperty.name, LAST_ENGAGEMENT, LAST_TOUCH, SEQUENCE_STEP_ORDER, OWNER_ASSIGNED_DATE, OWNER_ID, QUEUE_IDS, REPEAT_STATUS];
export const ME_OWNER = '__hs__ME';
export const NO_OWNER = 'NO_OWNERS';
export const CreationSourceOptions = {
  ALL: 'ALL',
  USER: 'USER',
  WORKFLOW: 'WORKFLOWS',
  SEQUENCE: 'SEQUENCES',
  SALESFORCE: 'SALESFORCE',
  // Workflow-created tasks can also have the source AUTOMATION_PLATFORM
  AUTOMATION_PLATFORM: 'AUTOMATION_PLATFORM'
};

// for certain filter operators, we don't want to check any of the boxes in the select dropdown
export const OPERATORS_TO_NOT_SHOW_CHECKS_IN_SELECT = [NOT_IN, NOT_EQUAL, KNOWN];