import { useCallback } from 'react';
import { bulkDeleteTasks, bulkUpdateTasks } from '../../api/tasks';
import { SELECTION_METHOD_BY_QUERY } from '../../constants/selectionMethods';
import { useBulkActionsNumberOfSelectedObjects } from '../useBulkActionsNumberOfSelectedObjects';
import { useBulkActionsSelectionState } from '../useBulkActionsSelectionState';
import { alertBulkActionFailure, alertBulkActionSuccess } from '../../alerts/bulkActionsAlerts';
import { DefaultBulkActionsDeleteTasksOnFailure, DefaultBulkActionsDeleteTasksOnSuccess } from '../../components/buttons/tasks/DeleteTasks';
export const INVALID_BULK_EDIT_ERROR = 'INVALID_VALUE_BULK_EDIT';
export const DefaultBulkActionsUpdateTasksOnSuccess = ({
  count
}) => alertBulkActionSuccess({
  count,
  langkey: 'bulkActions.alerts.tasks.generic.success'
});
export const DefaultBulkActionsUpdateTasksOnFailure = ({
  count
}) => alertBulkActionFailure({
  count,
  langkey: 'bulkActions.alerts.tasks.generic.failure'
});
export const useOnBulkUpdateTasks = (logFunction = () => {}) => {
  const {
    selectionMethod,
    selectionIds = [],
    selectionCrmSearchQuery
  } = useBulkActionsSelectionState();
  const count = useBulkActionsNumberOfSelectedObjects();
  const onBulkUpdateTasks = useCallback(({
    propertyUpdates,
    onSuccess = DefaultBulkActionsUpdateTasksOnSuccess,
    onFailure = DefaultBulkActionsUpdateTasksOnFailure
  }) => {
    const allSelected = selectionMethod === SELECTION_METHOD_BY_QUERY;
    const taskIdentifierOption = allSelected ? {
      crmSearchRequestOptions: selectionCrmSearchQuery
    } : {
      taskIds: selectionIds
    };
    return bulkUpdateTasks(Object.assign({}, taskIdentifierOption, {
      propertyUpdates
    })).then(() => {
      propertyUpdates.forEach(({
        name,
        value
      }) => {
        logFunction(true, {
          propertyName: name,
          value,
          count,
          allItems: Boolean(selectionCrmSearchQuery)
        });
      });
      onSuccess({
        count,
        propertyUpdates
      });
    }).catch(error => {
      logFunction(false, {
        error,
        count,
        propertyUpdates,
        functionName: 'bulkUpdateTasks'
      });
      onFailure({
        count,
        error
      });
    });
  }, [count, logFunction, selectionIds, selectionMethod, selectionCrmSearchQuery]);
  return onBulkUpdateTasks;
};
export const useOnBulkDeleteTasks = (logFunction = () => {}) => {
  const {
    selectionMethod,
    selectionIds = [],
    selectionCrmSearchQuery
  } = useBulkActionsSelectionState();
  const count = useBulkActionsNumberOfSelectedObjects();
  return useCallback(({
    onSuccess = DefaultBulkActionsDeleteTasksOnSuccess,
    onFailure = DefaultBulkActionsDeleteTasksOnFailure
  }) => {
    const allSelected = selectionMethod === SELECTION_METHOD_BY_QUERY;
    const taskIdentifierOption = allSelected ? {
      crmSearchRequestOptions: selectionCrmSearchQuery
    } : {
      taskIds: selectionIds
    };
    bulkDeleteTasks(taskIdentifierOption).then(() => {
      logFunction(true, {
        allItems: allSelected
      });
      onSuccess({
        allSelected,
        count
      });
    }).catch(error => {
      logFunction(false, {
        error,
        count,
        functionName: 'bulkDeleteTasksMatchingSearch'
      });
      onFailure({
        count,
        error
      });
    });
  }, [count, logFunction, selectionCrmSearchQuery, selectionIds, selectionMethod]);
};