import { baseKeymap, chainCommands, createParagraphNear, liftEmptyBlock, newlineInCode, splitBlockKeepMarks } from 'prosemirror-commands';
import { history, redo, undo } from 'prosemirror-history';
import { keymap } from 'prosemirror-keymap';
import { toggleBold } from './marks/BoldMark';
import { toggleItalic } from './marks/ItalicMark';
import { toggleUnderline } from './marks/UnderlineMark';
import { getInputRules } from './plugins/InputRulePlugin';
import { LinkPlugin } from './plugins/link/LinkPlugin';
import { schema as defaultSchema } from './ProsemirrorSchema';
import { liftListItem, sinkListItem, splitListItemKeepMarks } from './utils/listUtils';
const customSplitBlock = (state, dispatch) => {
  const {
    $from: previousSelectionFrom
  } = state.selection;
  return splitBlockKeepMarks(state, tr => {
    if (dispatch) {
      const targetNodePosition = tr.selection.$from.before();
      const sourceNode = previousSelectionFrom.node();
      const marks = state.storedMarks || state.selection.$to.parentOffset && previousSelectionFrom.marks();
      const {
        align,
        dir,
        level
      } = sourceNode.attrs;
      tr.setNodeMarkup(targetNodePosition, undefined, {
        align,
        dir,
        level
      });
      if (marks) tr.ensureMarks(marks);
      dispatch(tr);
    }
  });
};
const enterCommand = chainCommands(splitListItemKeepMarks(schema => schema.nodes.list_item), newlineInCode, createParagraphNear, liftEmptyBlock, customSplitBlock);
const buildKeyMap = options => {
  const linkCommand = (state, dispatch) => {
    if (dispatch) {
      dispatch(state.tr.setMeta(LinkPlugin, {
        keyComboFired: true
      }));
      return true;
    }
    return false;
  };
  const formattingKeys = {
    'Mod-b': toggleBold,
    'Mod-B': toggleBold,
    'Mod-u': toggleUnderline,
    'Mod-U': toggleUnderline,
    'Mod-i': toggleItalic,
    'Mod-I': toggleItalic
  };
  const enableFormattingKeys = !options.usePlaintextMode || options.plaintextOptions && options.plaintextOptions.allowBasicFormatting;
  return keymap(Object.assign({}, baseKeymap, {
    'Mod-z': undo,
    'Mod-Z': undo,
    'Mod-y': redo,
    'Mod-Y': redo,
    'Mod-Shift-z': redo,
    'Mod-Shift-Z': redo
  }, enableFormattingKeys && formattingKeys, {
    'Mod-k': linkCommand,
    'Mod-K': linkCommand,
    Enter: enterCommand,
    'Shift-Enter': enterCommand,
    'Alt-Enter': enterCommand,
    Tab: sinkListItem(schema => schema.nodes.list_item),
    'Shift-Tab': liftListItem(schema => schema.nodes.list_item)
  }, options.handleOnEnter && {
    Enter: options.handleOnEnter
  }));
};
export const config = (customPlugins = [], keyMapOptions = {}, schema = defaultSchema) => ({
  schema,
  plugins: [...customPlugins, history(), ...(keyMapOptions.usePlaintextMode ? [] : [getInputRules(schema)]), buildKeyMap(keyMapOptions)]
});