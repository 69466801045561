export const SAME_TIME = 'sameTime';
export const HALF_HOUR_BEFORE = 'halfHourBefore';
export const HOUR_BEFORE = 'hourBefore';
export const DAY_BEFORE = 'dayBefore';
export const WEEK_BEFORE = 'weekBefore';
export const TODAY = 'today';
export const TOMORROW = 'tomorrow';
export const ONE_BUSINESS_DAY = 'oneBusinessDay';
export const TWO_BUSINESS_DAYS = 'twoBusinessDays';
export const THREE_BUSINESS_DAYS = 'threeBusinessDays';
export const ONE_WEEK = 'oneWeek';
export const TWO_WEEKS = 'twoWeeks';
export const ONE_MONTH = 'oneMonth';
export const THREE_MONTHS = 'threeMonths';
export const SIX_MONTHS = 'sixMonths';
export const CUSTOM = 'CUSTOM';