'use es6';

import { indexByIdString } from 'customer-data-objects/protocol/Identifiable';
import { List, Map as ImmutableMap } from 'immutable';
import * as ImmutableAPI from '../api/ImmutableAPI';
import { EMPTY } from 'crm_data/constants/LoadingStatus';
import makeBatch from '../api/makeBatch';
import OwnerRecord from 'customer-data-objects/owners/OwnerRecord';
import PaginatedSearchResponse from 'customer-data-objects/search/PaginatedSearchResponse';
import Raven from 'raven-js';
import { logError } from 'customer-data-ui-utilities/eventLogging/eventLogger';
export const BASE_PATH = 'owners/v2/owners';
const handleFetchAllError = (error, pagedOffset, usePaged) => logError({
  error,
  extraData: {
    pagedOffset,
    usePaged
  }
});
export function fetchAll(pagedOffset = 0, usePaged = false) {
  if (usePaged) {
    return ImmutableAPI.get(`${BASE_PATH}/paged`, {
      includeSignature: false,
      offset: pagedOffset,
      limit: 500
    }, ({
      results,
      offset,
      hasMore
    }) => {
      return {
        owners: List(results.map(OwnerRecord.fromJS)),
        offset,
        hasMore
      };
    }).catch(error => handleFetchAllError(error, pagedOffset, usePaged));
  }
  return ImmutableAPI.get(`${BASE_PATH}`, {
    includeSignature: false
  }, owners => List(owners.map(OwnerRecord.fromJS))).catch(error => handleFetchAllError(error, pagedOffset, usePaged));
}
export function legacyFetchActiveOwners() {
  return fetchAll().then(indexByIdString);
}
export function updateOwner(owner) {
  const ownerId = owner.get('ownerId');
  return ImmutableAPI.put(`${BASE_PATH}/id/${ownerId}`, owner);
}
export function fetchCurrentOwner() {
  return ImmutableAPI.get(`${BASE_PATH}/current/remotes`, undefined, OwnerRecord.fromJS).catch(error => {
    // a 404 means this is a JITA user
    if (error.status === 404) {
      return EMPTY;
    }
    throw error;
  });
}
export function fetchByEmailFlat(email) {
  const error = new Error('OwnersAPI#fetchByEmailFlat - owner resolved via email alias');
  return ImmutableAPI.get(`${BASE_PATH}/email/${encodeURIComponent(email)}`, {
    includeSignature: false
  }, owners => owners && owners.length > 0 ? OwnerRecord.fromJS(owners[0]) : Promise.reject(new Error(`No matching owner found for email: ${email}`))).then(owner => {
    // Temporarily added to identify all stores/components using this API
    // https://git.hubteam.com/HubSpot/CRM-Issues/issues/3545
    if (!email || !owner.get('email')) {
      return owner;
    }
    if (email.toUpperCase() !== owner.get('email').toUpperCase()) {
      Raven.captureException(error, {
        extra: {
          emailAlias: email,
          ownerEmail: owner.get('email')
        }
      });
    }
    return owner;
  });
}
export const fetchByEmails = makeBatch(fetchByEmailFlat, 'OwnersAPI.fetchByEmailFlat');
export function fetchByEmail(email) {
  return fetchByEmailFlat(email).then(owner => List.of(owner));
}
export function fetchById(ownerId) {
  return ImmutableAPI.get(`${BASE_PATH}/${ownerId}`, {
    includeSignature: false
  }, OwnerRecord.fromJS);
}
export function fetchByIds(ownerIds) {
  return ImmutableAPI.get(`${BASE_PATH}/batch-v2`, {
    includeSignature: false,
    ownerId: ownerIds.valueSeq().toJS()
  }, json => ImmutableMap(json).map(OwnerRecord.fromJS));
}
export function fetchByRemoteIdFlat(remoteId) {
  return ImmutableAPI.get(`${BASE_PATH}/remoteId/${remoteId}`, {
    includeSignature: false
  }, owners => owners && owners.length > 0 ? OwnerRecord.fromJS(owners[0]) : Promise.reject(new Error(`No matching owner found for remoteId: ${remoteId}`)));
}
export const fetchByRemoteIds = makeBatch(fetchByRemoteIdFlat, 'OwnersAPI.fetchByRemoteIdFlat');
export function fetchByRemoteId(remoteId) {
  return fetchByRemoteIdFlat(remoteId).then(owner => List.of(owner));
}
const fromElasticSearch = PaginatedSearchResponse.fromElasticSearch({
  getHasMore: response => response.hasMore,
  getObjects: response => response.results,
  getTotal: response => response.hasMore ? response.total : response.results.length,
  getObjectId: owner => owner.ownerId,
  objectFromJS: OwnerRecord.fromJS
});
export function searchOwners(searchQuery) {
  const {
    count,
    offset,
    query
  } = searchQuery;
  return ImmutableAPI.get(`${BASE_PATH}/search`, {
    limit: count,
    offset,
    search: query
  }, fromElasticSearch(searchQuery));
}
export const searchOwnersBatch = makeBatch(searchOwners, 'OwnersAPI.searchOwners');