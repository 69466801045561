import { fetchUsersWithScope } from '../../api/scopedUsers';
import { GROUP_NAMES, reportNewTasksError } from '../../tracking/errors';
import { createAsyncThunk } from '@reduxjs/toolkit';
export const getUsersWithScope = createAsyncThunk('getUsersWithScope', scope => {
  return fetchUsersWithScope(scope).then(userIds => {
    return {
      scope,
      userIds
    };
  }, error => {
    reportNewTasksError(error, {
      groupName: GROUP_NAMES.QUEUES,
      functionName: 'getUsersWithScope'
    });
    return {
      scope,
      userIds: []
    };
  });
});