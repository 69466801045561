'use es6';

import { parse } from 'hub-http/helpers/params';
import dispatcher from 'dispatcher/dispatcher';
import { NAVIGATE } from './NavigationActionTypes';
import { getPathname } from '../getPathname';
import { getSearch } from '../getSearch';
export const getHubSpotBasename = () => {
  const hubspotBasePathnameRegex = /^\/[\w-]+\/\d+/;
  const parts = getPathname().match(hubspotBasePathnameRegex);
  return parts ? parts[0] : null;
};
export function navigate(pathName, params) {
  const fragment = getPathname().replace(getHubSpotBasename(), '');
  const queryParams = parse(getSearch().substring(1));
  return dispatcher.dispatch({
    actionType: NAVIGATE,
    data: {
      fragment,
      params,
      pathName,
      queryParams
    }
  });
}