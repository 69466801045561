export function findNode(nodeToSearch, predicate) {
  let found;
  nodeToSearch.descendants((node, pos, parent) => {
    if (parent && predicate(node, parent)) found = {
      node,
      pos
    };
    if (found) return false;
    return true;
  });
  return found;
}
export function findAllNodes(nodeToSearch, predicate) {
  const nodesFound = [];
  nodeToSearch.descendants((node, pos, parent) => {
    if (parent && predicate(node, parent)) nodesFound.push({
      node,
      pos
    });
    return true;
  });
  return nodesFound;
}
export function replaceAllNodes(editorState, predicate, transform) {
  let transaction = editorState.tr.setMeta('addToHistory', false);
  const nodesFound = findAllNodes(editorState.doc, predicate);
  nodesFound.forEach(nodepos => {
    transaction = transform(nodepos, transaction);
  });
  return editorState.apply(transaction);
}

// Starting with the node, Check each node to see if it in the Parent tag and
// if so add the StyleString to each of its matching Child Tags styles.
// If it is not, then run this function on each of its children.
export function assignStyleToChildTagInParentTag(node, StyleString, nameOfChildTag, nameOfParentTag) {
  if (node.nodeName === nameOfParentTag) {
    node.childNodes.forEach(child => {
      const childElement = child;
      if (childElement.nodeName === nameOfChildTag) {
        childElement.setAttribute('style', `${childElement.getAttribute('style')} ${StyleString}`);
      }
    });
  }
  if (node.childNodes) {
    node.childNodes.forEach(child => {
      assignStyleToChildTagInParentTag(child, StyleString, nameOfChildTag, nameOfParentTag);
    });
  }
}
export function areAttrsEqual(attrs1, attrs2) {
  return attrs1 && attrs2 && Object.entries(attrs1).sort().toString() === Object.entries(attrs2).sort().toString();
}