import { registerQuery } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
export const jitaSelectedUserId = registerQuery({
  fieldName: 'jitaSelectedUserId',
  args: ['isJitaUser', 'selectedOwnerId'],
  fetcher: function jitaSelectedUserIdFetcher({
    isJitaUser,
    selectedOwnerId
  }) {
    if (isJitaUser && selectedOwnerId !== -1) {
      return apiClient.get(`/owners/v2/owners/${selectedOwnerId}`).then(response => response.activeUserId);
    }
    return Promise.resolve(null);
  }
});