import enviro from 'enviro';
import { getFullUrl } from 'hubspot-url-utils';
import PortalIdParser from 'PortalIdParser';
export function fullHubSpotUrl(pathname = '') {
  const subdomain = enviro.deployed() ? 'app' : 'local';
  return `${getFullUrl(subdomain)}${pathname}`;
}
export function getCalendarSettingsUrl() {
  return fullHubSpotUrl(`/settings/${PortalIdParser.get()}/user-preferences/calendar`);
}
export const navigateToURL = url => {
  window.location.href = url;
};
export const getIsHoldingMetaKey = () => {
  const clickEvent = window.event;
  const isHoldingMetaKey = clickEvent && clickEvent.metaKey;
  return isHoldingMetaKey;
};
export const navigateToURLInNewTab = url => {
  window.open(url, '_blank');
};