import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["activeBranchNumber"],
  _excluded2 = ["activeBranchNumber"];
/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-promise-reject-errors */

import http from 'hub-http/clients/apiClient';
import { TASK_DEPENDENCY_EMPTY } from '../utils/TasksUtils';

// Map from vid -> enrollment object

function doEnroll(sequence, email) {
  // As part of dynamic sequence changes, `activeBranchNumber` is needed for enrollment request payload
  // If the dynamic sequence is gated, `activeBranchNumber` will be `null`
  const _sequence$toJS = sequence.toJS(),
    {
      activeBranchNumber
    } = _sequence$toJS,
    sequenceData = _objectWithoutPropertiesLoose(_sequence$toJS, _excluded);
  const enrollment = activeBranchNumber !== null ? {
    activeBranchNumber,
    sequence: sequenceData,
    steps: []
  } : {
    sequence: sequenceData,
    steps: []
  };
  const data = {
    email,
    enrollment
  };
  const options = {
    data,
    timeout: 12000
  };
  return http.post('sequences/v2/enrollments/enroll', options);
}
function doBulkEnroll(enrollments) {
  const data = enrollments.map((enrollment, id) => {
    // As part of dynamic sequence changes, `activeBranchNumber` is needed for enrollment request payload
    // If the dynamic sequence is gated, `activeBranchNumber` will be `null`
    const _enrollment$toJS = enrollment.toJS(),
      {
        activeBranchNumber
      } = _enrollment$toJS,
      enrollmentData = _objectWithoutPropertiesLoose(_enrollment$toJS, _excluded2);
    const enrollmentPayload = activeBranchNumber !== null ? {
      sequence: enrollmentData,
      activeBranchNumber
    } : {
      sequence: enrollmentData
    };
    return {
      enrollment: enrollmentPayload,
      vid: id
    };
  }).toList();
  const options = {
    data,
    timeout: 60000
  };
  return http.post('sequences/v2/enrollments/enroll/batch', options);
}
function fetchStepEnrollmentsSummaries(stepEnrollmentIds) {
  return http.post('sequences/v2/step-enrollments/summaries/batch/read', {
    data: {
      inputs: stepEnrollmentIds
    }
  });
}
export const SequencesApi = {
  enroll(...args) {
    const obj = args[0];
    const {
      sequence,
      email
    } = obj;
    return doEnroll(sequence, email).then(res => {
      return Promise.resolve({
        isBulk: false,
        res,
        toEmail: res.toEmail,
        successesSize: 1,
        sequenceName: sequence.get('name')
      });
    }, err => {
      return Promise.reject({
        isBulk: false,
        errorType: err.responseJSON && err.responseJSON.errorType,
        toEmail: email,
        sequenceName: sequence.get('name'),
        err
      });
    });
  },
  bulkEnroll(enrollments) {
    const sequenceName = enrollments.first().get('name');
    return doBulkEnroll(enrollments).then(res => {
      const successes = res.successes;
      return Promise.resolve({
        isBulk: true,
        res,
        toEmail: null,
        successesSize: successes.length,
        sequenceName
      });
    }, err => {
      return Promise.reject({
        isBulk: true,
        errorType: err.responseText,
        sequenceName,
        toEmail: null,
        err,
        payloadSize: JSON.stringify(enrollments.toJS()).length
      });
    });
  },
  getStepEnrollmentsSummaries(stepEnrollmentIds) {
    return fetchStepEnrollmentsSummaries(stepEnrollmentIds).then(response => {
      const foundTaskDependency = response.results.find(taskDependencyMetadata => {
        return taskDependencyMetadata.dependentOnThisStep.find(step => step.resolvedAt === null);
      });
      return foundTaskDependency || TASK_DEPENDENCY_EMPTY;
    });
  }
};