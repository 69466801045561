'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["type", "dataType"];
import http from 'hub-http/clients/apiClient';
import { DELETE, GET, PATCH, POST, PUT } from '../constants/HTTPVerbs';
import Immutable, { Map as ImmutableMap, Record } from 'immutable';
import partial from 'transmute/partial';
import { parseUrl } from 'hub-http/helpers/url';
const ALL_NUMS_RE = /\d+/g;
const EMAIL_RE = /\S+@\S+\.\S+/g;
const NOT_FOUND_STATUS_CODE = 404;
const RATE_LIMIT_STATUS_CODE = 429;
// these two codes should not get filtered. we have a number of APIs we expect
// to 404 often (e.g. /profile endpoint for deduping new contacts), and we
// can assume that if an API is rate limited then it's not actively causing
// problems and can safely be retried.
const ALWAYS_ALLOW_CODES = [NOT_FOUND_STATUS_CODE, RATE_LIMIT_STATUS_CODE];
// 4 retries === 5 total requests
const MAX_API_RETRIES = 4;
// 5 minutes in milliseconds
const RETRY_RATE_LIMIT_TTL = 300000;

// rate limit newrelic events to avoid firing too many
const NR_SAMPLE_RATE = 0.5;
// only send timeouts if page is visible, can't do anything about background
// API timeouts, that's just due to throttling
const shouldSendToNewRelic = () => document.visibilityState === 'visible' && Math.random() < NR_SAMPLE_RATE;
export const RateLimitRecord = Record({
  count: 0,
  lastUpdated: 0
});
RateLimitRecord.count = (oldRecord = RateLimitRecord(), increment = 1) => oldRecord.merge({
  count: oldRecord.count + increment,
  lastUpdated: Date.now()
});
RateLimitRecord.clear = oldRecord => oldRecord.merge({
  count: 0,
  lastUpdated: Date.now()
});
export function getMethod(verb) {
  switch (verb) {
    case DELETE:
      return http.delete;
    case GET:
      return http.get;
    case PATCH:
      return http.patch;
    case POST:
      return http.post;
    case PUT:
      return http.put;
    default:
      throw new Error(`Unknown http method \`${verb}\``);
  }
}
function getAccept(dataType) {
  switch (dataType) {
    case 'html':
      return 'text/html';
    case 'text':
      return 'text/plain';
    default:
      return undefined;
    // this will make hub-http default to application/json
  }
}
function toJS(data) {
  if (!data || typeof data.toJSON !== 'function') {
    return data;
  }
  return data.toJSON();
}
export function sanitizeURI(path) {
  const pathParts = (parseUrl(path).path || '').split('/');
  const sanitizedParts = pathParts.map(part => decodeURIComponent(part).replace(EMAIL_RE, '*').replace(ALL_NUMS_RE, '*'));
  return sanitizedParts.join('/');
}
let clientErrorCounts = ImmutableMap();
export function trackClientErrorRetries(error, uri, clientErrors = ImmutableMap()) {
  uri = sanitizeURI(uri);
  if (error && error instanceof Error && error.status >= 400 && error.status <= 499 && !ALWAYS_ALLOW_CODES.includes(error.status)) {
    return clientErrors.set(uri, RateLimitRecord.count(clientErrors.get(uri)));
  }
  return clientErrors;
}
export function maxRetriesExceeded(uri, clientErrors = ImmutableMap()) {
  uri = sanitizeURI(uri);
  const recordForURI = clientErrors.get(uri, RateLimitRecord());
  if (Date.now() - recordForURI.lastUpdated > RETRY_RATE_LIMIT_TTL) {
    clientErrors = clientErrors.remove(uri);
    return false;
  }
  return recordForURI.count > MAX_API_RETRIES;
}
export function send(config, uri, data, fromJS = Immutable.fromJS) {
  const {
      type,
      dataType
    } = config,
    options = _objectWithoutPropertiesLoose(config, _excluded);
  if (type === GET) {
    options.query = toJS(data);
  } else {
    options.data = toJS(data);
  }
  const accept = getAccept(dataType);
  if (accept) {
    options.headers = options.headers || {};
    options.headers.Accept = accept;
  }
  if (maxRetriesExceeded(uri, clientErrorCounts)) {
    console.warn(`%cThe request to ${uri} has failed too many times within the past five minutes and any further requests will be automatically rejected for the next five minutes or until the page is reloaded. To prevent this, please ensure requests to this URL are coming back with valid responses, or remove the requests until it can be fixed.`, 'font-size: 1.125em; border: 1px solid white; padding: 8px;');
    // If making changes to RETRY_RATE_LIMIT_TTL, adjust the "5 minutes" text below
    return Promise.reject(new Error(`ImmutableAPI: max retries exceeded for URI ${sanitizeURI(uri)} (${MAX_API_RETRIES} retries in 5 minutes})`));
  }
  return getMethod(type)(uri, options).then(response => response ? fromJS(response) : response).catch(error => {
    // handle all TIMEOUT errors and send to newrelic for alerting purposes
    // https://git.hubteam.com/HubSpot/Critsit/issues/807
    if (error && error.errorCode === 'TIMEOUT') {
      const url = error.options && error.options.url || '';
      if (!url) {
        // no URL associated with timeout. just rethrow.
        // shouldn't be possible
        throw error;
      }
      const path = sanitizeURI(url);
      if (window.newrelic && window.newrelic.addPageAction && shouldSendToNewRelic()) {
        window.newrelic.addPageAction('crmApiTimeout', {
          apiPath: path
        });
      }
    }
    clientErrorCounts = trackClientErrorRetries(error, uri, clientErrorCounts);
    throw error;
  });
}
function make(type) {
  return partial(send, {
    type
  });
}
export const del = make(DELETE);
export { del as delete };
export const get = make(GET);
export const patch = make(PATCH);
export const post = make(POST);
export const put = make(PUT);