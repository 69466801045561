'use es6';

import { Set as ImmutableSet } from 'immutable';
import { selectionActions } from '../actions/selectionActions';
const _defaultInitialState = new ImmutableSet();
export default function selectionReducer(state = _defaultInitialState, action) {
  switch (action.type) {
    case selectionActions.SELECTION_ADD:
      return state.union(action.ids);
    case selectionActions.SELECTION_REMOVE:
      return state.subtract(action.ids);
    case selectionActions.SELECTION_CLEAR:
      return state.clear();
    default:
      return state;
  }
}