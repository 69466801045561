import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserSettings, saveUserSetting as saveUserSettingRequest } from '../../api/userSettings';
// @ts-expect-error untyped
import { fetchUserSettingsWithEarlyRequestHook } from '../../earlyRequester/fetchUserSettingsWithEarlyRequestHook';
import { GROUP_NAMES, reportNewTasksError } from '../../tracking/errors';
export const initUserSettings = createAsyncThunk('initUserSettings', ({
  userSettingsKeys,
  earlyRequestData
}) => {
  if (earlyRequestData) {
    const {
      attributes
    } = earlyRequestData;
    return Promise.resolve({
      hasError: false,
      settings: attributes
    });
  }
  const fallbackFetch = () => fetchUserSettings(userSettingsKeys);
  return fetchUserSettingsWithEarlyRequestHook(fallbackFetch).then(response => {
    const {
      attributes
    } = response;
    return {
      hasError: false,
      settings: attributes
    };
  }, error => {
    reportNewTasksError(error, {
      groupName: GROUP_NAMES.USER_SETTINGS,
      functionName: 'initUserSettings',
      extra: {
        message: 'failed to retrieve user settings'
      }
    });
    return {
      hasError: true,
      settings: []
    };
  });
});
export const initLocalStorageUserSettings = createAction('initLocalStorageUserSettings');
export const saveUserSetting = createAsyncThunk('saveUserSetting', ({
  key,
  value
}) => {
  return saveUserSettingRequest(key, value).then(response => {
    return {
      settings: [response]
    };
  }, error => {
    reportNewTasksError(error, {
      groupName: GROUP_NAMES.USER_SETTINGS,
      functionName: 'saveUserSetting',
      extra: {
        message: `failed to save user setting with key, ${key}, and value: ${JSON.stringify(value)}`
      }
    });
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
});
export const saveLocalStorageUserSetting = createAction('saveLocalStorageUserSetting');