import { Map as ImmutableMap, List } from 'immutable';
import makeObjectRecord from '../record/makeObjectRecord';
import { TASK } from '../constants/ObjectTypes';
import { ENGAGEMENT_TYPE, TITLE, NOTES } from './TaskPropertyNames';
import PropertyValueRecord from '../property/PropertyValueRecord';
const TaskRecord = makeObjectRecord({
  idKey: ['objectId'],
  objectType: TASK,
  recordName: 'TaskRecord',
  defaults: {
    deleted: false,
    objectId: null,
    associations: ImmutableMap({
      contactIds: List(),
      companyIds: List(),
      dealIds: List(),
      ticketIds: List()
    }),
    properties: ImmutableMap({
      [ENGAGEMENT_TYPE]: PropertyValueRecord({
        name: ENGAGEMENT_TYPE,
        value: TASK
      })
    })
  }
}, {
  primary: [TITLE],
  secondary: [NOTES]
});
export default TaskRecord;