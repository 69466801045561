/**
 * Mutates provided Node to convert `br` tags used to separate paragraphs/divs into `p` tags.
 * */
export function replaceHardBreaksWithParagraphs(dom) {
  if (dom instanceof Element) {
    dom.querySelectorAll('br').forEach(brTag => {
      var _brTag$previousElemen, _brTag$nextElementSib;
      const previousSiblingName = (_brTag$previousElemen = brTag.previousElementSibling) === null || _brTag$previousElemen === void 0 ? void 0 : _brTag$previousElemen.nodeName;
      const nextSiblingName = (_brTag$nextElementSib = brTag.nextElementSibling) === null || _brTag$nextElementSib === void 0 ? void 0 : _brTag$nextElementSib.nodeName;
      if (previousSiblingName === 'P' || previousSiblingName === 'DIV' || nextSiblingName === 'P' || nextSiblingName === 'DIV') {
        brTag.replaceWith(document.createElement('p'));
      }
    });
  }
}