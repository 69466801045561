import update from 'transmute/update';
import { OWNER_ID, TASK_FAMILY } from 'customer-data-objects/task/TaskPropertyNames';
import { ALL_DATE_PLACEHOLDERS, NOW_PLACEHOLDER, TODAY_DATE_PLACEHOLDER, TOMORROW_DATE_PLACEHOLDER } from '../constants/filters';
import { ME_OWNER } from '../constants/viewFilters';
import { getNowPlaceholderValue, getTodayDatePlaceholderValue, getTomorrowDatePlaceholderValue } from './getDatePlaceholderValues';
const resolvePlaceholder = placeholder => {
  switch (placeholder) {
    case NOW_PLACEHOLDER:
      return getNowPlaceholderValue();
    case TODAY_DATE_PLACEHOLDER:
      return getTodayDatePlaceholderValue();
    case TOMORROW_DATE_PLACEHOLDER:
      return getTomorrowDatePlaceholderValue();
    default:
      return placeholder;
  }
};
const transformOwnerFilterForJita = (ownerFilter, ownerIdOverride) => {
  const {
    value
  } = ownerFilter;
  const {
    values
  } = ownerFilter;
  if (value && value === ME_OWNER) {
    return Object.assign({}, ownerFilter, {
      value: ownerIdOverride
    });
  } else if (values && values.includes(ME_OWNER)) {
    const replaceMePlaceholder = update(values.indexOf(ME_OWNER), () => ownerIdOverride);
    return Object.assign({}, ownerFilter, {
      // TODO Fix type. Type is not entirely accurate since ME_OWNER can be an array value
      values: replaceMePlaceholder(values)
    });
  } else {
    return ownerFilter;
  }
};
export const salesTaskOnlyFilter = {
  property: TASK_FAMILY,
  operator: 'EQ',
  value: 'SALES'
};

/**
 * Replace date filter placeholders with values that we want to hold off on calculating until we make the actual query
 * @param {Object}
 *  - filters: a list of filters to go through and process placeholders for
 *  - excludePlaceholders: a list of placeholders (from ALL_DATE_PLACEHOLDERS) to not process
 * @returns input list of filters with all non-excluded placeholders resolved
 */
export function processFiltersForSearchRequest({
  filters,
  isJitaUser,
  ownerIdOverride,
  excludePlaceholders = [],
  addTaskFamily = true
}) {
  // Filtering out of non-sales tasks

  const taskFamilyFilter = [...filters] || [];
  if (addTaskFamily && !filters.includes(salesTaskOnlyFilter)) {
    taskFamilyFilter.push(salesTaskOnlyFilter);
  }
  const placeholdersToResolve = ALL_DATE_PLACEHOLDERS.filter(placeholder => !excludePlaceholders.includes(placeholder));
  return taskFamilyFilter.map(filter => {
    let transformedFilter = filter;
    if (isJitaUser && ownerIdOverride && filter.property === OWNER_ID) {
      transformedFilter = transformOwnerFilterForJita(transformedFilter, ownerIdOverride);
    }
    const {
      value
    } = filter;
    if (value && typeof value === 'string' && placeholdersToResolve.includes(value)) {
      transformedFilter = Object.assign({}, transformedFilter, {
        value: resolvePlaceholder(value)
      });
    }
    const {
      highValue
    } = filter;
    if (highValue && typeof highValue === 'string' && placeholdersToResolve.includes(highValue)) {
      transformedFilter = Object.assign({}, transformedFilter, {
        highValue: resolvePlaceholder(highValue)
      });
    }
    return transformedFilter;
  });
}