import getIn from 'transmute/getIn';
import memoize from 'transmute/memoize';
import { createSelector } from 'reselect';
import { temporarilyExcludedTaskIdsSelector } from './temporarilyExcludeIds';
const tasksSearchResultsSelector = state => state.tasksSearchResults;
function filterTasks(taskIds, excludeTaskIds) {
  return taskIds.filter(taskId => !excludeTaskIds.includes(taskId));
}
const getRawSearchResultsSelector = createSelector(tasksSearchResultsSelector, tasksSearchResults => memoize(searchKeyArray => getIn(searchKeyArray)(tasksSearchResults)));
export const getSearchResultsSelector = createSelector(getRawSearchResultsSelector, temporarilyExcludedTaskIdsSelector, (getSearchResults, temporarilyExcludedTaskIds) => memoize(searchKey => {
  if (!searchKey) {
    return {
      loading: true,
      uninitialized: true,
      taskIds: []
    };
  }
  const searchResult = getSearchResults(searchKey);
  const isUninitialized = !searchResult;
  const isLoading = !searchResult || searchResult.loading;
  if (isUninitialized || isLoading) {
    return {
      loading: isLoading,
      uninitialized: isUninitialized,
      taskIds: []
    };
  }
  if (searchResult.error) {
    return {
      loading: isLoading,
      uninitialized: isUninitialized,
      error: searchResult.error,
      taskIds: []
    };
  }
  const taskIds = filterTasks(searchResult.taskIds, temporarilyExcludedTaskIds);
  return Object.assign({}, searchResult, {
    uninitialized: false,
    taskIds
  });
}));