import { BOLD, GLOBAL_VARS, ITALIC } from '../editorCapabilities/constants';
const getPlaintextOptions = editorConfig => {
  const hasBasicFormatting = editorConfig[BOLD] || editorConfig[ITALIC];
  if (editorConfig[GLOBAL_VARS] && editorConfig[GLOBAL_VARS].usePlaintextMode && hasBasicFormatting) {
    return {
      allowBasicFormatting: true
    };
  }
  return {};
};
export const getGlobalVars = editorConfig => {
  return Object.assign({}, editorConfig[GLOBAL_VARS], {
    plaintextOptions: getPlaintextOptions(editorConfig)
  });
};