export const EMAIL = 'EMAIL';
export const EMAIL_ACTIVITY = 'EMAIL_ACTIVITY';
export const TASK = 'TASK';
export const MEETING = 'MEETING';
export const CALL = 'CALL';
export const NOTE = 'NOTE';
export const INCOMING_EMAIL = 'INCOMING_EMAIL';
export const FORWARDED_EMAIL = 'FORWARDED_EMAIL';
export const FEEDBACK_SUBMISSION = 'FEEDBACK_SUBMISSION';
export const CONVERSATION_SESSION = 'CONVERSATION_SESSION';
export const LINKEDIN_MESSAGE = 'LINKEDIN_MESSAGE';
export const POSTAL_MAIL = 'POSTAL_MAIL';
export const SMS = 'SMS';
export const WHATS_APP = 'WHATS_APP';
export const CUSTOM_CHANNEL_CONVERSATION = 'CUSTOM_CHANNEL_CONVERSATION';
export const ENGAGEMENTS = [EMAIL, EMAIL_ACTIVITY, TASK, MEETING, CALL, NOTE, INCOMING_EMAIL, FORWARDED_EMAIL, FEEDBACK_SUBMISSION, CONVERSATION_SESSION, LINKEDIN_MESSAGE, POSTAL_MAIL, SMS, WHATS_APP, CUSTOM_CHANNEL_CONVERSATION];
/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
// @ts-expect-error module/module.exports aren't currently supported
if (!!module && !!module.exports) {
  // @ts-expect-error module/module.exports aren't currently supported
  module.exports.default = Object.assign({}, module.exports);
}