import { useEffect } from 'react';
import { SYNC_WARMUP_CACHE_FOR } from '../constants/actionTypes';
import { useBulkActionsDispatch } from '../hooks/useBulkActionsDispatch';
export const useBulkActionsSyncWarmupCacheFor = warmupCacheFor => {
  const dispatch = useBulkActionsDispatch();
  const stringified = JSON.stringify(warmupCacheFor);
  useEffect(() => {
    dispatch({
      type: SYNC_WARMUP_CACHE_FOR,
      payload: warmupCacheFor
    });

    /**
     * Why is this eslint-disable-next-line here?
     *
     * Often times consumers will call useBulkActionsSyncWarmupCacheFor many times with data that does not change,
     * but the reference to the data will.
     *
     * Every time `dispatch` is called, the reference to BulkActionsContextProvider.state changes,
     * and all children are re-rendered - which we want to minimize as much as possible
     *
     * If we add JSON.stringify(warmupCacheFor) to the dep array, the useEffect is essentially
     * deep equaling warmupCacheFor instead of reference checking.
     *
     * Meaning if we use JSON.stringify(warmupCacheFor) in the dep array,
     * the reference to warmupCacheFor can change and not trigger a rerender as long as the values within warmupCacheFor stay the same
     */
    //eslint-disable-next-line
  }, [stringified, dispatch]); //WARNING - make sure to keep this dep array accurate now that eslint is ignored
};