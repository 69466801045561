'use es6';

import { fetchPageStateForTask } from './actions/fetchPageStateForTask';
import { getPageStateForTask } from './actions/getPageStateForTask';
import { setStateToNextTask } from './actions/setStateToNextTask';
import { setCurrentTaskCompleted } from './actions/setCurrentTaskCompleted';
import { setCurrentTaskCompletedWithoutProgressing } from './actions/setCurrentTaskCompletedWithoutProgressing';
import { setCurrentTaskSkipped } from './actions/setCurrentTaskSkipped';
import { startTaskQueue } from './actions/startTaskQueue';
import { validateApiClientShape } from './api/validateApiClientShape';
import { getState } from './state/getState';
import { clearTaskQueueConfig } from './storage/clearTaskQueueConfig';
import { validateStorageShape } from './storage/validateStorageShape';

/**
 * A simple facade for all the task queue functionality.
 * Not required to use task queues but will probably make your life easier.
 */
export class TaskQueueManager {
  constructor(apiClient, storage) {
    validateApiClientShape(apiClient);
    validateStorageShape(storage, true);
    this.apiClient = apiClient;
    this.storage = storage;

    // setup internal functions with apiClient/storage
    this.startTaskQueue = startTaskQueue(this.apiClient, this.storage);
    this.getState = getState(this.storage);
    this.getPageStateForTask = getPageStateForTask;
    this.setStateToNextTask = setStateToNextTask(this.apiClient, this.storage);
    this.exit = () => clearTaskQueueConfig(this.storage);
    this.setCurrentTaskSkipped = setCurrentTaskSkipped(this.storage);
    this.setCurrentTaskCompleted = setCurrentTaskCompleted(this.storage);
    this.setCurrentTaskCompletedWithoutProgressing = setCurrentTaskCompletedWithoutProgressing(this.storage);
    this.fetchPageStateForTask = fetchPageStateForTask(this.apiClient);
  }
}