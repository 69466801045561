import { canEditTask } from '../utils/permissions';
import { useOwnerId, useUserScopes, useUserTeamIds } from './auth';

/**
 * returns true if the current user can edit the task; false otherwise
 * @param {TaskRecord} taskRecord
 *
 * @example sample usage;
 *
 * const canEditTask = useTaskEditPermissions({ taskRecord })
 */

export function useTaskEditPermissions({
  taskRecord
}) {
  const currentOwnerId = useOwnerId();
  const currentUserScopes = useUserScopes();
  const currentOwnerTeamIds = useUserTeamIds();
  return canEditTask({
    taskRecord,
    currentOwnerId,
    currentOwnerTeamIds,
    currentUserScopes
  });
}