function filter(predicates, cmd) {
  return function (state, dispatch, view) {
    if (predicates.some(pred => {
      return !pred(state, view);
    })) {
      return false;
    }
    return cmd(state, dispatch, view) || false;
  };
}
export default filter;