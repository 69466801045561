export const OPEN_IMAGE_PICKER = 'open-image-picker';
export const INSERT_IMAGE = 'insert-image';
export const REPLACE_IMAGE = 'replace-image';
export const EDIT_IMAGE = 'edit-image';
export const AUTOMATIC_IMAGE_UPLOAD = 'automatic-image-upload';
export const CHANGE_INLINE_STYLE = 'change-inline-style';
export const CHANGE_FONT_SIZE = 'change-font-size';
export const CHANGE_FONT = 'change-font';
export const CHANGE_CUSTOM_FONT = 'change-custom-font';
export const CHANGE_LINE_HEIGHT = 'change-line-height';
export const CHANGE_BLOCK_FORMAT = 'change-block-format';
export const ESCAPE_BLOCK_FORMAT = 'escape-block-format';
export const MAKE_UNORDERED_LIST = 'make-unordered-list';
export const MAKE_ORDERED_LIST = 'make-ordered-list';
export const INSERT_LINK = 'insert-link';
export const UPDATE_LINK = 'update-link';
export const OPEN_FILE_PICKER = 'open-file-picker';
export const INSERT_LINK_TO_FILE = 'insert-link-to-file';
export const OPEN_PROPERTY_BROWSER = 'open-contact-propery-browser';
export const INSERT_PERSONALIZATION_TOKEN = 'insert-personalization-token';
export const EDIT_PERSONALIZATION_TOKEN = 'edit-personalization-token';
export const OPEN_CTA_PICKER = 'open-cta-picker';
export const INSERT_CTA = 'insert-cta';
export const UPDATE_CTA = 'update-cta';
export const EDIT_CTA = 'edit-cta';
export const OPEN_EMBED_MODAL = 'open-embed-modal';
export const INSERT_MEDIA_EMBED = 'insert-embed-code';
export const EDIT_MEDIA_EMBED = 'edit-embed-code';
export const OPEN_VIDEO_PICKER = 'open-video-picker';
export const INSERT_HUBSPOT_VIDEO = 'insert-hubspot-video';
export const EDIT_HUBSPOT_VIDEO = 'edit-hubspot-video';
export const INSERT_RSS_TOKEN = 'insert-rss-token';
export const OPEN_RSS_TOKEN = 'open-rss-token-popover';
export const EDIT_SOURCE_CODE = 'edit-source-code';
export const TOGGLE_VISUAL_BLOCKS = 'toggle-visual-blocks';
export const OPEN_COLOR_POPOVER = 'open-colorpicker-popover';
export const RESET_COLOR_TO_DEFAULT = 'reset-color-to-default';
export const CHANGE_COLOR_POPOVER_MODE = 'change-colorpicker-mode';
export const APPLY_COLOR_POPOVER = 'change-color';
export const OPEN_EMOJI_POPOVER = 'open-emoji-picker-popover';
export const INSERT_EMOJI = 'insert-emoji';
export const OPEN_SNIPPET_POPOVER = 'open-snippet-popover';
export const INSERT_SNIPPET = 'insert-snippet';
export const OPEN_TABLE_POPOVER = 'open-table-popover';
export const INSERT_TABLE = 'insert-table';
export const EDIT_TABLE = 'edit-table';
export const PASTE_CONTENT = 'paste';
export const INSERT_TEMP_ANCHOR = 'insert-temp-anchor';
export const INSERT_ANCHOR = 'insert-anchor';
export const EDIT_ANCHOR = 'edit-anchor';
export const DRAG_DROP_ANCHOR = 'drag-drop-anchor';
export const INSERT_CALLOUT = 'insert-callout';
export const EDIT_CALLOUT = 'edit-callout';
export const OPEN_FIND_REPLACE_POPOVER = 'open-find-replace';
export const SHOW_REPLACE = 'show-replace';
export const FIND_TEXT = 'find-text';
export const REPLACE_TEXT = 'replace-text';
export const REPLACE_TEXT_ALL = 'replace-text-all';
export const INSERT_SPECIAL_CHARACTER = 'insert-special-character';
export const OPEN_SPECIAL_CHARACTER = 'open-special-character-popover';
export const INSERT_GOOGLE_DOC = 'insert-google-doc';
export const ADD_NEW_GOOGLE_ACCOUNT = 'add-new-google-account';
export const CANCEL_GOOGLE_DOC = 'cancel-google-doc';
export const EDIT_ICON = 'edit-icon';
export const INSERT_ICON = 'insert-icon';
export const OPEN_PAYMENT_POPOVER = 'open-payment-popover';
export const INSERT_PAYMENT = 'insert-payment';
export const CLICK_MANAGE_PAYMENTS = 'click-manage-payments';
export const EDIT_PAYMENT = 'edit-payment';
export const INSERT_INVOICE = 'insert-invoice';
export const CLICK_MANAGE_INVOICES = 'click-manage-invoices';