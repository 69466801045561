import { Record } from 'immutable';
export default class QuoteFeeRecord extends Record({
  name: '',
  amount: 0,
  category: 'FEE',
  isPercentage: false
}, 'QuoteFeeRecord') {
  getSign() {
    // this doesn't belong here
    return this.category && this.category === 'DISCOUNT' ? -1 : 1;
  }
}