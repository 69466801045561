import PortalIdParser from 'PortalIdParser';
import getIn from 'transmute/getIn';
import get from 'transmute/get';
import keySeq from 'transmute/keySeq';
import { CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID, LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
const SEQUENCE_STEP_ENROLLMENT_TYPE_ID = '0-79';
const TASK_TYPES = {
  EMAIL: 'EMAIL',
  CALL: 'CALL',
  LINKED_IN: 'LINKED_IN',
  LINKED_IN_MESSAGE: 'LINKED_IN_MESSAGE',
  LINKED_IN_CONNECT: 'LINKED_IN_CONNECT'
};
const TASK_TYPE_TO_INTERACTION = {
  [TASK_TYPES.EMAIL]: 'email',
  [TASK_TYPES.CALL]: 'call',
  [TASK_TYPES.LINKED_IN]: 'linked-in-message',
  [TASK_TYPES.LINKED_IN_MESSAGE]: 'linked-in-message',
  [TASK_TYPES.LINKED_IN_CONNECT]: 'linked-in-connect'
};
const PRIORITIZED_TYPE_IDS = [CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID];
export function _getRecordRoute(objectTypeId, objectId) {
  const baseRoute = `/contacts/${PortalIdParser.get()}`;
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
      return `${baseRoute}/contact/${encodeURIComponent(objectId)}`;
    case COMPANY_TYPE_ID:
      return `${baseRoute}/company/${encodeURIComponent(objectId)}`;
    case DEAL_TYPE_ID:
      return `${baseRoute}/deal/${encodeURIComponent(objectId)}`;
    case TICKET_TYPE_ID:
      return `${baseRoute}/ticket/${encodeURIComponent(objectId)}`;
    // for custom objects
    default:
      {
        return `${baseRoute}/record/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(objectId)}`;
      }
  }
}
export function _getTaskInteractionType(task) {
  if (!task) {
    return null;
  }
  const taskType = getIn(['properties', 'hs_task_type', 'value'])(task);
  return TASK_TYPE_TO_INTERACTION[taskType] || null;
}
export function _getTaskQueueParams(task) {
  const taskId = get('objectId', task);
  const interaction = _getTaskInteractionType(task);
  let params = `?taskId=${encodeURIComponent(taskId)}`;
  if (interaction) {
    params += `&interaction=${encodeURIComponent(interaction)}`;
  }
  return params;
}
export function _getSortedAssociationIds(taskAssociations) {
  return keySeq(taskAssociations).sort((a, b) => {
    const indexOfA = PRIORITIZED_TYPE_IDS.indexOf(a);
    const indexOfB = PRIORITIZED_TYPE_IDS.indexOf(b);
    if (indexOfA === -1 && indexOfB !== -1) {
      return 1;
    }
    if (indexOfB === -1 && indexOfA !== -1) {
      return -1;
    }
    // this should sort all other cases appropriately
    return indexOfA - indexOfB;
  });
}
export function _getPrimaryAssociation(taskAssociations) {
  const sortedAssociatedObjectTypeIds = _getSortedAssociationIds(taskAssociations);
  const firstAssociatedObjectTypeId = sortedAssociatedObjectTypeIds.find(objectTypeId => Boolean(getIn([objectTypeId, 'objectIds', 0])(taskAssociations)));
  // no valid association objects
  if (!firstAssociatedObjectTypeId) {
    return {
      objectTypeId: null,
      objectId: null
    };
  }
  const objectId = getIn([firstAssociatedObjectTypeId, 'objectIds', 0])(taskAssociations);
  const primaryDisplayLabelPropertyName = getIn([firstAssociatedObjectTypeId, 'associationSpec', 'primaryDisplayLabelPropertyName'], taskAssociations);
  return {
    objectTypeId: firstAssociatedObjectTypeId,
    objectId,
    primaryDisplayLabelPropertyName
  };
}
export function _getTaskQueueRecordRoute(task, primaryAssociation) {
  const taskQueueParams = _getTaskQueueParams(task);
  const {
    objectTypeId,
    objectId
  } = primaryAssociation;
  if (!objectTypeId || !objectId || objectTypeId === SEQUENCE_STEP_ENROLLMENT_TYPE_ID || objectTypeId === LEAD_TYPE_ID) {
    return undefined;
  }
  return `${_getRecordRoute(objectTypeId, objectId)}${taskQueueParams}`;
}
export function getPageStateForTask(task) {
  if (!task) {
    return {
      primaryAssociation: {
        objectTypeId: null,
        objectId: null
      },
      shouldNavigateToUrl: false,
      shouldShowUnassociatedTaskModal: false,
      url: undefined,
      task: undefined
    };
  }
  const primaryAssociation = _getPrimaryAssociation(get('associations', task));
  const taskQueueUrl = _getTaskQueueRecordRoute(task, primaryAssociation);
  return {
    primaryAssociation,
    shouldNavigateToUrl: Boolean(taskQueueUrl),
    shouldShowUnassociatedTaskModal: !taskQueueUrl,
    url: taskQueueUrl,
    task
  };
}