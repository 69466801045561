'use es6';

import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import memoize from 'transmute/memoize';
import { isOnlyActiveSalesforceOwner } from 'customer-data-objects/owners/isActiveOwner';
const getName = owner => {
  const {
    firstName,
    lastName,
    email
  } = owner;
  let label = '';
  if (!firstName && !lastName && !email) {
    label = '';
  } else if (!firstName && !lastName) {
    label = email;
  } else {
    label = formatName({
      firstName,
      lastName
    });
  }
  return label;
};
const formatDeactivated = owner => {
  const {
    firstName,
    lastName,
    email
  } = owner;
  const name = email || formatName({
    firstName,
    lastName
  });
  return I18n.text('customerDataObjects.OwnerName.deactivated', {
    name
  });
};
export const format = memoize(owner => {
  if (!owner) {
    return undefined;
  }
  if (!owner.active) {
    return formatDeactivated(owner);
  }
  if (owner.ownerId === '__hs__ME') {
    return I18n.text('customerDataObjects.OwnerIdSelect.me');
  }
  let label = getName(owner);
  if (label && isOnlyActiveSalesforceOwner(owner)) {
    label = `${label} (Salesforce)`;
  }
  return label;
});
export const formatWithEmail = memoize(owner => {
  if (!owner) {
    return undefined;
  }
  if (owner.ownerId === '__hs__ME') {
    return I18n.text('customerDataObjects.OwnerIdSelect.me');
  }
  const {
    email,
    active
  } = owner;
  let label = format(owner);
  if (!owner || !active || isOnlyActiveSalesforceOwner(owner)) {
    return label;
  } else if (email && label && label !== email) {
    label += ` (${email})`;
  }
  return label;
});