export const EMAIL_OPEN = 'EMAIL_OPEN';
export const EMAIL_CLICK = 'EMAIL_CLICK';
export const EMAIL_REPLY = 'EMAIL_REPLY';
export const EMAIL_TRACKER_CREATE = 'EMAIL_TRACKER_CREATE';
export const HUBSPOT_REVISIT = 'HUBSPOT_REVISIT';
export const COMPANYPROSPECTS_REVISIT = 'COMPANYPROSPECTS_REVISIT';
export const PRESENTATION_REVISIT = 'PRESENTATION_REVISIT';
export const MEETING_BOOKED = 'MEETING_BOOKED';
export const FORM_SUBMITTED = 'FORM_SUBMITTED';
export const LEAD_ASSIGNED = 'LEAD_ASSIGNED';
export const ALL_ACTIVITY_TYPES = [EMAIL_OPEN, EMAIL_CLICK, EMAIL_REPLY, EMAIL_TRACKER_CREATE, HUBSPOT_REVISIT, COMPANYPROSPECTS_REVISIT, PRESENTATION_REVISIT, MEETING_BOOKED, FORM_SUBMITTED, LEAD_ASSIGNED];