'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["fetchOptions", "httpClient"];
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { createGetAssociationsByTaskIds } from '../api/getAssociationsByTaskIds';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
export const TASK_ASSOCIATION_CACHE_KEY = 'TASK_ASSOCIATIONS';
const createGetAssociationsByTaskIdsAndFormatAsReferences = (...createOptions) => {
  const getAssociationsByTaskIds = createGetAssociationsByTaskIds(...createOptions);
  return (...fetchArgs) => getAssociationsByTaskIds(...fetchArgs).then(formatToReferencesList({
    getId: object => String(get('taskId', object)),
    getLabel: object => String(get('taskId', object))
  }));
};
export const createTaskAssociatedObjectsReferenceResolver = options => {
  const {
      fetchOptions,
      httpClient
    } = options,
    otherOptions = _objectWithoutPropertiesLoose(options, _excluded);
  return createBatchedReferenceResolver(Object.assign({
    // you have to pass in cacheKey via options
    cacheKey: TASK_ASSOCIATION_CACHE_KEY,
    createFetchByIds: args => createGetAssociationsByTaskIdsAndFormatAsReferences(Object.assign({
      fetchOptions
    }, args)),
    fetchByIds: createGetAssociationsByTaskIdsAndFormatAsReferences({
      httpClient,
      fetchOptions
    }),
    httpClient
  }, otherOptions));
};