import { registerQuery } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
export const taskQueues = registerQuery({
  fieldName: 'taskQueues',
  args: ['ids'],
  fetcher: function taskQueuesFetcher({
    ids
  }) {
    // Hooks may be called with undefined IDs to avoid being called
    // conditionally. Filter those out here as well as "new" that may be passed
    // in becuase of the `/queues/new` URL for the creation modal
    const filteredIds = ids.filter(id => id && id !== 'new');
    if (filteredIds.length === 0) {
      return Promise.resolve([]);
    }
    return apiClient.get(`engagements/v1/queues/batch?queueId=${filteredIds.join('&queueId=')}`);
  }
});