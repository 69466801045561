'use es6';

const SELECTION_ADD = 'SELECTION_ADD';
const SELECTION_CLEAR = 'SELECTION_CLEAR';
const SELECTION_REMOVE = 'SELECTION_REMOVE';
export const selectionActions = {
  SELECTION_ADD,
  SELECTION_CLEAR,
  SELECTION_REMOVE
};
export const addSelection = ids => ({
  ids,
  type: selectionActions.SELECTION_ADD
});
export const clearSelection = () => ({
  type: selectionActions.SELECTION_CLEAR
});
export const removeSelection = ids => ({
  ids,
  type: selectionActions.SELECTION_REMOVE
});