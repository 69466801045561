import { registerQuery } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
export function taskQueuesSearchFetcher({
  canChangeUser,
  createdBy,
  limit,
  offset,
  searchQuery,
  userId
}) {
  let requestUserId;
  let requestMode;
  if (canChangeUser) {
    // User is able to view all queues in the portal and filter by user
    if (createdBy) {
      // Created by filter is specified, so we need to change the mode
      requestUserId = createdBy;
      requestMode = 'OWNED_ONLY';
    } else {
      // No filter applied, this combination returns all queues in the portal,
      // including private queues for other users.
      requestUserId = undefined;
      requestMode = 'ALL_VISIBLE';
    }
  } else {
    // Non-admin user should just see what's visible to them, but we need to
    // pass the user ID
    requestUserId = userId;
    requestMode = 'ALL_VISIBLE';
  }
  return apiClient.post(`engagements/v1/queues/search-definitions`, {
    data: {
      limit,
      offset,
      mode: requestMode,
      query: searchQuery,
      userId: requestUserId
    }
  });
}
export const searchTaskQueues = registerQuery({
  fieldName: 'taskQueueSearchResults',
  args: ['canChangeUser', 'createdBy', 'limit', 'offset', 'searchQuery', 'userId'],
  fetcher: taskQueuesSearchFetcher
});