import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { useQueryParamString } from './useQueryParams';
export function useCloseTaskForm() {
  const history = useHistory();
  const location = useLocation();
  const queryParamsString = useQueryParamString();
  return useCallback(() => {
    const currentPathWithoutTask = location.pathname.replace(/\/task\/(\d+|new)/, '');
    history.replace(`${currentPathWithoutTask}${queryParamsString}`);
  }, [history, location.pathname, queryParamsString]);
}