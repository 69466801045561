import { getCurrentTaskIdQueryParam } from '../TaskQueueBar/utils/navigation';
import { getTaskQueueConfig } from '../storage/getTaskQueueConfig';
export function getRemainingTaskIds(storage) {
  const config = getTaskQueueConfig(storage)(undefined);
  const {
    currentTaskId: currentTaskIdFromState,
    taskIds
  } = config;
  const currentTaskIdQueryParam = getCurrentTaskIdQueryParam();
  const parsedCurrentTaskIdQueryParam = currentTaskIdQueryParam ? parseInt(String(currentTaskIdQueryParam), 10) : null;
  const currentTaskId = parsedCurrentTaskIdQueryParam || currentTaskIdFromState;
  const indexOfCurrentTask = taskIds.indexOf(currentTaskId);
  return taskIds.slice(indexOfCurrentTask + 1);
}