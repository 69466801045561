// Antipattern - maitaining a hand-coded type when the back end should
// provide the official API client w/type definitions
export const unsubscribeLinkTypes = {
  RELEVANT: 'RELEVANT',
  STOP_GETTING_MESSAGES: 'STOP_GETTING_MESSAGES',
  PREFER_LESS: 'PREFER_LESS'
};
// when the user has disabled the unsubscribe link in their preferences
export const nullUnsubscribeLinkType = {
  NO_UNSUBSCRIBE_LINK: 'NO_UNSUBSCRIBE_LINK'
};
export const I18N_LINK_TEXT_KEY_BASE = 'hubspot-prosemirror.unsubscribeLink.';
export const DATA_ATTRIBUTE = 'data-hs-unsubscribe';
export const LOCALE_DATA_ATTRIBUTE = 'data-hs-unsubscribe-locale';
export const LINK_TYPE_ATTRIBUTE = 'data-hs-linktype';
export const LOCALE_LANGUAGE_ATTRIBUTE = 'data-hs-unsubscribe-language';
export const LINK_TOKEN = '{{ unsubscribe_link }}';
export const LINK_TEXT_ATTRIBUTE = 'data-hs-link-text';
export const LINK_URL_TEXT_ATTRIBUTE = 'data-hs-link-url-text';