import { TASK_PROPERTIES_TO_FETCH } from '../constants/taskProperties';
const TASK_TEMPLATE_TYPE_ID = '0-43';
const SALES_TASK_TYPE_ID = '0-113';
export function _throwGraphQLErrors(operationName, query, errors) {
  if (errors && errors.length > 0) {
    const errorMessages = errors.map(error => `"${error.message}" occurred when running query ${operationName}.
      query ${operationName} {${query}}`);
    if (errors.length === 1) {
      throw new Error(errorMessages[0]);
    }
    throw new Error(`Multiple errors occurred. ${errorMessages.join(', ')}`);
  }
}
export function _buildQuery(taskId, propertyNames) {
  return `crmObject(id: ${taskId}, type: "TASK") {
    id,
    allAssociations {
      associatedObjects(first: 10) {
        edges {
          node {
            id,
            primaryDisplayLabel
          }
        },
        pageInfo {
          endCursor
        }
      },
      associationDefinition {
        associationCategory,
        associationTypeId,
        toObjectTypeId,
        toObjectTypeDefinition {
          primaryDisplayLabelPropertyName
        }
      }
    },
    properties(names: [${propertyNames.map(name => `"${name}"`).join(', ')}]) {
      name,
      value
    }
  }`;
}
export function _formatProperties(propertiesArray) {
  return propertiesArray.reduce((propertiesMap, property) => {
    const {
      name
    } = property;
    return Object.assign({}, propertiesMap, {
      [name]: property
    });
  }, {});
}
export function _formatAssociations(associationsArray) {
  return associationsArray.reduce((associationsMap, associationsForType) => {
    const {
      associatedObjects,
      associationDefinition: {
        associationCategory,
        associationTypeId,
        toObjectTypeId,
        toObjectTypeDefinition: {
          primaryDisplayLabelPropertyName
        }
      }
    } = associationsForType;
    let objectIds = [];

    // TASK_TEMPLATE_TYPE_ID is an association created for repeating tasks
    // because we are using the crm endpoint, these associations aren't hidden
    // from us and cause issues when starting a queue, so we filter them out for now
    // https://git.hubteam.com/HubSpot/sales-efficiency/issues/3292
    // similarly SALES_TASK_TO_TASK is another association we want hidden
    if ([TASK_TEMPLATE_TYPE_ID, SALES_TASK_TYPE_ID].includes(toObjectTypeId)) {
      return associationsMap;
    }
    if (associatedObjects && associatedObjects.edges) {
      objectIds = associatedObjects.edges.map(edge => edge.node.id);
    }
    const associationSpec = {
      associationTypeId,
      associationCategory,
      primaryDisplayLabelPropertyName
    };
    return Object.assign({}, associationsMap, {
      [toObjectTypeId]: {
        associationSpec,
        objectIds
      }
    });
  }, {});
}
export function _formatTask(response) {
  const {
    data
  } = response;
  if (data) {
    const {
      crmObject: {
        id,
        allAssociations,
        properties
      }
    } = data;
    return {
      objectId: id,
      associations: _formatAssociations(allAssociations),
      properties: _formatProperties(properties)
    };
  } else {
    return {
      objectId: null,
      associations: {},
      properties: {}
    };
  }
}
export const fetchTask = apiClient => taskId => {
  const operationName = `task_queues_associations_${taskId}`;
  const query = _buildQuery(taskId, TASK_PROPERTIES_TO_FETCH);
  return apiClient.post('graphql/crm', {
    data: {
      operationName,
      query: `query ${operationName} {${query}}`
    }
  }).then(response => {
    const {
      errors
    } = response;
    _throwGraphQLErrors(operationName, query, errors);
    return _formatTask(response);
  }); // Errors should be handled outside this function
};