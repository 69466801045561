import { getProperty } from 'customer-data-objects/model/ImmutableModel';
import { OWNER_ID, ALL_ACCESSIBLE_TEAM_IDS } from 'customer-data-objects/task/TaskPropertyNames';
import { TASKS_EDIT_OWNED_ONLY, TASKS_EDIT_ALL, TASKS_EDIT_TEAM_ONLY, TASKS_EDIT_UNASSIGNED } from '../constants/scopes';
export function canUserEditQueueDefinition(queueDefinition, currentUserOwnerId, canOverridePermissions) {
  if (!queueDefinition) return false;
  const {
    ownerId: queueOwnerId
  } = queueDefinition;
  return canOverridePermissions || queueOwnerId === currentUserOwnerId;
}
export function canUserEditQueue(queue, currentUserOwnerId, canOverridePermissions) {
  if (!queue) return false;
  return canUserEditQueueDefinition(queue.definition, currentUserOwnerId, canOverridePermissions);
}
export function canEditTask({
  taskRecord,
  currentOwnerId,
  currentOwnerTeamIds,
  currentUserScopes
}) {
  if (!taskRecord) {
    return false;
  }
  const taskTeamIds = (getProperty(taskRecord, ALL_ACCESSIBLE_TEAM_IDS) || []).map(String);
  const taskOwnerId = getProperty(taskRecord, OWNER_ID);
  const isTaskOwner = String(currentOwnerId) === String(taskOwnerId);
  const canEditOwned = currentUserScopes.includes(TASKS_EDIT_OWNED_ONLY);
  if (isTaskOwner && canEditOwned) {
    return true;
  }
  const canEditAll = currentUserScopes.includes(TASKS_EDIT_ALL);
  if (canEditAll) {
    return true;
  }
  const taskAssignedToTeamMember = currentOwnerTeamIds.some(teamId => taskTeamIds.includes(String(teamId)));
  const canEditTeamTask = currentUserScopes.includes(TASKS_EDIT_TEAM_ONLY);
  if (taskAssignedToTeamMember && canEditTeamTask) {
    return true;
  }
  const isTaskUnassigned = !taskOwnerId;
  const canEditUnassigned = currentUserScopes.includes(TASKS_EDIT_UNASSIGNED);
  return isTaskUnassigned && canEditUnassigned;
}
export function canBulkEditTasks({
  tasks,
  currentOwnerId,
  currentOwnerTeamIds,
  currentUserScopes
}) {
  return tasks.every(task => canEditTask({
    taskRecord: task,
    currentOwnerId,
    currentOwnerTeamIds,
    currentUserScopes
  }));
}