export const START_TASK_QUEUES = 'start-task-queues';
export const LEGACY_START_TASK_QUEUES = 'legacy-start-task-queues';
export const START_TASK_QUEUE_SCOPES = [START_TASK_QUEUES, LEGACY_START_TASK_QUEUES];
export const TASKS_EDIT_ALL = 'tasks-edit-all';
export const TASKS_EDIT_TEAM_ONLY = 'tasks-edit-team-only';
export const TASKS_EDIT_OWNED_ONLY = 'tasks-edit-owned-only';
export const TASKS_EDIT_UNASSIGNED = 'tasks-edit-unassigned';
export const TASK_WRITE_SCOPES = [TASKS_EDIT_ALL, TASKS_EDIT_TEAM_ONLY, TASKS_EDIT_OWNED_ONLY, TASKS_EDIT_UNASSIGNED];
export const RECURRING_TASKS = 'recurring-tasks';
export const TASKS_VIEW_ALL = 'tasks-view-all';
export const TASKS_VIEW_TEAM_ONLY = 'tasks-view-team-only';
export const TASKS_VIEW_UNASSIGNED = 'tasks-view-unassigned';
export const VIEWS_PUBLIC_SHARE_RESTRICTED = 'bet-views-public-share-restricted';
export const VIEWS_PUBLIC_SHARE = 'bet-views-public-share';