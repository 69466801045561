import TaskRecord from 'customer-data-objects/task/TaskRecord';
import { QUEUE_BAR_BASE_CONFIG } from '../TaskQueueBar/reducers/taskQueueBarReducer';
import { getSessionStorageKey } from '../TaskQueueBar/utils/getSessionStorageKey';
import { validateStorageShape } from './validateStorageShape';
export const getTaskQueueConfig = storage => storageOptions => {
  validateStorageShape(storage);
  let config = null;
  const storageKey = getSessionStorageKey();
  try {
    const configFromStorage = storage.getItem(storageKey, storageOptions);
    if (!configFromStorage) {
      return config;
    }
    const parsedConfig = JSON.parse(configFromStorage);
    if (parsedConfig) {
      // merge the config with the base config just incase its malformatted
      config = Object.assign({}, QUEUE_BAR_BASE_CONFIG, parsedConfig);
    }
  } catch (e) {
    // TODO: How should we handle surfacing local storage errors up to the consuming app?
    // I think ideally apps should pass in a storage interface that handles errors
    console.error(e);
  }
  return config;
};
export const getTaskQueueConfigByKey = (storage, storageKey) => {
  validateStorageShape(storage);
  let config = null;
  try {
    const configFromStorage = storage.getItem(storageKey);
    if (!configFromStorage) {
      return config;
    }
    const parsedConfig = JSON.parse(configFromStorage);
    if (parsedConfig) {
      // merge the config with the base config just incase its malformatted
      config = Object.assign({}, QUEUE_BAR_BASE_CONFIG, parsedConfig);
      if (config.currentTask) {
        config = Object.assign({}, config, {
          currentTask: config.currentTask instanceof TaskRecord ? config.currentTask : TaskRecord.fromJS(config.currentTask)
        });
      }
    }
  } catch (e) {
    // TODO: How should we handle surfacing local storage errors up to the consuming app?
    // I think ideally apps should pass in a storage interface that handles errors
    console.error(e);
  }
  return config;
};