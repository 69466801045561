import { Fragment, Slice } from 'prosemirror-model';
import { getImageDimensions } from '../../draft-transplant/utils/libUtils';
export function isImageFile(file) {
  return /image\//.test(file.type);
}
export function scaleImageSize(rawImageData) {
  if (rawImageData.width) {
    const scale = rawImageData.width < 400 ? 1 : 400 / rawImageData.width;
    return Object.assign({}, rawImageData, {
      width: rawImageData.width * scale,
      height: rawImageData.height ? rawImageData.height * scale : undefined
    });
  } else {
    return Object.assign({}, rawImageData, {
      width: 400,
      // height should default to auto, if an image doesn't have width it shouldnt have height
      height: undefined
    });
  }
}

// Pre-condition: files contains valid image files
export function addTemporaryImageNodes(editorState, dispatcher, files, fileOptions = {}) {
  const fragmentNodes = [];
  const promises = files.map(file => {
    const tempFileUrl = URL.createObjectURL(file);
    return getImageDimensions(tempFileUrl).then(({
      height,
      width
    }) => {
      const rawData = {
        height,
        isTemporary: true,
        src: tempFileUrl,
        uploadPercent: 10,
        width,
        originalDimensions: {
          width,
          height
        }
      };
      const scaledRawData = scaleImageSize(rawData);
      const data = Object.assign({}, scaledRawData, {
        originalDimensions: {
          width,
          height
        }
      });
      const {
        schema
      } = editorState;
      const imageNode = schema.nodes.image.create({
        src: data.src,
        isTemporary: true,
        uploadPercent: 0,
        originalDimensions: data.originalDimensions,
        width: data.width,
        fileToUpload: file,
        fileManagerAccess: fileOptions.fileManagerAccess
      });
      fragmentNodes.push(schema.nodes.paragraph.create(), imageNode);
    });
  });
  return Promise.all(promises).then(() => {
    let tr = editorState.tr;
    tr = tr.replaceSelection(new Slice(Fragment.fromArray([...fragmentNodes, editorState.schema.nodes.paragraph.create()]), 0, 0));
    dispatcher(tr);
  });
}