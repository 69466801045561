import { useFetchViewById } from './useFetchViewById';
// @ts-expect-error untyped
import { useActiveViewId } from './viewTabs';
export function useActiveView({
  skipQuery = false
} = {}) {
  const viewId = useActiveViewId();
  return useFetchViewById({
    skipQuery,
    viewId
  });
}