import { fetchNativeIntegrationAppStatus } from '../../api/marketplaceIntegrationsApi';
import { getLinkedInSalesNavigatorId } from '../../constants/marketplaceAppIntegrationIds';
import { reportNewTasksError } from '../../tracking/errors';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
const INTEGRATION_STATUS_CONNECTED = 'CONNECTED';

// TODO Separate out UserInfo, eg { userInfo: { user: ..., gates: ... }, ownerId: ... } and clean up types
export const initAuth = createAction('initAuth');
export const setJitaPretendOwnerId = createAction('setJitaPretendOwnerId');
export const fetchLinkedInIntegrationStatus = createAsyncThunk('fetchLinkedInIntegrationStatus', () => {
  const linkedInAppId = getLinkedInSalesNavigatorId();
  return fetchNativeIntegrationAppStatus(linkedInAppId).then(integrationResults => {
    const linkedInResult = integrationResults[0];
    if (linkedInResult && linkedInResult.integrationStatus === INTEGRATION_STATUS_CONNECTED) {
      return {
        appId: linkedInAppId,
        status: true
      };
    }
    return {
      appId: linkedInAppId,
      status: false
    };
  }, error => {
    reportNewTasksError(error, {
      groupName: 'LinkedIn',
      functionName: 'fetchLinkedInIntegrationStatus'
    });
    return {
      appId: linkedInAppId,
      status: false
    };
  });
});