import { inputRules, wrappingInputRule } from 'prosemirror-inputrules';
export function orderedListRule(nodeType) {
  return wrappingInputRule(/^(\d+)\.\s$/, nodeType, match => ({
    order: +match[1]
  }), (match, node) => node.childCount + node.attrs.order === +match[1]);
}

// : (NodeType) → InputRule
// Given a list node type, returns an input rule that turns a bullet
// (dash, plush, or asterisk) at the start of a textblock into a
// bullet list.
export function bulletListRule(nodeType) {
  return wrappingInputRule(/^\s*([-+*])\s$/, nodeType);
}
export const getInputRules = schema => {
  return inputRules({
    rules: [bulletListRule(schema.nodes.bullet_list), orderedListRule(schema.nodes.ordered_list)]
  });
};