import { List } from 'immutable';
import { DOMSerializer } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { fontPluginKey } from './plugins/FontPlugin';
import { FocusPlugin } from './plugins/FocusPlugin';
import { createDefaultFontStyleString } from './utils/fontUtils';
import { findAllNodes, findNode } from './utils/nodeUtils';
import { getDomParser } from './utils/domparser';
import { convertToPlaintextState } from './utils/plaintextUtils';
import { buildDefaultRTEConfig } from './utils/configUtils';
export function convertToHtml(editorState) {
  const div = document.createElement('div');
  let defaultFontStyleString;

  // DefaultFont Block
  const fontPluginState = fontPluginKey.getState(editorState);
  if (fontPluginState && fontPluginState.defaultFontInfo) {
    const {
      defaultFontSize,
      defaultFontStyle,
      defaultTextColor
    } = fontPluginState.defaultFontInfo;
    defaultFontStyleString = createDefaultFontStyleString({
      defaultFontSize,
      defaultFontStyle,
      defaultTextColor
    });
    div.setAttribute('style', defaultFontStyleString);
  }

  // set auto direction for RTL languages
  div.setAttribute('dir', 'auto');
  div.setAttribute('data-top-level', 'true');
  const fragment = DOMSerializer.fromSchema(editorState.schema).serializeFragment(editorState.doc.content);
  div.appendChild(fragment);
  div.childNodes.forEach(child => {
    if (child.nodeName === 'P' && !child.innerHTML.trim()) {
      const linebreak = document.createElement('br');
      child.replaceWith(linebreak);
    }
  });
  return div.outerHTML;
}
export function convertToPlaintext(editorState) {
  const plainState = convertToPlaintextState(editorState);
  return plainState.doc.textBetween(0, plainState.doc.content.size, '\n');
}
export function hasContent(editorState) {
  // checks for content fragment size (minus two for boundaries of Node)
  return !!(editorState.doc.content.size - 2);
}
export function isUploadingImages(editorState) {
  return !!findNode(editorState.doc, n => n.type === editorState.schema.nodes.image && n.attrs.isTemporary);
}
export function convertToJSON(editorState) {
  return editorState.toJSON();
}
export function getEditorStateFromJSON(json, customPlugins) {
  return EditorState.fromJSON(buildDefaultRTEConfig(customPlugins), json);
}
export function getEditorStateFromHtml(htmlContent, customPlugins, options) {
  const docNode = getDocNodeFromHtml(htmlContent, options);
  return EditorState.create(Object.assign({
    doc: docNode
  }, buildDefaultRTEConfig(customPlugins)));
}
export function getDocNodeFromHtml(htmlContent, {
  preserveNewlines = true
} = {
  preserveNewlines: true
}) {
  if (preserveNewlines) {
    htmlContent = htmlContent.replace(new RegExp('\\n', 'g'), '<br>');
  }
  const div = document.implementation.createHTMLDocument().createElement('div');
  div.innerHTML = htmlContent;
  return getDomParser().parse(div);
}
export function focusEditor(editorState, focusViewPos) {
  return editorState.apply(editorState.tr.setMeta(FocusPlugin, {
    focusView: true,
    focusViewPos
  }));
}
export function getAtMentions(editorState) {
  const mentions = findAllNodes(editorState.doc, node => node.type === editorState.schema.nodes.atmention);
  let mentionIds = List();
  mentions.forEach(mention => {
    const ownerId = parseInt(mention.node.attrs.id, 10);
    if (ownerId && !isNaN(ownerId) && !mentionIds.has(ownerId)) {
      mentionIds = mentionIds.push(ownerId);
    }
  });
  return mentionIds;
}
export function getNumberOfNodeType(editorState, nodeType) {
  const nodesFound = findAllNodes(editorState.doc, node => node.type === nodeType);
  return nodesFound.length;
}
export function getNumberOfImages(editorState) {
  return getNumberOfNodeType(editorState, editorState.schema.nodes.image);
}
export function getNumberOfLinks(editorState) {
  let numLinks = 0;
  editorState.doc.descendants(node => {
    if (editorState.schema.marks.link.isInSet(node.marks)) {
      numLinks++;
    }
    return true;
  });
  return numLinks;
}
export function getMatchResults(query, data) {
  const exactRegex = new RegExp(`^${query.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}`, 'i');
  const globalRegex = new RegExp(query.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
  const matchResults = data.reduce((acc, item) => {
    const toSearch = item.searchText || item.name || '';
    const isExactMatch = toSearch.match(exactRegex) !== null;
    if (isExactMatch) {
      return Object.assign({}, acc, {
        exactMatches: [...acc.exactMatches, item]
      });
    }
    const isGlobalMatch = toSearch.match(globalRegex) !== null;
    if (isGlobalMatch) {
      return Object.assign({}, acc, {
        globalMatches: [...acc.globalMatches, item]
      });
    }
    return acc;
  }, {
    exactMatches: [],
    globalMatches: []
  });
  return [...matchResults.exactMatches, ...matchResults.globalMatches];
}