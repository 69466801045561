'use es6';

import { createFetchImportNamesByObjectType, fetchImportNamesByObjectType } from '../api/ImportsAPI';
import * as CacheKeys from '../constants/CacheKeys';
import createSimpleCachedReferenceResolver from '../lib/createSimpleCachedReferenceResolver';
export const createInboundDbTaskImportReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.INBOUND_DB_TASK_IMPORT,
  createFetchData: opts => {
    const getFn = createFetchImportNamesByObjectType(opts);
    return () => getFn('task');
  },
  fetchData: () => fetchImportNamesByObjectType('task')
}, options));
export default createInboundDbTaskImportReferenceResolver();