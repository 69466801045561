'use es6';

import quickFetch from 'quick-fetch';
import { formatFetchUserSettingsResponse } from '../formatters/formatFetchUserSettingsResponse';
export function fetchUserSettingsWithEarlyRequestHook(fallbackFetch) {
  const earlyRequest = quickFetch.getRequestStateByName('tasks-user-settings');
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(result => {
        resolve(formatFetchUserSettingsResponse(result));
      });
      earlyRequest.onError(() => fallbackFetch().then(result => resolve(formatFetchUserSettingsResponse(result)), reject));
    });
  }
  return fallbackFetch();
}