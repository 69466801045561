import get from 'transmute/get';
import { createTracker } from 'usage-tracker';
import events from '../../../events.yaml';
import { PAGE_IDS } from '../constants/pageConfigs';
import { areFiltersDifferent } from '../utils/crmSearchFilters';
import { VIEW_ID_KEYS } from '../utils/views';
const PAGEVIEW = 'indexPageView';
const TASKS_HOME_PAGEVIEW = 'tasksHomePageView';
const INTERACTION = 'tasksInteraction';
const SAVED_VIEWS_INTERACTION = 'savedViewsInteraction';
const START_QUEUE = 'startTaskQueue';
const UNASSOCIATED_TASK_MODAL = 'unassociatedTaskModalInteraction';
const CUSTOM_VIEW_IDENTIFIER = 'custom view';
function getGenericViewIdentifier(viewId) {
  const viewIdAsNumber = typeof viewId === 'string' ? parseInt(viewId, 10) : viewId;

  // if the viewId is a string key from utils/views, keep the string
  if (isNaN(viewIdAsNumber)) {
    return viewId;
  } else {
    return CUSTOM_VIEW_IDENTIFIER;
  }
}

///////////////////////////////////////
// main tracking functions
///////////////////////////////////////

export const tracker = createTracker({
  events,
  properties: {
    namespace: 'tasks'
  }
});
export function trackPageview(screen, viewId) {
  tracker.track(PAGEVIEW, {
    screen,
    tab: getGenericViewIdentifier(viewId)
  });
}
export const START_QUEUE_SOURCES = {
  START_BUTTON: 'startButton',
  TABLE_ROW: 'tableRow',
  BULK_ACTION: 'bulkAction'
};

// As defined in customer-data-tracking/tracking/tasks.yaml
export const QUEUE_TYPES = {
  PRIVATE: 'private',
  SHARED: 'shared',
  VIEW: 'view'
};
export function trackStartQueue({
  completed,
  source,
  viewId,
  taskId
}) {
  tracker.track(START_QUEUE, {
    completed,
    source,
    tab: getGenericViewIdentifier(viewId),
    id: taskId // optional
  });
}
export const SAVED_VIEW_LOCATIONS = {
  DROPDOWN: 'dropdown',
  MANAGE_VIEWS: 'manageViews',
  SAVED_VIEW_BUTTON: 'saveViewButton',
  ADD_VIEW_BUTTON: 'addViewButton'
};
export const trackSavedViewsInteraction = (action, extraProperties = {}) => {
  const {
    id,
    triggeredFrom,
    isPrivate,
    teamId,
    filters,
    columns,
    order,
    isClone
  } = extraProperties;
  tracker.track(SAVED_VIEWS_INTERACTION, {
    action,
    id,
    triggeredFrom,
    isPrivate,
    teamId,
    filters,
    columns,
    order,
    isClone
  });
};
export function trackInteraction(action, extraProperties = {}) {
  const {
    id,
    property,
    value,
    count,
    stepNumber,
    added,
    removed,
    selected,
    operator,
    highValue,
    source
  } = extraProperties;
  tracker.track(INTERACTION, {
    action,
    id,
    property,
    value: String(value),
    count,
    stepNumber,
    added,
    removed,
    selected,
    operator,
    highValue,
    source
  });
}
function trackUnassociatedTaskModalInteraction(action, extraProperties = {}) {
  const {
    associationCount
  } = extraProperties;
  tracker.track(UNASSOCIATED_TASK_MODAL, {
    screen: PAGE_IDS.TASKS_HOME,
    action,
    associationCount
  });
}

///////////////////////////////////////
// helper tracking functions
///////////////////////////////////////

const INTERACTIONS = {
  ACTIVITY_FEED_VIEWED: 'activityFeedViewed',
  BACK_TO_TASKS_CLICKED: 'backToTasksClicked',
  BULK_EDITED_TASKS: 'bulkEdited',
  BULK_DELETED_TASKS: 'bulkDeleted',
  COLUMNS_EDITED: 'columnsEdited',
  CREATE_QUEUE: 'createQueue',
  DELETE_QUEUE: 'deleteQueue',
  EDIT_COLUMNS_CLICKED: 'editColumnsClicked',
  EDIT_QUEUE: 'editQueue',
  FILTER_APPLIED: 'filterApplied',
  FILTER_DELETED: 'filterDeleted',
  RIGHT_SIDEBAR_TOGGLED: 'rightSidebarToggled',
  SCHEDULE_SIDEBAR_VIEWED: 'scheduleSidebarViewed',
  TASK_SYNC_BANNER_DISMISSED: 'taskSyncBannerDismissed',
  TASK_SYNC_BANNER_LINK_CLICKED: 'taskSyncBannerLinkClicked'
};
const UNASSOCIATED_TASK_MODAL_INTERACTIONS = {
  VIEWED: 'viewed',
  ASSOCIATIONS_ADDED: 'associationsAdded',
  DISMISSED: 'dismissed',
  MARKED_COMPLETED: 'markedCompleted',
  SKIPPED: 'skipped',
  REDIRECTED_AFTER_LOAD: 'redirectedAfterLoad'
};
const SAVED_VIEWS_INTERACTIONS = {
  CREATED: 'savedViewCreation',
  PINNED: 'savedViewPinned',
  UNPINNED: 'savedViewUnpinned',
  FILTERS_COLUMNS_UPDATED: 'savedViewFiltersColumnsUpdated',
  RENAMED: 'savedViewRenamed',
  SHARING: 'savedViewSharingManaged',
  DELETED: 'savedViewDeleted',
  REORDERED: 'savedViewsReordered',
  START_TASKS_CLICKED: 'savedViewsStartTasksClicked',
  ADD_VIEW_CLICKED: 'savedViewsAddViewClicked'
};
export const trackSavedViewCreation = ({
  id,
  triggeredFrom,
  isClone,
  filters,
  columns
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.CREATED, {
    id,
    triggeredFrom,
    isClone,
    filters,
    columns
  });
};
export const trackSavedViewPinned = ({
  id,
  triggeredFrom
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.PINNED, {
    id,
    triggeredFrom
  });
};
export const trackSavedViewUnpinned = ({
  id,
  triggeredFrom
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.UNPINNED, {
    id,
    triggeredFrom
  });
};
export const trackSavedViewFiltersColumnsUpdated = ({
  id,
  filters,
  columns
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.FILTERS_COLUMNS_UPDATED, {
    id,
    filters,
    columns
  });
};
export const trackSavedViewRenamed = ({
  id,
  triggeredFrom
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.RENAMED, {
    id,
    triggeredFrom
  });
};
export const trackSavedViewSharing = ({
  id,
  triggeredFrom,
  teamId,
  isPrivate
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.SHARING, {
    id,
    triggeredFrom,
    teamId,
    isPrivate
  });
};
export const trackSavedViewDeleted = ({
  id,
  triggeredFrom
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.DELETED, {
    id,
    triggeredFrom
  });
};
export const trackSavedViewReordered = ({
  order
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.REORDERED, {
    order
  });
};
export const trackSavedViewStartTasksClicked = ({
  id
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.START_TASKS_CLICKED, {
    id
  });
};
export const trackSavedViewAddViewClicked = ({
  id
}) => {
  trackSavedViewsInteraction(SAVED_VIEWS_INTERACTIONS.ADD_VIEW_CLICKED, {
    id
  });
};
export function trackUnassociatedTaskModalViewed({
  associationCount
}) {
  trackUnassociatedTaskModalInteraction(UNASSOCIATED_TASK_MODAL_INTERACTIONS.VIEWED, {
    associationCount
  });
}
export function trackRedirectedToRecordAfterLoad({
  associationCount
}) {
  trackUnassociatedTaskModalInteraction(UNASSOCIATED_TASK_MODAL_INTERACTIONS.REDIRECTED_AFTER_LOAD, {
    associationCount
  });
}
export function trackUnassociatedTaskModalDismissed() {
  trackUnassociatedTaskModalInteraction(UNASSOCIATED_TASK_MODAL_INTERACTIONS.DISMISSED);
}
export function trackUnassociatedTaskModalAssociationsAdded({
  associationCount
}) {
  trackUnassociatedTaskModalInteraction(UNASSOCIATED_TASK_MODAL_INTERACTIONS.ASSOCIATIONS_ADDED, {
    associationCount
  });
}
export function trackUnassociatedTaskModalTaskCompleted() {
  trackUnassociatedTaskModalInteraction(UNASSOCIATED_TASK_MODAL_INTERACTIONS.MARKED_COMPLETED);
}
export function trackUnassociatedTaskModalTaskSkipped() {
  trackUnassociatedTaskModalInteraction(UNASSOCIATED_TASK_MODAL_INTERACTIONS.SKIPPED);
}
export function trackTasksHomePageview(viewId) {
  tracker.track(TASKS_HOME_PAGEVIEW, {
    screen: PAGE_IDS.TASKS_HOME,
    tab: getGenericViewIdentifier(viewId)
  });
}
export function trackFilterChanged({
  filterName,
  filterValue,
  filterHighValue,
  filterOperator,
  filterSource,
  wasFilterDeleted = false
}) {
  const action = wasFilterDeleted ? INTERACTIONS.FILTER_DELETED : INTERACTIONS.FILTER_APPLIED;
  trackInteraction(action, {
    id: filterName,
    value: filterValue,
    highValue: filterHighValue,
    operator: filterOperator,
    source: filterSource
  });
}
export function trackQueueCreated(numberOfParticipants) {
  trackInteraction(INTERACTIONS.CREATE_QUEUE, {
    count: numberOfParticipants
  });
}
export function trackQueueEdited(numberOfParticipants) {
  trackInteraction(INTERACTIONS.EDIT_QUEUE, {
    count: numberOfParticipants
  });
}
export function trackQueueDeleted(numberOfParticipants) {
  trackInteraction(INTERACTIONS.DELETE_QUEUE, {
    count: numberOfParticipants
  });
}
export function trackLegacyBoardPageview() {
  trackPageview(PAGE_IDS.LEGACY_BOARD, VIEW_ID_KEYS.OPEN_TASKS_ALL);
}
export function trackActivityFeedViewed() {
  trackInteraction(INTERACTIONS.ACTIVITY_FEED_VIEWED);
}
export function trackBackToTasksClicked() {
  trackInteraction(INTERACTIONS.BACK_TO_TASKS_CLICKED);
}
export function trackColumnsEdited({
  addedColumns,
  removedColumns,
  allColumns
}) {
  trackInteraction(INTERACTIONS.COLUMNS_EDITED, {
    added: addedColumns,
    removed: removedColumns,
    selected: allColumns
  });
}
export function trackEditColumnsClicked() {
  trackInteraction(INTERACTIONS.EDIT_COLUMNS_CLICKED);
}
export function trackBulkEditedProperty({
  propertyName,
  value,
  count,
  allItems
}) {
  trackInteraction(INTERACTIONS.BULK_EDITED_TASKS, {
    property: propertyName,
    value,
    count,
    allItems
  });
}
export function trackBulkDeletedTasks({
  allIems = false
} = {}) {
  trackInteraction(INTERACTIONS.BULK_DELETED_TASKS, {
    allIems
  });
}
export function trackScheduleTabViewed() {
  trackInteraction(INTERACTIONS.SCHEDULE_SIDEBAR_VIEWED);
}
export function trackTaskSyncBannerDismissed() {
  trackInteraction(INTERACTIONS.TASK_SYNC_BANNER_DISMISSED);
}
export function trackTaskSyncBannerLinkClicked() {
  trackInteraction(INTERACTIONS.TASK_SYNC_BANNER_LINK_CLICKED);
}
export function trackChangedFiltersForFilterEditor(originalFilters, updatedFilters) {
  const allAppliedProperties = new Set([...originalFilters.map(get('property')), ...updatedFilters.map(get('property'))]);
  allAppliedProperties.forEach(propertyName => {
    const oldFilterObject = originalFilters.find(filter => filter.property === propertyName);
    const newFilterObject = updatedFilters.find(filter => filter.property === propertyName);

    // filter deleted
    if (!newFilterObject && oldFilterObject) {
      const oldValue = oldFilterObject.value ? oldFilterObject.value : oldFilterObject.values;
      const operator = oldFilterObject.operator;
      trackFilterChanged({
        filterName: propertyName,
        filterValue: oldValue,
        filterHighValue: oldFilterObject.highValue,
        filterOperator: operator,
        filterSource: 'filterEditor',
        wasFilterDeleted: true
      });
      return;
    }
    const value = newFilterObject.value ? newFilterObject.value : newFilterObject.values;

    // filter does not exist in old filters and is new or the values
    // are different, so want to track the update
    if ((!oldFilterObject || areFiltersDifferent(oldFilterObject, newFilterObject)) && newFilterObject) {
      const operator = newFilterObject.operator;
      trackFilterChanged({
        filterName: propertyName,
        filterValue: value,
        filterHighValue: newFilterObject.highValue,
        filterSource: 'filterEditor',
        filterOperator: operator
      });
    }
  });
}