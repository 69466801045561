import { clearTaskQueueConfig } from '../storage/clearTaskQueueConfig';
import { getTaskQueueConfig } from '../storage/getTaskQueueConfig';
import { updateTaskQueueConfig } from '../storage/updateTaskQueueConfig';
import { validateStorageShape } from '../storage/validateStorageShape';
export const setCurrentTaskCompleted = storage => () => {
  validateStorageShape(storage);
  const {
    completedTaskIds = [],
    currentTaskId,
    queueProgressIndex,
    taskIds
  } = getTaskQueueConfig(storage)();
  const newCompletedTasks = [...completedTaskIds, currentTaskId];
  const oldCurrentTaskIndex = taskIds.indexOf(currentTaskId);
  const newCurrentTaskId = taskIds[oldCurrentTaskIndex + 1];

  // if there isn't a next task then we're done here
  if (!newCurrentTaskId) {
    clearTaskQueueConfig(storage);
  }
  updateTaskQueueConfig(storage)({
    completedTaskIds: newCompletedTasks,
    currentTaskId: newCurrentTaskId,
    queueProgressIndex: queueProgressIndex + 1
  });
};