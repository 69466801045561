import invariant from 'react-utils/invariant';
export function _valuesAreNotObjects(object) {
  return !Object.values(object).find(value => typeof value === 'object');
}
export function reverseKeysAndValues(object) {
  invariant(_valuesAreNotObjects(object), "reverseKeysAndValues can't be used with nested objects");
  return Object.keys(object).reduce((reversedObject, key) => {
    return Object.assign({}, reversedObject, {
      [object[key]]: key
    });
  }, {});
}