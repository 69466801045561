import { AssociationCategoryTypesToIds, USER_DEFINED } from 'customer-data-objects/associations/AssociationCategoryTypes';
import { toCrmObjectAssociationKey } from './crmObjectHelpers';
const ASSOCIATION_COLUMN_NAME_PREFIX = 'association';
export function getAssociationColumnKey(associationCategoryId, associationTypeId) {
  // Will be formatted as associaton-0-204
  return `${ASSOCIATION_COLUMN_NAME_PREFIX}-${toCrmObjectAssociationKey({
    associationCategoryId,
    associationTypeId
  })}`;
}
export function isAssociationColumnName(columnName) {
  return columnName && columnName.includes(ASSOCIATION_COLUMN_NAME_PREFIX);
}
export function isCustomObjectAssociationColumnName(columnName) {
  if (isAssociationColumnName(columnName)) {
    const [associationCategoryId, __] = columnName.replace(`${ASSOCIATION_COLUMN_NAME_PREFIX}-`, '').split('-');
    return associationCategoryId === AssociationCategoryTypesToIds[USER_DEFINED];
  }
  return false;
}

/**
 * Transforms a string like 'association-0-204' => { associationCategory: 0, associationTypeId: 204 }
 * @param {String} associationColumnName
 */
export function associationColumnNameToAssociationSpec(associationColumnName) {
  const [associationCategoryId, associationTypeId] = associationColumnName.replace(`${ASSOCIATION_COLUMN_NAME_PREFIX}-`, '').split('-');
  return {
    associationCategoryId: Number(associationCategoryId),
    associationTypeId: Number(associationTypeId)
  };
}