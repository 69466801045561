'use es6';

import quickFetch from 'quick-fetch';
import { formatFetchUserSettingsResponse } from '../formatters/formatFetchUserSettingsResponse';
export function getUserSettingsEarlyRequesterData() {
  const earlyRequestState = quickFetch.getRequestStateByName('tasks-user-settings');
  if (!earlyRequestState || earlyRequestState.errored || !earlyRequestState.finished || !earlyRequestState.data) {
    return undefined;
  }
  return formatFetchUserSettingsResponse(earlyRequestState.data.results);
}