import { createReducer } from '@reduxjs/toolkit';
import omit from 'transmute/omit';
import { ASC, DESC } from '../../constants/sorts';
import { deleteView, resetView, seedView, setViewColumns, setViewOffset } from '../actions/tempViewState';
import { resetTempViewFilters, setSearchText, setSortState, updateTempViewCrmSearchQuery } from '../actions/tempViewState';
const defaultState = {};
const transformFreshView = view => ({
  id: view.id,
  filters: view.filters,
  defaultFilters: view.filters,
  columns: view.columns,
  sortState: {
    sortOrder: view.state.order === 1 ? DESC : ASC,
    propertyName: view.state.sortColumnName
  },
  searchText: '',
  offset: 0
});
export const tempViewState = createReducer(defaultState, builder => builder.addCase(seedView, (state, action) => {
  // no-op if the temp view state already exists
  if (!(action.payload.viewId in state)) {
    state[action.payload.viewId] = transformFreshView(action.payload.view);
  }
}).addCase(resetView, (state, action) => {
  state[action.payload.viewId] = transformFreshView(action.payload.view);
}).addCase(deleteView, (state, action) => {
  return action.payload.viewId in state ? omit([action.payload.viewId], state) : state;
}).addCase(setViewOffset, (state, action) => {
  if (action.payload.viewId in state) {
    state[action.payload.viewId].offset = action.payload.offset;
  }
}).addCase(setSortState, (state, action) => {
  if (action.payload.viewId in state) {
    state[action.payload.viewId].sortState = action.payload.sortState;
    state[action.payload.viewId].offset = 0;
  }
}).addCase(setSearchText, (state, action) => {
  if (action.payload.viewId in state) {
    state[action.payload.viewId].searchText = action.payload.searchText;
    state[action.payload.viewId].offset = 0;
  }
}).addCase(updateTempViewCrmSearchQuery, (state, action) => {
  const {
    viewId
  } = action.payload;
  if (viewId in state) {
    state[viewId].filters = action.payload.crmSearchFilters;
    state[viewId].offset = 0;
  }
}).addCase(resetTempViewFilters, (state, action) => {
  const {
    viewId
  } = action.payload;
  if (viewId in state) {
    state[viewId].filters = state[viewId].defaultFilters;
    state[viewId].offset = 0;
  }
}).addCase(setViewColumns, (state, action) => {
  if (action.payload.viewId in state) {
    state[action.payload.viewId].columns = action.payload.columns;
  }
}));