/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { Plugin } from 'prosemirror-state';
import { Map as ImmutableMap } from 'immutable';
import { addTemporaryImageNodes, isImageFile } from '../utils/imageUtils';
import { uploadFile } from '../../draft-transplant/api/FileManagerApi';
const emptyFunction = () => {};
const createFileTypeRegexps = fileTypes => fileTypes && fileTypes.map(type => new RegExp(type));
function handleDroppedFiles({
  onNonImageUploadStarted = emptyFunction,
  onDropNonImage = emptyFunction,
  onNonImageUploadFailed = emptyFunction,
  fileOptions = {},
  skipNonImageUpload = false,
  allowedFileTypes,
  // undefined will allow any files to be uploaded
  onUnsupportedFileDropped = emptyFunction,
  uploadDroppedFile = uploadFile,
  usePlaintextMode = false
}, files, view) {
  if (!files || files.length === 0) {
    return;
  }
  const acceptedFileChecks = createFileTypeRegexps(allowedFileTypes);
  const fileTypeValidator = type => acceptedFileChecks && acceptedFileChecks.some(check => check.test(type));
  const handleNonImageDrop = file => {
    const fileToUpload = ImmutableMap({
      name: file.name,
      size: file.size,
      type: file.type
    });
    onNonImageUploadStarted(fileToUpload);
    if (!skipNonImageUpload) {
      uploadDroppedFile(file, fileOptions).then(data => {
        // use a Map because most consumers (namely CRM) wrap
        // API responses in Immutable Records
        let fileData = ImmutableMap(data.objects[0]);
        if (fileData && onDropNonImage) {
          fileData = fileData.merge({
            originalName: fileToUpload.get('name')
          });
          onDropNonImage(fileData, file);
        }
      }).catch(error => onNonImageUploadFailed(error, fileToUpload));
    } else {
      onDropNonImage(null, file);
    }
  };
  const imagesToUpload = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file) {
      if (allowedFileTypes && !fileTypeValidator(file.type)) {
        onUnsupportedFileDropped(file);
      } else {
        if (!usePlaintextMode && isImageFile(file)) {
          imagesToUpload.push(file);
        } else {
          handleNonImageDrop(file);
        }
      }
    }
  }
  if (imagesToUpload.length !== 0) {
    addTemporaryImageNodes(view.state, view.dispatch, imagesToUpload, {
      fileManagerAccess: fileOptions.access
    });
  }
}
export const initialState = {
  files: null
};

/**
 * TODO: move this into `DropPlugin` state
 */
// eslint-disable-next-line no-restricted-syntax
let fileDropConfig;
export const DropPlugin = new Plugin({
  state: {
    init() {
      return initialState;
    },
    apply(tr, currentPluginState) {
      const meta = tr.getMeta(DropPlugin);
      if (meta) {
        fileDropConfig = meta.fileDropConfig || fileDropConfig;
        return meta;
      }
      return currentPluginState;
    }
  },
  view() {
    return {
      update: (view, prevState) => {
        const pluginState = DropPlugin.getState(view.state);
        const prevPluginState = DropPlugin.getState(prevState);
        if (pluginState.files && pluginState.files.length && prevPluginState.files && prevPluginState.files.length) {
          view.dispatch(view.state.tr.setMeta(DropPlugin, initialState));
          return;
        }
        const {
          files
        } = pluginState;
        if (files && files.length && fileDropConfig) {
          handleDroppedFiles(fileDropConfig, files, view);
        }
      }
    };
  },
  props: {
    /** TODO: this should only be handled in ImagePastePlugin - right now this allows pasting images even if INLINE_IMAGE is not a capability */
    // TODO: Change naming of functions - https://git.hubteam.com/HubSpot/hubspot-prosemirror/pull/214/files#r2034643
    handlePaste: (view, event) => {
      if (event.clipboardData) {
        const files = event.clipboardData.files;
        if (files.length && !event.clipboardData.getData('text')) {
          // TODO: Only uploading first file for draft parity, consider multi-file paste
          const file = files.item(0);
          if (file) {
            const dt = new DataTransfer();
            dt.items.add(file);
            view.dispatch(view.state.tr.setMeta(DropPlugin, {
              files: dt.files
            }));
            return true;
          }
        }
      }
      return false;
    }
  }
});