'use es6';

import * as objectTypes from 'customer-data-objects/constants/ObjectTypes';
import CompanyRecord from 'customer-data-objects/company/CompanyRecord';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import DealRecord from 'customer-data-objects/deal/DealRecord';
import QuoteRecord from 'customer-data-objects/quote/QuoteRecord';
import TaskRecord from 'customer-data-objects/task/TaskRecord';
import TicketRecord from 'customer-data-objects/ticket/TicketRecord';
export const objectRecords = {
  [objectTypes.CONTACT]: ContactRecord,
  [objectTypes.COMPANY]: CompanyRecord,
  [objectTypes.TICKET]: TicketRecord,
  [objectTypes.DEAL]: DealRecord,
  [objectTypes.QUOTE]: QuoteRecord,
  [objectTypes.TASK]: TaskRecord
};