import { createTracker } from 'usage-tracker';
import rteEvents from 'hubspot-prosemirror/events.yaml';
import userInfo from 'hub-http/userInfo';
import PortalIdParser from 'PortalIdParser';
export const Tracker = createTracker({
  trackerName: 'rte-tracker',
  events: rteEvents,
  properties: {
    textLibrary: 'prosemirror',
    email: () => userInfo({
      cached: true,
      ignoreRedirect: true
    }).then(({
      user
    }) => user.email).catch(() => 'unknown'),
    hubId: () => PortalIdParser.get() || 'unknown'
  }
});