import { Record as ImmutableRecord, List } from 'immutable';
import LineItemRecord from 'customer-data-objects/lineItem/LineItemRecord';
import CompanyRecord from 'customer-data-objects/company/CompanyRecord';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import QuoteFeeRecord from './QuoteFeeRecord';
import QuoteSignerRecord from './QuoteSignerRecord';
import { DEFAULT_QUOTE_ASSOCIATED_OBJECTS } from './constants/properties';
export default class QuoteAssociatedObjects extends ImmutableRecord(DEFAULT_QUOTE_ASSOCIATED_OBJECTS) {
  static fromJS(json) {
    if (json.lineItems) {
      json.lineItems = List(json.lineItems).map(LineItemRecord.fromJS);
    }
    if (json.recipientCompany) {
      json.recipientCompany = CompanyRecord.fromJS(json.recipientCompany).set('companyId', json.recipientCompany.objectId);
    }
    if (json.recipientContacts) {
      json.recipientContacts = List(json.recipientContacts.map(contact => ContactRecord.fromJS(contact).set('vid', contact.objectId)));
    }
    if (json.additionalFees) {
      json.additionalFees = List(json.additionalFees.map(fee => new QuoteFeeRecord(fee)));
    }
    if (json.quoteSigners) {
      json.quoteSigners = List(json.quoteSigners.map(quoteSigner => new QuoteSignerRecord(quoteSigner)));
    }
    if (json.contactSigners) {
      json.contactSigners = List(json.contactSigners.map(contactSigner => new QuoteSignerRecord(contactSigner)));
    }
    if (json.userSigners) {
      json.userSigners = List(json.userSigners.map(userSigner => new QuoteSignerRecord(userSigner)));
    }
    return new QuoteAssociatedObjects(json);
  }
}