export function snapToBusinessDay(momentDate) {
  const SATURDAY = 6;
  const SUNDAY = 7;
  const NEXT_MONDAY = 8;
  if (momentDate.isoWeekday() === SATURDAY || momentDate.isoWeekday() === SUNDAY) {
    // set to the monday after
    return momentDate.clone().isoWeekday(NEXT_MONDAY);
  }
  return momentDate;
}
export function addBusinessDays(fromDate, days) {
  let toDate = fromDate.clone();
  for (let i = 0; i < days; i++) {
    toDate = snapToBusinessDay(toDate.add(1, 'day'));
  }
  return toDate;
}