'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import getIn from 'transmute/getIn';
export const SUPPORTED_OBJECT_TYPES = {
  TASK: 'TASK'
};
export const SUPPORTED_PROPERTIES = {
  LAST_TOUCH: 'LAST_TOUCH',
  LAST_ENGAGEMENT: 'LAST_ENGAGEMENT'
};
const getLabelForProperty = {
  [SUPPORTED_PROPERTIES.LAST_TOUCH]: getIn(['engagement', 'properties', 'hs_timestamp', 'value']),
  [SUPPORTED_PROPERTIES.LAST_ENGAGEMENT]: data => String(getIn(['activity', 'createdAt'], data))
};
export const createGetHydratedPropertiesByObjectId = ({
  httpClient = http,
  fetchOptions: {
    objectType,
    property
  }
}) => objectIds => {
  return httpClient.post(`accountbasedgrowth/v1/property-hydration/${objectType}`, {
    data: {
      ids: objectIds,
      properties: [property]
    }
  }).then(data => {
    const valuesForGivenProperties = objectIds.map(objectId => {
      const value = getIn(['hits', objectId, property], data);
      if (value) {
        return Object.assign({}, value, {
          hydratedFromObjectId: objectId
        });
      }
      return null;
    }).filter(value => Boolean(value));
    const referenceList = formatToReferencesList({
      getId: getIn(['hydratedFromObjectId']),
      getLabel: getLabelForProperty[property]
    })(valuesForGivenProperties);
    return referenceList;
  });
};