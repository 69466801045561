'use es6';

import { useEffect, useState } from 'react';
import { AssociationsBatchRequestClient } from '../clients/AssociationsBatchRequestClient';
import { useIsMounted } from './useIsMounted';

/**
 * Usage:
 *
 * const { data, loading, error } = useFetchAssociatedObjectIds({
 *   associationDefinition: ContactToCompanyAssociationDefinition,
 *   objectId: 12345
 * })
 *
 * if (loading) {
 *   // render loading state
 *   return <UILoadingSpinner />
 * }
 *
 * if (error) {
 *   // render error state
 *   return renderError(error);
 * }
 *
 * if (data) {
 *   // render data
 * }
 *
 * @param associationDefinition - The association definition to fetch associated
 * objects for
 * @param objectId - The objectId whose associated objects will be fetched
 */
export const useFetchAssociatedObjectIds = ({
  associationDefinition,
  objectId
}) => {
  const isMounted = useIsMounted();
  const [associatedObjects, setAssociatedObjects] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {
    associationCategory,
    associationTypeId
  } = associationDefinition;
  useEffect(() => {
    setLoading(true);
    AssociationsBatchRequestClient.fetch({
      associationCategory,
      associationTypeId,
      objectId
    }).then(response => {
      if (isMounted) {
        setLoading(false);
        setAssociatedObjects(response);
      }
    }).catch(responseError => {
      if (isMounted) {
        setLoading(false);
        setError(responseError);
      }
    });
    return () => {
      setError(false);
      setAssociatedObjects(undefined);
    };
  }, [associationCategory, associationDefinition, associationTypeId, isMounted, objectId]);
  return {
    data: associatedObjects,
    error,
    loading
  };
};