import { registerQuery, useQuery } from 'data-fetching-client';
import { getViewPermissionsById } from '../api/views';
export const viewPermissionsFieldName = 'viewPermissions';
export const fetchViewPermissionsQuery = registerQuery({
  fieldName: viewPermissionsFieldName,
  args: ['viewId'],
  fetcher: getViewPermissionsById
});
export const useFetchViewPermissionsById = viewId => {
  const isDefaultView = typeof viewId === 'string' && isNaN(parseInt(viewId, 10));
  const {
    data,
    loading,
    error
  } = useQuery(fetchViewPermissionsQuery, {
    variables: {
      viewId: viewId
    },
    skip: viewId === null || isDefaultView,
    // don't make the request if the ID is null, such as when creating a new view or if its a default view.
    fetchPolicy: 'cache-first'
  });
  const viewUserIds = loading || error || !data ? [] : data[viewPermissionsFieldName].userIds;
  return {
    viewUserIds,
    loading,
    error
  };
};