import { useReducer, useCallback } from 'react';
import { FINISHED } from '../hooks/constants';
const SHOW_RESULT = 'SHOW_RESULT';
const INCREMENT_CLICK_COUNT = 'INCREMENT_CLICK_COUNT';
const SET_GENERATED_CONTENT = 'SET_GENERATED_CONTENT';
const SET_INPUT = 'SET_INPUT';
const initialState = {
  regenerateClickCount: 0,
  // max clicks is 5
  generationInput: '',
  generatedContent: null,
  allResults: [],
  selectedResultIndex: 0
};
const reducer = (state, action) => {
  const {
    type,
    payload
  } = action;
  switch (type) {
    case SET_INPUT:
      return Object.assign({}, state, {
        generationInput: payload
      });
    case INCREMENT_CLICK_COUNT:
      return Object.assign({}, state, {
        regenerateClickCount: state.regenerateClickCount + 1
      });
    case FINISHED:
      return Object.assign({}, state, {
        allResults: [...state.allResults, ...payload.response],
        // Reset index, and content shown in preview
        selectedResultIndex: state.allResults.length + payload.response.length - 1,
        generatedContent: payload.generatedContent
      });
    case SET_GENERATED_CONTENT:
      return Object.assign({}, state, {
        generatedContent: payload.generatedContent
      });
    case SHOW_RESULT:
      return Object.assign({}, state, {
        selectedResultIndex: payload.selectedResultIndex,
        generatedContent: state.allResults[payload.selectedResultIndex]
      });
    default:
      throw new Error('Unexpected action type');
  }
};
const useInlineAutocompleteMenuState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setGeneratedContent = useCallback(generatedContent => {
    dispatch({
      type: SET_GENERATED_CONTENT,
      payload: {
        generatedContent
      }
    });
  }, []);
  const setInput = useCallback(value => {
    dispatch({
      type: SET_INPUT,
      payload: value
    });
  }, []);
  const onSuccess = useCallback(response => dispatch({
    type: FINISHED,
    payload: {
      response,
      generatedContent: response[0]
    }
  }), []);
  const incrementCount = useCallback(() => dispatch({
    type: INCREMENT_CLICK_COUNT
  }), []);
  const setShowResult = useCallback(selectedResultIndex => dispatch({
    type: SHOW_RESULT,
    payload: {
      selectedResultIndex
    }
  }), []);
  return {
    state,
    actions: {
      onSuccess,
      setGeneratedContent,
      incrementCount,
      setShowResult,
      setInput
    }
  };
};
export default useInlineAutocompleteMenuState;