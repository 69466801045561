import { useReducer } from 'react';
import { FINISHED } from '../hooks/constants';
const SET_HOVERED_COMMAND = 'SET_HOVERED_COMMAND';
const SET_SELECTED_COMMAND = 'SET_SELECTED_COMMAND';
const SHOW_RESULT = 'SHOW_RESULT';
const INCREMENT_CLICK_COUNT = 'INCREMENT_CLICK_COUNT';
const SET_GENERATED_CONTENT = 'SET_GENERATED_CONTENT';
const PRESERVE_EXTRA_PARAMS = 'PRESERVE_EXTRA_PARAMS';
const initialState = {
  regenerateClickCount: 0,
  // max clicks is 5
  selectedCommand: null,
  hoveredCommand: null,
  generatedContent: null,
  allResults: [],
  selectedResultIndex: 0,
  previousExtraParams: {}
};
const reducer = (state, action) => {
  const {
    type,
    payload
  } = action;
  switch (type) {
    case INCREMENT_CLICK_COUNT:
      return Object.assign({}, state, {
        regenerateClickCount: state.regenerateClickCount + 1
      });
    case FINISHED:
      return Object.assign({}, state, {
        allResults: [...state.allResults, ...payload.response],
        // Reset index, and content shown in preview
        selectedResultIndex: state.allResults.length + payload.response.length - 1,
        generatedContent: payload.generatedContent
      });
    case SET_GENERATED_CONTENT:
      return Object.assign({}, state, {
        generatedContent: payload.generatedContent
      });
    case SHOW_RESULT:
      return Object.assign({}, state, {
        selectedResultIndex: payload.selectedResultIndex,
        generatedContent: state.allResults[payload.selectedResultIndex]
      });
    case SET_SELECTED_COMMAND:
      return Object.assign({}, state, {
        selectedCommand: payload.selectedCommand
      });
    case SET_HOVERED_COMMAND:
      return Object.assign({}, state, {
        hoveredCommand: payload.hoveredCommand
      });
    case PRESERVE_EXTRA_PARAMS:
      return Object.assign({}, state, {
        previousExtraParams: payload.extraParams
      });
    default:
      console.error('Unexpected action type ', type);
      return state;
  }
};
export const useInlineToolbarState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // TODO: Wrap these in `useCallback`s
  const setGeneratedContent = generatedContent => {
    dispatch({
      type: SET_GENERATED_CONTENT,
      payload: {
        generatedContent
      }
    });
  };
  const onSuccess = response => dispatch({
    type: FINISHED,
    payload: {
      response,
      generatedContent: response[0]
    }
  });
  const setSelectedCommand = command => dispatch({
    type: SET_SELECTED_COMMAND,
    payload: {
      selectedCommand: command
    }
  });
  const incrementCount = () => dispatch({
    type: INCREMENT_CLICK_COUNT
  });
  const setHoveredCommand = command => dispatch({
    type: SET_HOVERED_COMMAND,
    payload: {
      hoveredCommand: command
    }
  });
  const setShowResult = selectedResultIndex => dispatch({
    type: SHOW_RESULT,
    payload: {
      selectedResultIndex
    }
  });
  const setExtraParams = extraParams => dispatch({
    type: PRESERVE_EXTRA_PARAMS,
    payload: {
      extraParams
    }
  });
  return {
    state,
    actions: {
      onSuccess,
      setGeneratedContent,
      setSelectedCommand,
      setHoveredCommand,
      incrementCount,
      setShowResult,
      setExtraParams
    }
  };
};