'use es6';

import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap } from 'immutable';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';

/**
 * Note: Using SalesContentSearch because it enforces Object-level permissions
 * while CRM Search does not.
 */

const formatSequences = formatToReferencesList({
  getId: get('contentId'),
  getLabel: get('name')
});
export const createGetSequencesWithPermissionsByIds = ({
  httpClient
}) => (ids = []) => httpClient.post('/salescontentsearch/v2/search', {
  data: {
    filters: [{
      field: 'content_type',
      values: ['SEQUENCE']
    }, {
      field: 'content_id',
      values: ids
    }],
    limit: ids.length,
    query: '',
    sorts: [{
      field: 'name',
      order: 'ASC'
    }]
  }
}).then(({
  results
}) => formatSequences(results));
export const getSequencesWithPermissionsByIds = createGetSequencesWithPermissionsByIds({
  httpClient: http
});
export const createGetSequencesWithPermissionsSearchPage = ({
  httpClient
}) => opts => httpClient.post('/salescontentsearch/v2/search', {
  data: {
    filters: [{
      field: 'content_type',
      values: ['SEQUENCE']
    }],
    limit: get('count', opts) || 100,
    offset: get('offset', opts) || 0,
    query: get('query', opts) || '',
    sorts: [{
      field: 'name',
      order: 'ASC'
    }]
  }
}).then(({
  hasMore,
  offset,
  results = []
}) => ImmutableMap({
  count: results.length,
  hasMore,
  offset,
  results: formatSequences(results)
}));
export const getSequencesWithPermissionsSearchPage = createGetSequencesWithPermissionsSearchPage({
  httpClient: http
});