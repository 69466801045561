import { createReducer } from '@reduxjs/toolkit';
import { deleteTask } from '../actions/tasksCRUD';
import { resetTemporarilyExcludedTaskIds, temporarilyExcludeTaskIds } from '../actions/temporarilyExcludeIds';
const defaultState = [];
const mergeWithoutDuplicates = (arrayOne, arrayTwo) => {
  return Array.from(new Set([...arrayOne, ...arrayTwo]));
};
export const temporarilyExcludeIds = createReducer(defaultState, builder => builder.addCase(temporarilyExcludeTaskIds, (state, action) => {
  return mergeWithoutDuplicates(action.payload.taskIds, state);
}).addCase(deleteTask.fulfilled, (state, action) => {
  if (action.payload && action.payload.activeViewId) {
    return mergeWithoutDuplicates([action.payload.taskId], state);
  }
  return state;
}).addCase(resetTemporarilyExcludedTaskIds, () => {
  return defaultState;
}));