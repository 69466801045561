import { toggleMark } from 'prosemirror-commands';
// https://github.com/ProseMirror/prosemirror-example-setup/blob/afbc42a68803a57af3f29dd93c3c522c30ea3ed6/src/menu.js#L57-L61
export function isMarkActive(state, mark) {
  const {
    from,
    $from,
    to,
    empty
  } = state.selection;
  return empty ? !!mark.isInSet(state.storedMarks || $from.marks()) : state.doc.rangeHasMark(from, to, mark);
}

/**
 * Locates a mark of the given type in the editor and returns it if it's active
 *
 * A mark is considered "active" in the following cases:
 *
 *   1. The mark is in the current stored marks of the editor state
 *   2. The mark is applied to the node under the cursor
 *   3. The mark is applied to any nodes inside the selection
 *
 * @param state Editor state
 * @param markType MarkType to Test
 * @returns Mark instance if the Mark is active, undefined no such Mark was found
 */
export function getActiveMark(state, markType) {
  const {
    $from,
    empty,
    $head,
    $anchor
  } = state.selection;
  let foundMark = [];
  if (empty) {
    foundMark = state.storedMarks || $from.marks();
  } else if ($head.marks().length > 0) {
    foundMark = $head.marks();
  } else if ($anchor.marks().length > 0) {
    foundMark = $anchor.marks();
  } else {
    const contentFragment = state.selection.content().content;
    contentFragment.nodesBetween(0, contentFragment.size, node => {
      if (foundMark.length === 0) {
        if (node.marks.length > 0) {
          foundMark = node.marks;
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
  }
  return markType.isInSet(foundMark);
}
export function toggleMarkCommand(getMark, attrs) {
  return (state, dispatch) => toggleMark(getMark(state.schema), attrs)(state, dispatch);
}
export function setMarkCommand(getMark, attrs) {
  return (state, dispatch) => setMark(getMark(state.schema), attrs)(state, dispatch);
}
export function clearMarkCommand(getMark) {
  return (state, dispatch) => clearMark(getMark(state.schema))(state, dispatch);
}

/**
 * Remove a formatting mark from the selection or cursor state
 *
 * If the mark type does not exist on the selection, this is a no-op. If there is
 * no selection, then the mark will be removed from storedMarks if needed.
 *
 * @param markType The type of mark to remove from the current selection
 * @returns true if the command applies, false if it does not
 */
export function clearMark(markType) {
  return function (state, dispatch) {
    const {
      empty,
      $cursor,
      ranges
    } = state.selection;
    if (empty && !$cursor || !markApplies(state.doc, ranges, markType)) return false;
    if (dispatch) {
      if ($cursor) {
        if (markType.isInSet(state.storedMarks || $cursor.marks())) {
          dispatch(state.tr.removeStoredMark(markType));
        }
      } else {
        const tr = state.tr;
        for (const {
          $from,
          $to
        } of ranges) {
          if (state.doc.rangeHasMark($from.pos, $to.pos, markType)) {
            tr.removeMark($from.pos, $to.pos, markType);
          }
        }
        dispatch(tr.scrollIntoView());
      }
    }
    return true;
  };
}

/**
 * Apply a formatting mark to the current selection or the cursor state
 *
 * The mark type and attributes are applied to all text spans that intersect the
 * current selection, or to the cursor if there is no selection. Any conflicting marks
 * are removed before the new mark is added.
 *
 * This command will ensure there is no leading or trailing whitespace in the mark
 * when inserted.
 *
 * @param markType Type of mark to apply to the cursor or selection
 * @param attrs Attributes to assign to the mark (color, font style, etc.)
 * @returns True if the command applies, false if it does not
 */
export function setMark(markType, attrs) {
  return function (state, dispatch) {
    const {
      empty,
      $cursor,
      ranges
    } = state.selection;
    if (empty && !$cursor || !markApplies(state.doc, ranges, markType)) return false;
    if (dispatch) {
      if ($cursor) {
        if (markType.isInSet(state.storedMarks || $cursor.marks())) {
          dispatch(state.tr.removeStoredMark(markType));
          dispatch(state.tr.addStoredMark(markType.create(attrs)));
        } else {
          dispatch(state.tr.addStoredMark(markType.create(attrs)));
        }
      } else {
        let has = false;
        const tr = state.tr;
        for (let i = 0; !has && i < ranges.length; i++) {
          const {
            $from,
            $to
          } = ranges[i];
          has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
        }
        for (let i = 0; i < ranges.length; i++) {
          const {
            $from,
            $to
          } = ranges[i];
          if (has) {
            tr.removeMark($from.pos, $to.pos, markType);
            tr.addMark($from.pos, $to.pos, markType.create(attrs));
          } else {
            let from = $from.pos;
            let to = $to.pos;
            const start = $from.nodeAfter;
            const end = $to.nodeBefore;
            const spaceStart = start && start.isText ? /^\s*/.exec(start.text)[0].length : 0;
            const spaceEnd = end && end.isText ? /\s*$/.exec(end.text)[0].length : 0;
            if (from + spaceStart < to) {
              from += spaceStart;
              to -= spaceEnd;
            }
            tr.addMark(from, to, markType.create(attrs));
          }
        }
        dispatch(tr.scrollIntoView());
      }
    }
    return true;
  };
}

/**
 * Test if a mark can be applied to a text selection
 *
 * If any node inside the selection allows the given mark, then this function returns
 * true. Otherwise, this function returns false. Uses the schema of the given document
 * or Node to determine valid marks.
 *
 * @param doc Document node for checking the schema
 * @param ranges A selection to test
 * @param type Type of mark to test
 * @returns true if the selection allows this mark, false if it does not
 */
function markApplies(doc, ranges, type) {
  for (let i = 0; i < ranges.length; i++) {
    const {
      $from,
      $to
    } = ranges[i];
    let can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
    doc.nodesBetween($from.pos, $to.pos, node => {
      if (can) return false;
      can = node.inlineContent && node.type.allowsMarkType(type);
      return true;
    });
    if (can) return true;
  }
  return false;
}

/**
 * Locates the beginning and end of a Mark that intersects with a given Cursor position
 *
 * @param $start Cursor position
 * @param mark Type of mark to search for
 * @returns start and end positions of the Mark
 */
export function getMarkRange($start, mark) {
  let startIndex = $start.index();
  let endIndex = $start.indexAfter();
  while (startIndex > 0 && mark.isInSet($start.parent.child(startIndex - 1).marks)) startIndex--;
  while (endIndex < $start.parent.childCount && mark.isInSet($start.parent.child(endIndex).marks)) endIndex++;
  let startPos = $start.start();
  let endPos = startPos;
  for (let i = 0; i < endIndex; i++) {
    const size = $start.parent.child(i).nodeSize;
    if (i < startIndex) startPos += size;
    endPos += size;
  }
  return {
    from: startPos,
    to: endPos
  };
}