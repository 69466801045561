import getLang from 'I18n/utils/getLang';
import Superstore, { IndexedDB } from 'superstore';
import toJS from 'transmute/toJS';
export const defaultNamespace = 'crm-tasks-ui-cache';
let client = null;
export const getSuperstoreClient = (namespace = defaultNamespace) => {
  if (!client) {
    client = new Superstore({
      backend: IndexedDB,
      namespace,
      // We currently are only using the superstore to cache property definitions, so makes sense to partition based on locale rather than portal ID for now
      partition: getLang
    }).open();
  }
  return client;
};
export const getSuperstoreKey = keyPart => `${defaultNamespace}:${keyPart}`;
export const getSuperstoreCachedValue = key => {
  return getSuperstoreClient().then(store => {
    return store.get(key);
  }).catch(() => {
    // ignore
  });
};
export const setSuperstoreCachedValue = (key, data) => {
  return getSuperstoreClient().then(store => {
    return store.set(key, toJS(data)).catch(() => {
      // Some part of the cache write failed. It doesn't really matter what failed,
      // regardless we want to blow away cache so it's clear for next fetch
      return store.delete(key);
    }).catch(() => {
      // ignore. on systems with IndexedDB disabled `store.delete` can fail,
      // but there's nothing we can do about it, so just ignore the error
    });
  });
};