'use es6';

import { AssociationsBatchRequestClient } from './AssociationsBatchRequestClient';
import { CrmObjectPreviewBatchRequestClient } from './CrmObjectPreviewBatchRequestClient';
import allSettled from 'hs-promise-utils/allSettled';
export const AssociationsPreviewClient = {
  fetch: ({
    objectIds,
    associationDefinition: {
      associationCategory,
      associationTypeId,
      toObjectTypeId
    }
  }) => allSettled(objectIds.map(objectId => AssociationsBatchRequestClient.fetch({
    associationCategory,
    associationTypeId,
    objectId
  }).then(associatedObjectIds => allSettled(associatedObjectIds.results.map(associatedObjectId => CrmObjectPreviewBatchRequestClient.fetch({
    objectTypeId: toObjectTypeId,
    objectId: associatedObjectId
  }))).then(results => results.filter(({
    status
  }) => status === 'fulfilled').map(({
    value
  }) => value))))).then(results => results.reduce((acc, result, index) => {
    acc[objectIds[index]] = result.status === 'fulfilled' ? result.value : [];
    return acc;
  }, {}))
};