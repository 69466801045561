export const DEFAULT_BULK_ACTIONS_EDIT_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_EDIT_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_ENRICH_RECORDS_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_ENRICH_RECORDS_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_DELETE_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_DELETE_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_ASSIGN_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_ASSIGN_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_SHARE_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_SHARE_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_BET_RECYCLE_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_BET_RECYCLE_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_BET_MOVE_TO_CLOSED_LOST_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_BET_MOVE_TO_CLOSED_LOST_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_DELETE_INVOICES_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_DELETE_INVOICES_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_CREATE_TASKS_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_CREATE_TASKS_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_COMPLETE_TASKS_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_COMPLETE_TASKS_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_DELETE_TASKS_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_DELETE_TASKS_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_EDIT_TASKS_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_EDIT_TASKS_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_SET_MARKETABLE_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_SET_MARKETABLE_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_SET_NON_MARKETABLE_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_SET_NON_MARKETABLE_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_ENROLL_IN_WORKFLOW_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_ENROLL_IN_WORKFLOW_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_BET_ASSIGN_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_BET_ASSIGN_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_ADD_TO_OBJECT_LIST_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_ADD_TO_OBJECT_LIST_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_REMOVE_FROM_OBJECT_LIST_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_REMOVE_FROM_OBJECT_LIST_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_ENROLL_IN_SEQUENCE_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_ENROLL_IN_SEQUENCE_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_UPDATE_DOUBLE_OPT_IN_KEY = 'DEFAULT_BULK_ACTIONS_UPDATE_DOUBLE_OPT_IN_KEY';
export const DEFAULT_BULK_ACTIONS_ADD_LEGAL_BASIS_KEY = 'DEFAULT_BULK_ACTIONS_ADD_LEGAL_BASIS_KEY';
export const DEFAULT_BULK_ACTIONS_EDIT_COMMUNICATION_SUBSCRIPTIONS_KEY = 'DEFAULT_BULK_ACTIONS_EDIT_COMMUNICATION_SUBSCRIPTIONS_KEY';
export const DEFAULT_BULK_ACTIONS_SURVEYMONKEY_SEND_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_SURVEYMONKEY_SEND_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_EDIT_CAMPAIGNS_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_EDIT_CAMPAIGNS_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_CREATE_LEADS_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_CREATE_LEADS_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_ADD_TO_PLAYLIST_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_ADD_TO_PLAYLIST_MODAL_KEY';
export const DEFAULT_BULK_ACTIONS_ASSIGN_TO_AGENT_MODAL_KEY = 'DEFAULT_BULK_ACTIONS_ASSIGN_TO_AGENT_MODAL_KEY';