import { validateStorageShape } from './validateStorageShape';
import { QUEUE_BAR_BASE_CONFIG } from '../TaskQueueBar/reducers/taskQueueBarReducer';
import { getSessionStorageKey } from '../TaskQueueBar/utils/getSessionStorageKey';
function crmSearchResultsToTaskIds(results) {
  return results.map(result => result.objectId);
}
function insertTaskInFront(taskIds, firstTaskId) {
  if (firstTaskId) {
    const filteredTaskIds = taskIds.filter(taskId => taskId !== firstTaskId);
    return [firstTaskId, ...filteredTaskIds];
  }
  return taskIds;
}
export const createTaskQueueConfig = storage => ({
  crmSearchQuery,
  crmSearchResponse,
  firstTaskId,
  pageSize,
  queueType,
  redirectUrlForExitQueue,
  title
}) => {
  validateStorageShape(storage);
  const {
    total,
    results
  } = crmSearchResponse;
  const taskIds = insertTaskInFront(crmSearchResultsToTaskIds(results), firstTaskId);
  const currentTaskId = taskIds.length ? taskIds[0] : null;
  const currentTask = results.find(task => task.objectId === currentTaskId);
  const storageKey = getSessionStorageKey();

  // there were no results to start the queue from, make sure we don't have
  // state left over from a previous queue session
  if (currentTaskId === null || total === 0 || taskIds.length === 0) {
    storage.removeItem(storageKey);
    return null;
  }
  const config = Object.assign({}, QUEUE_BAR_BASE_CONFIG, {
    crmSearchQuery,
    currentTask,
    currentTaskId,
    pageSize,
    queueProgressIndex: 0,
    queueType,
    redirectUrl: redirectUrlForExitQueue,
    taskIds,
    title,
    total
  });
  storage.setItem(storageKey, JSON.stringify(config));
  return config;
};