import { TASK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import http from 'hub-http/clients/apiClient';
import update from 'transmute/update';
import * as ViewIdMapping from '../constants/ViewIdMapping';
import { MAX_PINNED_VIEWS, VIEW_CATEGORY_PAGE_SIZE } from '../constants/views';
import { formatViewForSave, parseView } from '../formatters/views';
export const TASKS_NAMESPACE = 'TASKS';
export const getViewCategories = ({
  categories,
  offset = 0,
  count = VIEW_CATEGORY_PAGE_SIZE,
  searchQuery = ''
}) => http.post(`sales/v3/views/by-category/search/${TASK_TYPE_ID}`, {
  data: {
    count,
    offset,
    query: searchQuery,
    categories
  },
  query: {
    namespace: TASKS_NAMESPACE
  }
}).then(viewsByCategory => {
  const parsedViews = {};
  Object.keys(viewsByCategory).forEach(category => {
    Object.assign(parsedViews, {
      [category]: Object.assign({}, viewsByCategory[category], {
        results: viewsByCategory[category].results.map(parseView)
      })
    });
  });
  return parsedViews;
});
export function getViewById({
  viewId
}) {
  return http.get(`/sales/v3/views/${TASK_TYPE_ID}/${viewId}`, {
    query: {
      namespace: TASKS_NAMESPACE
    }
  }).then(parseView);
}
export const getViewPermissionsById = ({
  viewId
}) => {
  return http.get(`sales/v3/views/permissions/${viewId}`);
};
export const getPinnedViewDefinitions = () => http.get(`sales/v3/views/${TASK_TYPE_ID}/pinned/view`, {
  query: {
    namespace: TASKS_NAMESPACE,
    count: MAX_PINNED_VIEWS
  }
}).then(response => {
  const pinnedViews = update('results', results => results.map(parseView), response);
  return pinnedViews;
});
export const putPinnedViews = ({
  allPinnedViewDefinitions
}) => {
  const allPinnedViewIds = allPinnedViewDefinitions.map((viewDefinition // TODO: Remove ViewIdMapping after the backend supports string ids for default views
  // @ts-expect-error ignore that ID may be number or not in mapping
  ) => ViewIdMapping.get(viewDefinition.id));
  return http.put(`sales/v3/views/${TASK_TYPE_ID}/pinned`, {
    data: allPinnedViewIds,
    query: {
      namespace: TASKS_NAMESPACE
    }
  })
  // return the pinned view ids we just set so the apollo mutation can apply them to the cache
  .then(() => allPinnedViewDefinitions);
};
export const createView = ({
  view
}) => {
  return http.post(`sales/v3/views`, {
    data: formatViewForSave(view),
    query: {
      namespace: TASKS_NAMESPACE
    }
  }).then(parseView);
};
export const deleteView = ({
  id
}) => http.delete(`sales/v3/views/${id}`, {
  query: {
    namespace: TASKS_NAMESPACE
  }
});
export const updateView = ({
  view
}) => {
  return http.put(`sales/v3/views/${view.id}`, {
    data: formatViewForSave(view),
    query: {
      namespace: TASKS_NAMESPACE
    }
  }).then(parseView);
};

/**
 * Override certain parts of the default view definition for that user in a specific portal.
 * The changes will apply to all default views. We only allow users to override the columns
 * in default views.
 *
 * Currently these overrides are stored in user attributes, but this will be changed at some point.
 * We don't need to manually fetch this user attribute; it's handled by backend when fetching default
 * view definitions
 * https://hubspot.slack.com/archives/C028JC2EF7U/p1644438631178549
 * @param {Object} input object containing view field, the definition to
 * @returns
 */
export const updateDefaultView = ({
  view
}) => {
  const formattedView = formatViewForSave(view);
  return http.put(`sales/v3/views/default-view`, {
    data: {
      // @ts-expect-error ignore that ID may not be in mapping
      id: ViewIdMapping.get(formattedView.id),
      columns: formattedView.columns,
      objectTypeId: TASK_TYPE_ID
    },
    query: {
      namespace: TASKS_NAMESPACE
    }
  });
};

/**
 * See https://hubspot.slack.com/archives/C028JC2EF7U/p1630680408005400?thread_ts=1630039484.029600&cid=C028JC2EF7U
 * for additional context on this endpoint
 * @returns view counts for all applicable object type IDs
 */
export const getViewCountsByTypeId = () => http.get('sales/v3/views/counts/by-object-type-id/by-namespace', {
  query: {
    namespace: TASKS_NAMESPACE
  }
});

/**
 * Returns whether there is an existing view with the name
 * Note that this endpoint currently does not check against default view names
 * @param {Object} input in { name: ... } format
 * @returns
 */
export const getIsNameUsed = ({
  name
}) => http.get(`sales/v3/views/is-name-used/${TASK_TYPE_ID}/${name}`, {
  query: {
    namespace: TASKS_NAMESPACE
  }
});