'use es6';

import { registerMutation, registerQuery, useMutation, useQuery } from 'data-fetching-client';
import { useCallback, useMemo, useState } from 'react';
import getIn from 'transmute/getIn';
import { noop } from '../utils/noop';
import { getPinnedViewDefinitions, putPinnedViews } from '../api/views';
import { reportAndDisplayError, GROUP_NAMES } from '../tracking/errors';
export const pinnedViewsFieldName = 'pinnedViews';
export const pinnedViewsQuery = registerQuery({
  fieldName: pinnedViewsFieldName,
  fetcher: getPinnedViewDefinitions
});
export const SET_PINNED_VIEWS_MUTATION = registerMutation({
  fieldName: pinnedViewsFieldName,
  fetcher: putPinnedViews
});
export const usePinnedViews = ({
  onToggleCompleted = noop,
  onReorderCompleted = noop
} = {}) => {
  // using this to determine whether to track pinned or unpinned event
  // seems like the best way because it's kind of a circular logic tree
  const [isPinningView, setIsPinningView] = useState(false);
  const {
    data,
    loading
  } = useQuery(pinnedViewsQuery, {
    notifyOnNetworkStatusChange: true // allows loading state to be updated on refetches
  });
  const pinnedViews = useMemo(() => {
    return getIn([pinnedViewsFieldName, 'results'], data) || [];
  }, [data]);
  const [setPinnedViewsMutation] = useMutation(SET_PINNED_VIEWS_MUTATION, {
    onCompleted() {
      onToggleCompleted(isPinningView);
      onReorderCompleted(data);
    },
    update(cache, {
      data: {
        pinnedViews: updatedPinnedViewDefinitions
      }
    }) {
      cache.writeQuery({
        query: pinnedViewsQuery,
        data: {
          pinnedViews: {
            count: updatedPinnedViewDefinitions.length,
            results: updatedPinnedViewDefinitions
          }
        }
      });
    },
    onError: error => {
      reportAndDisplayError(error, {
        messageI18nKey: 'tasksAlerts.views.pinViewError'
      }, {
        groupName: GROUP_NAMES.TASK_VIEWS,
        functionName: 'toggleViewPinned',
        extra: {
          pinnedViews
        }
      });
    }
  });
  const toggleViewPinned = useCallback(selectedViewDefinition => {
    const viewAlreadyPinned = !!pinnedViews.find(view => view.id === selectedViewDefinition.id);
    setIsPinningView(!viewAlreadyPinned);
    const newPinnedViews = viewAlreadyPinned ? pinnedViews.filter(view => view.id !== selectedViewDefinition.id) : [...pinnedViews, selectedViewDefinition];
    setPinnedViewsMutation({
      variables: {
        allPinnedViewDefinitions: newPinnedViews
      }
    });
  }, [setPinnedViewsMutation, pinnedViews, setIsPinningView]);
  const setDefaultView = useCallback(newDefaultView => {
    const newPinnedViews = [newDefaultView, ...pinnedViews.filter(view => view.id !== newDefaultView.id)];
    setPinnedViewsMutation({
      variables: {
        allPinnedViewDefinitions: newPinnedViews
      }
    });
  }, [pinnedViews, setPinnedViewsMutation]);

  // This should be called with an array of views
  const reorderViews = useCallback(reorderedViews => {
    setPinnedViewsMutation({
      variables: {
        allPinnedViewDefinitions: reorderedViews
      }
    });
  }, [setPinnedViewsMutation]);
  return {
    pinnedViews,
    setDefaultView,
    toggleViewPinned,
    reorderViews,
    pinnedViewsLoading: loading
  };
};