'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["fetchOptions", "httpClient", "cacheKey"];
import { TASK_LAST_TOUCH, TASK_LAST_ENGAGEMENT } from 'reference-resolvers/constants/CacheKeys';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { createGetHydratedPropertiesByObjectId } from 'reference-resolvers/api/ABMPropertyHydrationAPI';
import { SUPPORTED_OBJECT_TYPES, SUPPORTED_PROPERTIES } from '../api/ABMPropertyHydrationAPI';
export const createABMPropertyHydrationReferenceResolver = options => {
  const {
      fetchOptions,
      httpClient,
      cacheKey
    } = options,
    otherOptions = _objectWithoutPropertiesLoose(options, _excluded);
  return createBatchedReferenceResolver(Object.assign({
    // you have to pass in cacheKey via options
    cacheKey,
    createFetchByIds: args => createGetHydratedPropertiesByObjectId(Object.assign({
      fetchOptions
    }, args)),
    fetchByIds: createGetHydratedPropertiesByObjectId({
      httpClient,
      fetchOptions
    }),
    httpClient
  }, otherOptions));
};
export const TaskLastTouchPropertyReferenceResolver = createABMPropertyHydrationReferenceResolver({
  cacheKey: TASK_LAST_TOUCH,
  fetchOptions: {
    objectType: SUPPORTED_OBJECT_TYPES.TASK,
    property: SUPPORTED_PROPERTIES.LAST_TOUCH
  }
});
export const TaskLastEngagementPropertyReferenceResolver = createABMPropertyHydrationReferenceResolver({
  cacheKey: TASK_LAST_ENGAGEMENT,
  fetchOptions: {
    objectType: SUPPORTED_OBJECT_TYPES.TASK,
    property: SUPPORTED_PROPERTIES.LAST_ENGAGEMENT
  }
});