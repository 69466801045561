'use es6';

import ScopesContainer from '../containers/ScopesContainer';
export const setupScopesContainer = auth => {
  const {
    user
  } = auth;
  const scopes = user.scopes.reduce((acc, scope) => {
    acc[scope] = true;
    return acc;
  }, {});
  ScopesContainer.set(scopes);
};