// @ts-expect-error no declaration file
import { emojiIndex } from 'emoji-mart';
import Raven from 'raven-js';
import { createSuggestionPlugin } from './index';
const skinToneBaseRe = new RegExp('\\p{Emoji_Modifier_Base}', 'gu');
const allowedSearchRe = /^[\w-+]+$/;
const getSkinTone = () => {
  const LOCAL_STORAGE_KEY = 'emoji-mart.skin';
  const skinTones = new Map([['1', ''], ['2', '\u{1f3fb}'], ['3', '\u{1f3fc}'], ['4', '\u{1f3fd}'], ['5', '\u{1f3fe}'], ['6', '\u{1f3ff}']]);
  try {
    const tone = localStorage.getItem(LOCAL_STORAGE_KEY) || '1';
    return skinTones.get(tone) || '';
  } catch (error) {
    Raven.captureException(error, {
      extra: {
        LOCAL_STORAGE_KEY
      }
    });
    return '';
  }
};
export const EmojiPlugin = createSuggestionPlugin({
  allowSpace: false,
  getSuggestions(text, callback) {
    if (!allowedSearchRe.test(text)) {
      callback([]);
      return;
    }
    const selectedSkinTone = getSkinTone();
    const results = emojiIndex.search(text).map(emoji => applySkinTone(emoji, selectedSkinTone)).map(e => {
      return {
        // @ts-expect-error id doesn't really need to be a number
        id: e.id,
        searchText: `${e.native} ${e.id}`,
        text: e.native
      };
    });
    callback(results);
  },
  type: 'EMOJI'
});
export function applySkinTone(emoji, selectedSkinTone) {
  const matches = emoji.native.match(skinToneBaseRe);
  return matches && matches.length === 1 ? Object.assign({}, emoji, {
    native: emoji.native.replace(matches[0], matches[0] + selectedSkinTone)
  }) : emoji;
}