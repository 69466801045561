'use es6';

const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { useQuery, gql } from '@apollo/client';
import { TASK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { AssociationDefinitionKeyFields } from 'hubspot-apollo-client/fragments';
export const allAssocationDefinitionsFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"AllAssociationDefinitionsFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"AssociationDefinition\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"AssociationDefinitionKeyFields\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationCategory\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"associationTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"fromObjectTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"label\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"toObjectTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"toObjectTypeDefinition\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"singularForm\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"pluralForm\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"secondaryDisplayLabelPropertyNames\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"primaryDisplayLabelPropertyName\"}}]}}]}}]}", {
  kind: "Document",
  definitions: _unique([{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "AllAssociationDefinitionsFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "AssociationDefinition"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "FragmentSpread",
        name: {
          kind: "Name",
          value: "AssociationDefinitionKeyFields"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "associationCategory"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "associationTypeId"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "fromObjectTypeId"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "label"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "toObjectTypeId"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "toObjectTypeDefinition"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "singularForm"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "pluralForm"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "secondaryDisplayLabelPropertyNames"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "primaryDisplayLabelPropertyName"
            }
          }]
        }
      }]
    }
  }].concat(AssociationDefinitionKeyFields.definitions))
});
export const allAssociationDefinitionsQueryTemplate = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"AllAssociationDefinitions\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectType\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allAssociationTypesFromObjectType\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"objectType\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectType\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"AllAssociationDefinitionsFields\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "AllAssociationDefinitions"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectType"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "allAssociationTypesFromObjectType"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "objectType"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectType"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "AllAssociationDefinitionsFields"
            }
          }]
        }
      }]
    }
  }].concat(allAssocationDefinitionsFragment.definitions))
});
export const useAllAssociationDefinitionsQuery = () => {
  return useQuery(allAssociationDefinitionsQueryTemplate, {
    fetchPolicy: 'cache-first',
    variables: {
      objectType: TASK_TYPE_ID
    }
  });
};