'use es6';

import { useMemo } from 'react';
import { DEFAULT_USER_SETTING_COLUMNS, STATIC_COLUMNS_FOR_OPEN_VIEWS } from '../constants/table';
import { useSelector } from '../context/Redux';
import { tempViewStateSelector } from '../redux/selectors/tempViewState';
export function useTableColumns(viewId) {
  const tempViewState = useSelector(tempViewStateSelector)[viewId] || {};
  const {
    columns = []
  } = tempViewState;
  return useMemo(() => {
    const viewColumnNames = columns.map(column => column.name);
    const tableColumns = viewColumnNames.length > 0 ? viewColumnNames : DEFAULT_USER_SETTING_COLUMNS;

    // want to make sure users always show the status/title columns in whichever view they are in
    return {
      columnNames: Array.from(new Set([...STATIC_COLUMNS_FOR_OPEN_VIEWS, ...tableColumns]))
    };
  }, [columns]);
}