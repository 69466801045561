import { TASK } from 'customer-data-objects/engagement/EngagementTypes';
import { NONE } from 'customer-data-objects/engagement/taskPriorities';
import { TODO } from 'customer-data-objects/engagement/TaskTypes';
import PropertyValueRecord from 'customer-data-objects/property/PropertyValueRecord';
import { DUE_DATE, ENGAGEMENT_TYPE, NOTES, OWNER_ID, PRIORITY, QUEUE_IDS, RELATIVE_REMINDERS, REMINDERS, TITLE, TYPE } from 'customer-data-objects/task/TaskPropertyNames';
import TaskRecord from 'customer-data-objects/task/TaskRecord';
import { THREE_BUSINESS_DAYS } from '../constants/RelativeDates';
import { datePresetToMoment } from './RelativeDatePresets';
import I18n from 'I18n';
import { fromJS } from 'immutable';
import { getRemindersAsUnitAndCount } from './formatRelativeDatePresets';
const NO_REMINDER = null;
const EIGHT_HOURS_IN_MINUTES = 8 * 60;
export function getPropertyKeyValuePair(name, value) {
  if (!name || value === undefined) return null;
  return {
    [name]: PropertyValueRecord({
      name,
      value
    })
  };
}
export function getDefaultDueDate(defaultDueDatePreset = THREE_BUSINESS_DAYS, defaultDueTime = EIGHT_HOURS_IN_MINUTES) {
  return datePresetToMoment(defaultDueDatePreset || THREE_BUSINESS_DAYS).add(defaultDueTime || EIGHT_HOURS_IN_MINUTES, 'minutes').valueOf();
}
export function getDefaultReminder(defaultReminderPreset, dueDate = getDefaultDueDate()) {
  if (!defaultReminderPreset) {
    return NO_REMINDER;
  }
  return datePresetToMoment(defaultReminderPreset, I18n.moment.userTz(dueDate)).valueOf();
}
export function getDefaultTask({
  ownerId,
  queueId,
  timestamp = getDefaultDueDate(),
  relativeReminder,
  reminder = getDefaultReminder(),
  subject,
  body
}) {
  if (reminder && !relativeReminder) {
    relativeReminder = getRemindersAsUnitAndCount(timestamp, [reminder]);
  }
  return TaskRecord(fromJS({
    properties: Object.assign({}, getPropertyKeyValuePair(TITLE, subject), getPropertyKeyValuePair(NOTES, body), getPropertyKeyValuePair(ENGAGEMENT_TYPE, TASK), getPropertyKeyValuePair(OWNER_ID, ownerId), getPropertyKeyValuePair(TYPE, TODO), getPropertyKeyValuePair(PRIORITY, NONE), getPropertyKeyValuePair(DUE_DATE, timestamp), getPropertyKeyValuePair(REMINDERS, reminder), getPropertyKeyValuePair(QUEUE_IDS, queueId), getPropertyKeyValuePair(RELATIVE_REMINDERS, relativeReminder), getPropertyKeyValuePair('hs_task_is_all_day', false))
  }));
}