import { useEffect, useState, useMemo } from 'react';
// @ts-expect-error untyped
import { useSelector } from '../context/Redux';
import { userSelector } from '../redux/selectors/auth';
import { fetchSecondaryTeamIdsOnce } from '../api/teams';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
export const getPrimaryTeamIds = teams => {
  return teams.map(({
    teammates
  }) => teammates.map(String)).reduce((accumulator, team) => {
    return [...accumulator, ...team];
  }, []);
};
export function useTeammateUserIds() {
  const {
    teams: primaryTeamsInformation
  } = useSelector(userSelector);
  const [secondaryTeammateIds, setSecondaryTeammateIds] = useState([]);
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchSecondaryTeamIdsOnce().then(ids => {
      setSecondaryTeammateIds(ids);
      setIsLoading(false);
    }).catch(rethrowError);
  }, []);
  const memoizedUserTeammateIds = useMemo(() => {
    if (loading) {
      return {
        loading,
        teammateUserIds: []
      };
    }
    const currentUserPrimaryTeammateIds = getPrimaryTeamIds(primaryTeamsInformation);
    const currentUserTeammateIds = new Set([...currentUserPrimaryTeammateIds, ...secondaryTeammateIds]);
    return {
      loading,
      teammateUserIds: Array.from(currentUserTeammateIds)
    };
  }, [loading, primaryTeamsInformation, secondaryTeammateIds]);
  return memoizedUserTeammateIds;
}