// TODO: Throw these in I18n
// const CONTENT_ASSISTANCE_TIPS_KEYS = [
//   'Tip: promote your blog posts on social media to help drive traffic.',
//   // 'Tip: Did you know that it can take up to a month for Google to index a new blog? Promote your blog posts on social media to help drive traffic.',
//   'Tip: Add expert opinions or quotes to your posts to help them stand out in search.',
//   // 'Tip: When generating text, try specifying the tone you want in your output. Maybe a paragraph in a pirate voice? Aye matey!',
//   // "Tip: Not sure how to get your blog post to stand out? Add in a personal story or perspective. It's a great way to help you connect with your audience.",
// ];

const DEFAULT_CONTENT_ASSISTANCE_TIPS_KEYS = ['tips.checkContent'];
const CONTENT_ASSISTANCE_QUIPS_KEYS = ['Get yourself a treat today. You deserve it.', 'Hey, remember that time we wrote a blog post together? That was nice', 'Whoa, this post is coming together quick.'];
export function useRandomContentAssistanceTip(tips = DEFAULT_CONTENT_ASSISTANCE_TIPS_KEYS) {
  const idx = Math.floor(Math.random() * tips.length);
  return tips[idx];
}
export function useRandomContentAssistanceQuip() {
  const idx = Math.floor(Math.random() * CONTENT_ASSISTANCE_QUIPS_KEYS.length);
  return CONTENT_ASSISTANCE_QUIPS_KEYS[idx];
}
export function useRandomContentAssistanceTipOrQuip() {
  // Maybe wrap this in a useMemo later
  const tip = useRandomContentAssistanceTip();
  const quip = useRandomContentAssistanceQuip();
  const chance = Math.random();
  if (chance > 0.5) {
    return quip;
  }
  return tip;
}