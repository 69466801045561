'use es6';

import Raven from 'raven-js';

/**
 * @description
 * Utility to report errors to LogFetch
 * This is simplified version of: https://git.hubteam.com/HubSpot/conversations-error-reporting/blob/master/static/js/error-reporting/reportError.js
 *
 * @param {Object} options
 * @param {Error} options.error Error to report
 * @param {Array} options.fingerprint Sentry error fingerprint
 * @param {Object} options.tags Tags
 * @param {String} options.level Level error (default), warning, info
 */
export const logError = ({
  error,
  extraData,
  fingerprint,
  tags = {},
  level
}) => {
  Raven.captureException(error, {
    fingerprint,
    tags,
    extra: extraData,
    level
  });
  console.debug(`Error reported with '${error.message}'`, extraData);
};

/**
 * @description
 * Utility to add messages to Sentry
 *
 * @param {Object} options
 * @param {Error} options.message Message to report
 * @param {Array} options.fingerprint Sentry error fingerprint
 * @param {Object} options.tags Tags
 * @param {String} options.level Level error (default), warning, info
 */
export const logMessageToSentry = ({
  message,
  extraData,
  fingerprint,
  tags = {},
  level
}) => {
  Raven.captureMessage(message, {
    fingerprint,
    tags,
    extra: extraData,
    level
  });
};

/**
 * @description
 * Utility to report page events to LogFetch
 *
 * @param {Object} options
 * @param {string} options.key Key to log
 * @param {Object} options.tags Tags
 */
export function logPageAction({
  key,
  tags
}) {
  Raven.capturePageEvent(key, {
    extra: tags
  });
}