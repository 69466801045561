'use es6';

import { useCallback } from 'react';
import { useDispatch, useSelector } from '../context/Redux';
import { setViewOffset } from '../redux/actions/tempViewState';
import { tempViewStateSelector } from '../redux/selectors/tempViewState';
import { useSearchResultsOfView } from './tasksSearchResults';
import { getObjectTypesAndIdsFromSingleAssociationsObject } from '../utils/getObjectTypesAndIdsFromAssociations';
import { getCrmObjectKey } from '../utils/crmObjectHelpers';
import { setSortState } from '../redux/actions/tempViewState';
import { useTaskTablePageSize } from './useTaskTablePageSize';
export function useSortTable(viewId) {
  const dispatch = useDispatch();
  const tempViewState = useSelector(tempViewStateSelector)[viewId];
  const {
    sortState
  } = tempViewState;
  const handleSortTable = useCallback(({
    order,
    property
  }) => {
    dispatch(setSortState({
      sortState: {
        sortOrder: order,
        propertyName: property
      },
      viewId
    }));
  }, [viewId, dispatch]);
  return {
    sortState,
    handleSortTable
  };
}
export function usePagination(viewId) {
  const dispatch = useDispatch();
  const tempViewState = useSelector(tempViewStateSelector)[viewId];
  const {
    pageSize,
    onSetTaskTablePageSize
  } = useTaskTablePageSize();
  // there's an offset value in view and an offset value in the search result
  // the offset in the view is what you're currently on, the offset in the search result
  // is for what you should fetch next
  const {
    offset
  } = tempViewState;
  const {
    total
  } = useSearchResultsOfView(viewId);
  const pageIndex = Math.floor(offset / pageSize);
  const totalPages = Math.ceil(total / pageSize);
  const setPageIndex = useCallback(newPageIndex => {
    const newOffset = pageSize * newPageIndex;
    // this should kick off a new search
    dispatch(setViewOffset({
      viewId,
      offset: newOffset
    }));
  }, [viewId, dispatch, pageSize]);
  return {
    pageIndex,
    setPageIndex,
    totalPages,
    pageSize,
    onSetTaskTablePageSize
  };
}
export function useGetAssociatedObjects(associatedObjects) {
  const getAssociatedObjects = useCallback(taskAssociations => {
    const objectIdentifiers = getObjectTypesAndIdsFromSingleAssociationsObject(taskAssociations);
    return objectIdentifiers.filter(objectIdentifierData => associatedObjects && getCrmObjectKey(objectIdentifierData) in associatedObjects).map(objectIdentifierData => {
      const objectKey = getCrmObjectKey(objectIdentifierData);
      return associatedObjects[objectKey];
    });
  }, [associatedObjects]);
  return getAssociatedObjects;
}