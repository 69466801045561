/**
 * Makes a unique key for a search request.
 *
 * @param {string} searchId - a string that identifies the source of the search request.
 *  if the search is for a view, the searchId will be view.id (e.g. ALL_OPEN_TASKS)
 * @param {object} searchRequestOptions - An object with search request options for contact or CRM search.
 *  (filterGroups, sorts, query)
 */
export function getSearchKey(searchId, searchRequestOptions) {
  const {
    filterGroups,
    sorts,
    query,
    offset,
    count
  } = searchRequestOptions;
  const primaryKey = searchId;
  const secondaryKey = JSON.stringify({
    filterGroups,
    sorts,
    query
  });
  const tertiaryKey = JSON.stringify({
    count,
    offset
  });
  return [primaryKey, secondaryKey, tertiaryKey];
}