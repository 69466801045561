import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducers';
export function createStore(preloadedState) {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: getDefaultMiddleWare => getDefaultMiddleWare({
      // TODO Reenable to check for invalid state mutations
      immutableCheck: false,
      // Allow non-serializable data, like task records, to be used in Redux
      serializableCheck: false
    })
  });
}
export const store = createStore();