'use es6';

import getIn from 'transmute/getIn';
import get from 'transmute/get';
import has from 'transmute/has';
import ifThen from 'transmute/ifThen';
import map from 'transmute/map';
import pipe from 'transmute/pipe';
import set from 'transmute/set';
import some from 'transmute/some';
import updateIn from 'transmute/updateIn';
import { SIMPLE_TO_API_ASSOCIATION_KEYS, TASK_ASSOCIATION_SPECS } from './TaskAssociationTypes';
import { ALL_ACCESSIBLE_TEAM_IDS, COMPLETION_DATE, CREATED_DATE, DUE_DATE, IS_ALL_DAY, LAST_MODIFIED_DATE, OWNER_ID, QUEUE_IDS, REMINDERS, REPEAT_INTERVAL, SEND_DEFAULT_REMINDER } from './TaskPropertyNames';
import TaskRecord from './TaskRecord';
const taskNumberProperties = [DUE_DATE, COMPLETION_DATE, LAST_MODIFIED_DATE, CREATED_DATE, OWNER_ID];
const taskBooleanProperties = [IS_ALL_DAY, SEND_DEFAULT_REMINDER];
const taskSemicolonArrayProperties = [ALL_ACCESSIBLE_TEAM_IDS];
const taskJSONProperties = [REPEAT_INTERVAL];

// Format associations in simple format
const formatAssociationsFromResponse = responseAssociations => {
  const objectIdLists = responseAssociations.reduce((lists, association) => {
    const {
      associationType,
      toObjectId
    } = association;
    if (lists[associationType]) {
      const idList = lists[associationType];
      idList.push(toObjectId);
    } else {
      lists[associationType] = [toObjectId];
    }
    return lists;
  }, {});
  return SIMPLE_TO_API_ASSOCIATION_KEYS.map((engagementToX, simpleAssociationKey) => {
    return {
      associationSpec: {
        associationTypeId: getIn([simpleAssociationKey, 'associationTypeId'], TASK_ASSOCIATION_SPECS),
        toObjectTypeId: simpleAssociationKey
      },
      objectIds: objectIdLists[engagementToX] || []
    };
  }).toJS();
};

/**
 * array of V2 association objects { associationCategory, associationTypeId, toObjectId}
 * @param {Array} responseAssociations
 * @returns {Map<string, Object}
 * Map of bjectTypeId to { associationSpec: { associationTypeId, toObjectTypeId }, objectIds: []}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const formatAssociationsFromTaskV2Response = responseAssociations => {
  return responseAssociations.reduce((associationMap, {
    associationTypeId,
    toObjectId,
    toObjectTypeId
  }) => {
    if (get(toObjectTypeId, associationMap)) {
      const currentSimpleAssociation = get(toObjectTypeId, associationMap);
      const simpleAssociation = Object.assign({}, currentSimpleAssociation, {
        objectIds: [...currentSimpleAssociation.objectIds, toObjectId]
      });
      return set(toObjectTypeId, simpleAssociation, associationMap);
    } else {
      return set(toObjectTypeId, {
        associationSpec: {
          associationTypeId,
          toObjectTypeId
        },
        objectIds: [toObjectId]
      });
    }
  }, {}).toJS();
};
export function getTypedTaskProperty(propertyId, property) {
  let value = property.value;
  if (taskNumberProperties.includes(propertyId)) {
    value = Number(property.value);
  } else if (taskBooleanProperties.includes(propertyId)) {
    value = property.value === 'true';
  } else if (taskSemicolonArrayProperties.includes(propertyId)) {
    value = property.value ? property.value.split(';') : [];
  } else if (taskJSONProperties.includes(propertyId)) {
    try {
      value = JSON.parse(property.value);
    } catch (_) {
      value = property.value;
    }
  }
  return Object.assign({}, property, {
    name: propertyId,
    value
  });
}
function isNumberOrArray(property) {
  return typeof property.value === 'number' || Array.isArray(property.value);
}
function getFirstOrNull(arrayValue) {
  return arrayValue[0] || null;
}
function formatPropertiesFromResponse(properties) {
  if (some(isNumberOrArray, properties)) {
    // v2 format
    // eventually we may get around to storing these values as arrays on the FE but until then…
    return pipe(ifThen(has(QUEUE_IDS), updateIn([QUEUE_IDS, 'value'], getFirstOrNull)), ifThen(has(REMINDERS), updateIn([REMINDERS, 'value'], getFirstOrNull)))(properties);
  } else {
    // v1 format
    return map((property, propertyId) => {
      return getTypedTaskProperty(propertyId, property);
    }, properties);
  }
}
export function mapTaskAPIV1ResponseToTaskRecord(response) {
  const {
    objectId,
    properties: rawProperties,
    associations: rawAssociations
  } = response;
  const associations = formatAssociationsFromResponse(rawAssociations);
  const properties = formatPropertiesFromResponse(rawProperties);
  return TaskRecord.fromJS({
    objectId,
    associations,
    properties
  });
}
export function mapTaskAPIV2ResponseToTaskRecord(response) {
  const {
    objectId,
    properties: rawProperties
    // associations: rawAssociations,
  } = response;
  // TODO: re-enable this test after toObjectTypeId is added to the
  // response and we can properly format custom associations
  // const associations = formatAssociationsFromTaskV2Response(rawAssociations);
  const properties = formatPropertiesFromResponse(rawProperties);
  return TaskRecord.fromJS({
    objectId,
    // associations,
    properties
  });
}