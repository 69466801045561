import { clearTaskQueueConfig } from '../storage/clearTaskQueueConfig';
import { getTaskQueueConfig } from '../storage/getTaskQueueConfig';
import { updateTaskQueueConfig } from '../storage/updateTaskQueueConfig';
import { validateStorageShape } from '../storage/validateStorageShape';
export const setCurrentTaskSkipped = storage => () => {
  validateStorageShape(storage);
  const {
    currentTaskId,
    queueProgressIndex,
    taskIds
  } = getTaskQueueConfig(storage)(undefined);
  const oldCurrentTaskIndex = taskIds.indexOf(currentTaskId);
  // Skipping implies that there must be a next task
  const newCurrentTaskId = taskIds[oldCurrentTaskIndex + 1];

  // if there isn't a next task then we're done here
  if (!newCurrentTaskId) {
    clearTaskQueueConfig(storage);
  }
  updateTaskQueueConfig(storage)({
    currentTaskId: newCurrentTaskId,
    queueProgressIndex: queueProgressIndex + 1
  });
};