import { createReducer } from '@reduxjs/toolkit';
import { getFrom, setFrom } from '../../utils/localSettings';
import { initLocalStorageUserSettings, initUserSettings, saveLocalStorageUserSetting, saveUserSetting } from '../actions/userSettings';
const initialState = {
  loading: true,
  error: false,
  keys: {}
};
const mergeKeys = (stateKeys, newSettingsArray) => {
  return newSettingsArray.reduce((acc, setting) => {
    const {
      key,
      value
    } = setting;
    return Object.assign({}, acc, {
      [key]: value
    });
  }, stateKeys);
};
export const userSettings = createReducer(initialState, builder => builder.addCase(initUserSettings.pending, state => {
  state.loading = true;
}).addCase(initUserSettings.fulfilled, (state, action) => {
  state.keys = mergeKeys(state.keys, action.payload.settings);
  state.error = action.payload.hasError;
  state.loading = false;
}).addCase(initLocalStorageUserSettings, (state, action) => {
  const settings = action.payload.settingKeys.map(key => ({
    key,
    value: getFrom(localStorage, key)
  }));
  state.keys = mergeKeys(state.keys, settings);
}).addCase(saveUserSetting.fulfilled, (state, action) => {
  if (action.payload) {
    state.keys = mergeKeys(state.keys, action.payload.settings);
  }
}).addCase(saveLocalStorageUserSetting, (state, action) => {
  setFrom(localStorage, action.payload.settingKey, action.payload.settingValue);
  state.keys = mergeKeys(state.keys, [{
    key: action.payload.settingKey,
    value: action.payload.settingValue
  }]);
}));