import { HUBSPOT_DEFINED } from 'customer-data-objects/associations/AssociationCategoryTypes';
import { ENGAGEMENT_TO_COMPANY, ENGAGEMENT_TO_CONTACT, ENGAGEMENT_TO_DEAL, ENGAGEMENT_TO_TICKET, TASK_TO_COMPANY, TASK_TO_CONTACT, TASK_TO_DEAL, TASK_TO_TICKET } from 'customer-data-objects/associations/AssociationTypes';
import AssociationTypeToAssociationTypeId from 'customer-data-objects/associations/AssociationTypeToAssociationTypeId';
import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const CRM_ASSOCIATIONS_TO_TASK_ASSOCIATONS = {
  [TASK_TO_CONTACT]: CONTACT_TYPE_ID,
  [TASK_TO_COMPANY]: COMPANY_TYPE_ID,
  [TASK_TO_DEAL]: DEAL_TYPE_ID,
  [TASK_TO_TICKET]: TICKET_TYPE_ID,
  [ENGAGEMENT_TO_CONTACT]: CONTACT_TYPE_ID,
  [ENGAGEMENT_TO_COMPANY]: COMPANY_TYPE_ID,
  [ENGAGEMENT_TO_DEAL]: DEAL_TYPE_ID,
  [ENGAGEMENT_TO_TICKET]: TICKET_TYPE_ID
};
export const ASSOCIATION_TYPE_ID_TO_ASSOCIATION_TYPE = {
  [AssociationTypeToAssociationTypeId.get(TASK_TO_CONTACT)]: TASK_TO_CONTACT,
  [AssociationTypeToAssociationTypeId.get(TASK_TO_COMPANY)]: TASK_TO_COMPANY,
  [AssociationTypeToAssociationTypeId.get(TASK_TO_DEAL)]: TASK_TO_DEAL,
  [AssociationTypeToAssociationTypeId.get(TASK_TO_TICKET)]: TASK_TO_TICKET,
  [AssociationTypeToAssociationTypeId.get(ENGAGEMENT_TO_CONTACT)]: ENGAGEMENT_TO_CONTACT,
  [AssociationTypeToAssociationTypeId.get(ENGAGEMENT_TO_COMPANY)]: ENGAGEMENT_TO_COMPANY,
  [AssociationTypeToAssociationTypeId.get(ENGAGEMENT_TO_DEAL)]: ENGAGEMENT_TO_DEAL,
  [AssociationTypeToAssociationTypeId.get(ENGAGEMENT_TO_TICKET)]: ENGAGEMENT_TO_TICKET
};
export const TASK_ASSOCIATION_SPECS = {
  [CONTACT_TYPE_ID]: {
    associationTypeId: AssociationTypeToAssociationTypeId.get(TASK_TO_CONTACT),
    associationCategory: HUBSPOT_DEFINED
  },
  [COMPANY_TYPE_ID]: {
    associationTypeId: AssociationTypeToAssociationTypeId.get(TASK_TO_COMPANY),
    associationCategory: HUBSPOT_DEFINED
  },
  [DEAL_TYPE_ID]: {
    associationTypeId: AssociationTypeToAssociationTypeId.get(TASK_TO_DEAL),
    associationCategory: HUBSPOT_DEFINED
  },
  [TICKET_TYPE_ID]: {
    associationTypeId: AssociationTypeToAssociationTypeId.get(TASK_TO_TICKET),
    associationCategory: HUBSPOT_DEFINED
  }
};
export const BASE_TASK_ASSOCIATIONS = {
  [CONTACT_TYPE_ID]: {
    associationSpec: TASK_ASSOCIATION_SPECS[CONTACT_TYPE_ID],
    objectIds: []
  },
  [COMPANY_TYPE_ID]: {
    associationSpec: TASK_ASSOCIATION_SPECS[COMPANY_TYPE_ID],
    objectIds: []
  },
  [DEAL_TYPE_ID]: {
    associationSpec: TASK_ASSOCIATION_SPECS[DEAL_TYPE_ID],
    objectIds: []
  },
  [TICKET_TYPE_ID]: {
    associationSpec: TASK_ASSOCIATION_SPECS[TICKET_TYPE_ID],
    objectIds: []
  }
};