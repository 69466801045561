export const DAILY = 'DAILY';
export const WEEKDAY = 'WEEKDAY';
export const WEEKLY = 'WEEKLY';
export const MONTHLY = 'MONTHLY';
export const YEARLY = 'YEARLY';
export const RepeatStatusOptions = {
  NONE: 'NONE',
  REPEATING: 'REPEATING',
  STOPPED_UNKNOWN: 'STOPPED_UNKNOWN',
  STOPPED_BY_USER: 'STOPPED_BY_USER',
  STOPPED_SCOPE_LOST: 'STOPPED_SCOPE_LOST',
  STOPPED_INTERVAL_CHANGED: 'STOPPED_INTERVAL_CHANGED',
  STOPPED_ASSIGNEE_NOT_FOUND: 'STOPPED_ASSIGNEE_NOT_FOUND',
  STOPPED_OCCURRENCE_LIMIT: 'STOPPED_OCCURRENCE_LIMIT',
  STOPPED_END_DATE: 'STOPPED_END_DATE'
};