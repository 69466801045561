import I18n from 'I18n';
import { List } from 'immutable';
import { SAME_TIME, HALF_HOUR_BEFORE, HOUR_BEFORE, DAY_BEFORE, WEEK_BEFORE, TODAY, TOMORROW, ONE_BUSINESS_DAY, TWO_BUSINESS_DAYS, THREE_BUSINESS_DAYS, ONE_WEEK, TWO_WEEKS, ONE_MONTH, THREE_MONTHS, SIX_MONTHS } from '../constants/RelativeDates';
import { snapToBusinessDay, addBusinessDays } from './businessDayHelpers';
const FRIDAY = 5;
const SATURDAY = 6;
const LANG_KEYS = {
  [SAME_TIME]: 'taskFormsLib.input.taskReminder.preset.sameTime',
  [HALF_HOUR_BEFORE]: 'taskFormsLib.input.taskReminder.preset.halfHourBefore',
  [HOUR_BEFORE]: 'taskFormsLib.input.taskReminder.preset.hourBefore',
  [DAY_BEFORE]: 'taskFormsLib.input.taskReminder.preset.dayBefore',
  [WEEK_BEFORE]: 'taskFormsLib.input.taskReminder.preset.weekBefore',
  [TODAY]: 'taskFormsLib.input.taskDueDate.preset.today',
  [TOMORROW]: 'taskFormsLib.input.taskDueDate.preset.tomorrow',
  [ONE_BUSINESS_DAY]: 'taskFormsLib.input.taskDueDate.preset.oneBusinessDay',
  [TWO_BUSINESS_DAYS]: 'taskFormsLib.input.taskDueDate.preset.twoBusinessDays',
  [THREE_BUSINESS_DAYS]: 'taskFormsLib.input.taskDueDate.preset.threeBusinessDays',
  [ONE_WEEK]: 'taskFormsLib.input.taskDueDate.preset.oneWeek',
  [TWO_WEEKS]: 'taskFormsLib.input.taskDueDate.preset.twoWeeks',
  [ONE_MONTH]: 'taskFormsLib.input.taskDueDate.preset.oneMonth',
  [THREE_MONTHS]: 'taskFormsLib.input.taskDueDate.preset.threeMonths',
  [SIX_MONTHS]: 'taskFormsLib.input.taskDueDate.preset.sixMonths'
};
export function datePresetToMoment(presetKey, relatesTo = I18n.moment.userTz()) {
  let diff;
  switch (presetKey) {
    case SAME_TIME:
      return relatesTo.clone();
    case HALF_HOUR_BEFORE:
      return relatesTo.clone().subtract(0.5, 'hours');
    case HOUR_BEFORE:
      return relatesTo.clone().subtract(1, 'hour');
    case DAY_BEFORE:
      return relatesTo.clone().subtract(1, 'day');
    case WEEK_BEFORE:
      return relatesTo.clone().subtract(1, 'week');
    case TODAY:
      return relatesTo.clone().startOf('day');
    case TOMORROW:
      return relatesTo.clone().add(1, 'day').startOf('day');
    case ONE_BUSINESS_DAY:
      return addBusinessDays(relatesTo, 1).startOf('day');
    case TWO_BUSINESS_DAYS:
      return addBusinessDays(relatesTo, 2).startOf('day');
    case THREE_BUSINESS_DAYS:
      return addBusinessDays(relatesTo, 3).startOf('day');
    case ONE_WEEK:
      diff = [1, 'week'];
      break;
    case TWO_WEEKS:
      diff = [2, 'weeks'];
      break;
    case ONE_MONTH:
      diff = [1, 'month'];
      break;
    case THREE_MONTHS:
      diff = [3, 'months'];
      break;
    case SIX_MONTHS:
      diff = [6, 'months'];
      break;
    default:
      return relatesTo.clone();
  }
  return snapToBusinessDay(relatesTo.clone().add(...diff).startOf('day'));
}
export function momentToDatePreset(value, presets, relatesTo = I18n.moment.userTz()) {
  return presets.find(presetKey => {
    const presetValue = datePresetToMoment(presetKey, relatesTo);
    return presetValue.valueOf() === value;
  });
}
export function getRelativeLabel(presetKey, relatesTo = I18n.moment.userTz()) {
  let options;
  switch (presetKey) {
    case ONE_BUSINESS_DAY:
    case TWO_BUSINESS_DAYS:
    case THREE_BUSINESS_DAYS:
      options = {
        day: datePresetToMoment(presetKey, relatesTo).format('dddd')
      };
      break;
    case ONE_WEEK:
    case TWO_WEEKS:
    case ONE_MONTH:
    case THREE_MONTHS:
    case SIX_MONTHS:
      options = {
        day: datePresetToMoment(presetKey, relatesTo).format('MMMM D')
      };
      break;
    default:
      options = undefined;
      break;
  }
  return I18n.text(LANG_KEYS[presetKey], options);
}
export function getDueDatePresets(relatesTo = I18n.moment.userTz()) {
  const config = List.of(TODAY, TOMORROW, ONE_BUSINESS_DAY, TWO_BUSINESS_DAYS, THREE_BUSINESS_DAYS, ONE_WEEK, TWO_WEEKS, ONE_MONTH, THREE_MONTHS, SIX_MONTHS);

  // The whole list of options includes 'Tomorrow' and
  // '1 business day', which in most cases are the same, so we usually delete
  // the '1 business day' option. On fridays and saturdays, they're different
  // (1bd -> monday, tomorrow -> sat or sun). So we leave both in.
  if (![FRIDAY, SATURDAY].includes(relatesTo.day())) {
    return config.filterNot(cat => cat === ONE_BUSINESS_DAY).toList();
  }
  return config;
}
export function getTaskReminderPresets() {
  return List.of(SAME_TIME, HALF_HOUR_BEFORE, HOUR_BEFORE, DAY_BEFORE, WEEK_BEFORE);
}
export function getUISelectFormattedOptions(options, relatesTo = I18n.moment.userTz()) {
  return options.map(cat => ({
    value: datePresetToMoment(cat, relatesTo).valueOf(),
    text: getRelativeLabel(cat, relatesTo),
    key: cat
  })).toArray();
}