import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import { STANDARD, DEFAULT } from 'customer-data-objects/view/ViewTypes';
import PropTypes from 'prop-types';
import { PAGE_IDS } from '../constants/pageConfigs';
import { PRIVATE, SHARED } from '../constants/QueueAccessTypes';
import { DAILY, MONTHLY, WEEKDAY, WEEKLY, YEARLY } from '../constants/repeatingTasks';

// TODO Delete this file after TS migration

export function PropertiesObjectPropType(props, propName, componentName) {
  const propertiesObject = props[propName];

  // validate values
  const invalidValues = Object.values(propertiesObject).filter(propertyValue => !(propertyValue instanceof PropertyRecord));
  if (invalidValues.length > 0) {
    return new Error(`Invalid object values for prop '${propName}' supplied to ${componentName}. Object values should be instances of PropertyRecord. Invalid values were: ${invalidValues}.`);
  }
}
const filterGroupPropType = PropTypes.shape({
  filter: PropTypes.object
});
export { filterGroupPropType };
export const ViewIdPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
// @ts-expect-error ts-migrate(2322) FIXME: Type 'Requireable<InferProps<{ filterGroups: Valid... Remove this comment to see the full error message
const ElasticSearchQueryPropType = PropTypes.shape({
  filterGroups: PropTypes.arrayOf(filterGroupPropType).isRequired
});
export { ElasticSearchQueryPropType };
// @ts-expect-error ts-migrate(2322) FIXME: Type 'Requireable<InferProps<{ id: Validator<strin... Remove this comment to see the full error message
const ViewPropType = PropTypes.shape({
  id: ViewIdPropType.isRequired,
  name: PropTypes.string,
  crmSearchOptions: ElasticSearchQueryPropType
});
export { ViewPropType };
export const PageIdPropType = PropTypes.oneOf(Object.values(PAGE_IDS));
const QueueDefinitionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  accessType: PropTypes.oneOf([PRIVATE, SHARED]).isRequired
});
export { QueueDefinitionPropType };
// @ts-expect-error ts-migrate(2322) FIXME: Type 'Requireable<InferProps<{ definition: Validat... Remove this comment to see the full error message
const QueuePropType = PropTypes.shape({
  definition: QueueDefinitionPropType.isRequired,
  userParticipants: PropTypes.arrayOf(PropTypes.number).isRequired
});
export { QueuePropType };
export function TasksResultsSearchKeyPropType(props, propName, componentName) {
  const searchKey = props[propName];
  if (searchKey.length !== 3) {
    return new Error(`Invalid length for prop ${propName} supplied to ${componentName}. The length should be 3, but the length was: ${searchKey.length}`);
  }
  for (const partialKey in searchKey) {
    if (typeof partialKey !== 'string') {
      return new Error(`Invalid array element type for prop ${propName} supplied to ${componentName}. Each element of the array should be a string, Invalid value is: ${partialKey}`);
    }
  }
}
export const ChildrenPropType = PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.element, PropTypes.arrayOf(PropTypes.element)]);
const RepeatIntervalType = PropTypes.shape({
  freq: PropTypes.oneOf([DAILY, WEEKDAY, WEEKLY, MONTHLY, YEARLY]).isRequired,
  interval: PropTypes.number.isRequired
});
export { RepeatIntervalType };
// @ts-expect-error ts-migrate(2322) FIXME: Type 'Requireable<InferProps<{ id: Requireable<str... Remove this comment to see the full error message
const SavedViewPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  state: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })).isRequired,
  ownerId: PropTypes.number,
  private: PropTypes.bool,
  teamId: PropTypes.number,
  type: PropTypes.oneOf([STANDARD, DEFAULT]).isRequired
});
export { SavedViewPropType };