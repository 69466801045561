import TaskRecord from 'customer-data-objects/task/TaskRecord';
import { fetchTask } from '../api/fetchTask';
import { END_OF_QUEUE } from '../constants/configConstants';
import { getNextTaskId } from '../state/getNextTaskId';
import { getTaskQueueConfig } from '../storage/getTaskQueueConfig';
import { updateTaskQueueConfig } from '../storage/updateTaskQueueConfig';
import { validateStorageShape } from '../storage/validateStorageShape';

/**
 * This function updates the sessionStorage queue config to represent moving to the next task; it moves the progress index forward and updates
 * currentTask and currentTaskId to reflect this.
 * It is meant to be called through TaskQueueManager by consuming applications rather than within task-queues. If wanting to make the same state
 * update from within task-queues, should use hooks/goToNextTask or actions/goToTask instead
 */
export const setStateToNextTask = (apiClient, storage) => () => {
  validateStorageShape(storage);
  const {
    taskIds
  } = getTaskQueueConfig(storage)();
  const nextTaskId = getNextTaskId(storage);
  if (!nextTaskId) {
    console.error('Cannot execute setStateToNextTask as there are no more remaining tasks in the queue');
    return Promise.resolve(END_OF_QUEUE);
  }
  return fetchTask(apiClient)(nextTaskId).then(task => {
    const queueProgressIndex = taskIds.indexOf(nextTaskId);
    // This should not happen since getNextTaskId pulls from taskIds in storage
    if (queueProgressIndex < 0) {
      console.error('There was an error setting the state to the next task');
    } else {
      updateTaskQueueConfig(storage)({
        currentTask: TaskRecord.fromJS(task),
        currentTaskId: nextTaskId,
        queueProgressIndex
      });
    }
    return task;
  });
};