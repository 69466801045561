import I18n from 'I18n';
import { TWO_MINUTES_IN_MILLIS } from '../constants/filters';
let lastNowPlaceholderValue;
export function getNowPlaceholderValue() {
  const now = I18n.moment.userTz().valueOf();
  if (lastNowPlaceholderValue && now - lastNowPlaceholderValue < TWO_MINUTES_IN_MILLIS) {
    return lastNowPlaceholderValue;
  }
  lastNowPlaceholderValue = now;
  return lastNowPlaceholderValue;
}
export function getTodayDatePlaceholderValue() {
  return I18n.moment.userTz().format('YYYY-MM-DD');
}
export function getTomorrowDatePlaceholderValue() {
  return I18n.moment.userTz().add(1, 'day').format('YYYY-MM-DD');
}