import { Map as ImmutableMap, List } from 'immutable';
const INTERNAL_URL_PATTERN = new RegExp('(local.*|tools.*).(hubspot|hubteam)(qa)?.com');
export const buildTag = (tagName, contents, attrs = {}) => {
  let attrStr = '';
  if (Object.keys(attrs).length) {
    attrStr = Object.keys(attrs)
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .filter(k => attrs[k])
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .map(k => `${k}="${attrs[k]}"`).join(' ');
    if (attrStr.length) {
      attrStr = ` ${attrStr}`;
    }
  }
  return `<${tagName}${attrStr}>${contents}</${tagName}>`;
};
export function memoize(func) {
  let _cache = ImmutableMap();
  return (...args) => {
    const argList = List.of(...args);
    if (!_cache.has(argList)) {
      _cache = _cache.set(argList, func(...args));
    }
    return _cache.get(argList);
  };
}
export const toggleInlineStyleOverride = (editorState, styleLookupSet) => {
  let currentInlineStyleOverride = editorState.getInlineStyleOverride();
  if (currentInlineStyleOverride === null) {
    currentInlineStyleOverride = editorState.getCurrentInlineStyle();
  }
  return currentInlineStyleOverride.subtract(styleLookupSet);
};
export function ensureUrlHasProtocol(url) {
  const trimmed = url.trim();
  if (!url || !trimmed) {
    return url;
  }
  const hasProtocol = trimmed.includes('://') || trimmed.includes('tel:') || trimmed.includes('mailto:');
  if (hasProtocol) {
    return url;
  }
  return `http://${url}`;
}
export function encodeUrl(url) {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.pathname = decodeURIComponent(anchor.pathname);
  return anchor.href;
}
export function isInternal() {
  return INTERNAL_URL_PATTERN.test(window.location.hostname);
}
export function getImageDimensions(blob) {
  const image = new Image();
  image.src = blob;
  return new Promise(resolve => {
    image.onload = function () {
      resolve({
        width: this.width,
        height: this.height
      });
    };
  });
}