import { AssociationCategoryIdsToTypes } from 'customer-data-objects/associations/AssociationCategoryTypes';
import { NOT_STARTED } from 'customer-data-objects/engagement/EngagementStatusTypes';
import { getProperty, setProperty } from 'customer-data-objects/model/ImmutableModel';
import { DUE_DATE, MENTIONED_OWNER_IDS, OWNER_ID, QUEUE_IDS, REMINDERS, REPEAT_INTERVAL, STATUS } from 'customer-data-objects/task/TaskPropertyNames';
import { List } from 'immutable';
import filter from 'transmute/filter';
import flatten from 'transmute/flatten';
import get from 'transmute/get';
import identity from 'transmute/identity';
import map from 'transmute/map';
import pipe from 'transmute/pipe';
import toJS from 'transmute/toJS';
import { TASK_FORM_PROPERTIES } from '../constants/properties';
import { associationColumnNameToAssociationSpec } from '../utils/associationColumnNames';
import { cleanAssociations } from '../utils/taskForms';
const toNumber = value => Number(value);
// The tasks/v2 api requires some properties to be arrays.
// For historical reasons, these are not stored as arrays in a lot of TaskRecord instances.
const toArray = value => {
  const wrappedValue = pipe(List.of.bind(List), flatten, map(Number), filter(Boolean))(value);
  return wrappedValue;
};
export const TypedTaskPropertyFormatters = {
  [DUE_DATE]: toNumber,
  // choosing no owner results in "" being passed, which we don't want to
  // format into a number as it causes the BE to look for an owner with ID 0
  [OWNER_ID]: value => value === '' ? null : toNumber(value),
  [MENTIONED_OWNER_IDS]: toArray,
  [QUEUE_IDS]: value => value === '' ? null : toArray(value),
  [REMINDERS]: toArray
};
const filterForEditableTaskProperties = property => TASK_FORM_PROPERTIES.includes(get('name', property));

/**
 *
 * @param {Map} taskRecordProperties Map({ propertyName: { name, value}})
 * @returns {Object} { propertyName: value }
 */
export const formatPropertiesForTaskV2Request = taskRecordProperties => {
  if (!taskRecordProperties) {
    return taskRecordProperties;
  }
  return pipe(filter(filterForEditableTaskProperties), map(property => {
    const propertyName = get('name', property);
    const propertyValue = get('value', property);
    const formatter = get(propertyName, TypedTaskPropertyFormatters) || identity;
    return propertyValue !== null ? formatter(propertyValue) : propertyValue;
  }), toJS)(taskRecordProperties);
};

/**
 *
 * @param {Number} objectId
 * @param {Map} associationSpec { associationCategory, associationTypeId} || { associationTypeId, toObjectTypeId }
 * @returns {Object} { associationCategory, associationTypeId, toObjectId }
 */
const formattedAssociationV2 = (objectId, associationSpec) => {
  const associationTypeId = get('associationTypeId', associationSpec);
  let associationCategory = get('associationCategory', associationSpec);

  // some places (like follow-up tasks) send associations in the
  // { associationTypeId, toObjectTypeId } format without the category
  if (!associationCategory && get('toObjectTypeId', associationSpec)) {
    const {
      associationCategoryId
    } = associationColumnNameToAssociationSpec(get('toObjectTypeId', associationSpec));
    associationCategory = AssociationCategoryIdsToTypes[associationCategoryId];
  }
  const tasksV2FormattedAssociationSpec = {
    associationCategory,
    associationTypeId
  };
  return Object.assign({}, tasksV2FormattedAssociationSpec, {
    toObjectId: objectId
  });
};

// Expects taskRecordAssociations to be in simple format
export const formatAssociationsForTaskV2Request = taskRecordAssociations => taskRecordAssociations && taskRecordAssociations.map(simpleAssociation => {
  return List(get('objectIds', simpleAssociation)).map(objectId => formattedAssociationV2(objectId, get('associationSpec', simpleAssociation)));
}).toList().flatten(1).toArray();
const prepareForSave = task => task
// @ts-expect-error Fix usage of update or change function; update expects two args
.update(record => setProperty(record, STATUS, NOT_STARTED))
// @ts-expect-error Fix usage of update or change function; update expects two args
.update(record => getProperty(record, REPEAT_INTERVAL) === null ? record.removeIn(['properties', REPEAT_INTERVAL]) : record);
export function mapTaskRecordToPOST(taskRecord) {
  taskRecord = prepareForSave(taskRecord);
  const {
    associations: recordAssociations,
    properties: recordProperties
  } = taskRecord;
  const associations = formatAssociationsForTaskV2Request(cleanAssociations(recordAssociations));
  const properties = formatPropertiesForTaskV2Request(recordProperties);
  return {
    associations,
    properties
  };
}