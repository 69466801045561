export const suggestionTypes = {
  atMention: 'AT_MENTION',
  tag: 'TAG_MENTION',
  emoji: 'EMOJI',
  slashAI: 'AI_SLASH'
};
export const AT_MENTION = suggestionTypes.atMention;
export const TAG_MENTION = suggestionTypes.tag;
export const AI_SLASH = suggestionTypes.slashAI;
export function getMentionTrigger(suggestionType) {
  switch (suggestionType) {
    case 'AT_MENTION':
      return '@';
    case 'TAG_MENTION':
      return '#';
    case 'EMOJI':
      return ':';
    case 'AI_SLASH':
      return '/';
    default:
      return '@';
  }
}
export function getMentionNodeType(schema, suggestionType) {
  switch (suggestionType) {
    case 'AT_MENTION':
      return schema.nodes.atmention;
    case 'TAG_MENTION':
      return schema.nodes.tag;
    default:
      return schema.nodes.atmention;
  }
}