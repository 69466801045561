import merge from 'transmute/merge';
import set from 'transmute/set';
/**
 * Merges two task objects by always taking the properties of the newTask and merging the top-level keys of both tasks' associations
 * @returns Task with properties of newTask and associations from both task inputs
 */
export const mergeTasksWithAssociations = (oldTask, newTask) => {
  if (!oldTask) {
    return newTask || {};
  }
  const mergedAssociations = oldTask.associations ? merge(newTask.associations, oldTask.associations) : newTask.associations;
  return set('associations', mergedAssociations, newTask);
};