'use es6';

import { createGetSequencesWithPermissionsByIds, createGetSequencesWithPermissionsSearchPage, getSequencesWithPermissionsByIds, getSequencesWithPermissionsSearchPage } from 'reference-resolvers/api/SequenceWithPermissionsAPI';
import { SEQUENCES_WITH_PERMISSIONS } from 'reference-resolvers/constants/CacheKeys';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';

/**
 * Note: This differs from the SequencesReferenceResolver because it enforces
 * Object-level permissions.
 */

export const createSequencesWithPermissionsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: SEQUENCES_WITH_PERMISSIONS,
  createFetchByIds: createGetSequencesWithPermissionsByIds,
  createFetchSearchPage: createGetSequencesWithPermissionsSearchPage,
  fetchByIds: getSequencesWithPermissionsByIds,
  fetchSearchPage: getSequencesWithPermissionsSearchPage
}, options));
export default createSequencesWithPermissionsReferenceResolver();