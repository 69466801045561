require.resolve("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1348/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./en.lyaml?sporks");

export default {
  mode: "lazy",
  source: "crm-tasks-ui/lang/bundles/taskForm",
  context: {
    "de": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1348/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./de.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-de" */); },
    "en": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1348/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./en.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-en" */); },
    "es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1348/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./es.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-es" */); },
    "fr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1348/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./fr.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-fr" */); },
    "ja": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1348/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./ja.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-ja" */); },
    "nl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1348/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./nl.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-nl" */); },
    "pt-br": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1348/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./pt-br.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-pt-br" */); }  }
};
