import { CUSTOM, DAY_BEFORE, HALF_HOUR_BEFORE, HOUR_BEFORE, SAME_TIME, WEEK_BEFORE } from '../constants/RelativeDates';
import { getDueDatePresets, getTaskReminderPresets, momentToDatePreset } from './RelativeDatePresets';
import I18n from 'I18n';
import { Map as ImmutableMap } from 'immutable';
const UNITS_OF_TIME = ImmutableMap({
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS'
});
const VALID_PRESETS = getTaskReminderPresets();
export const NO_REMINDER = null;

/**
 * Returns a relative (to today) date preset if the due date matches the preset. Ignores the time of day
 * @param {Number} dueDate timestamp of due date
 * @returns a preset from RelativeDates if the date matches an option
 */
export function getDueDateCategoryIfMatchesPreset(dueDate) {
  if (dueDate) {
    const TASK_DUE_DATE_PRESETS = getDueDatePresets();
    // Ignore time of day when checking for preset
    const momentDate = I18n.moment.userTz().startOf('day');
    const dueDateStartOfDay = I18n.moment.userTz(dueDate).startOf('day').valueOf();
    const category = momentToDatePreset(dueDateStartOfDay, TASK_DUE_DATE_PRESETS, momentDate);
    return category || CUSTOM;
  }
  return null;
}

/**
 * Returns a relative date preset if the reminder for a due date matches the preset
 * @param {Number} dueDate timestamp for due date; used for calculating if reminder matches a preset relative to this timestamp
 * @param {Number} reminder timestamp for the reminder that is being checked for whether it matches a preset
 * @returns a preset from RelativeDates if the reminder matches an option
 */
export function getReminderCategoryIfMatchesPreset(dueDate, reminder) {
  if (dueDate && reminder) {
    const momentDate = I18n.moment.userTz(dueDate);
    const category = momentToDatePreset(reminder, VALID_PRESETS, momentDate);
    return category || CUSTOM;
  }
  return NO_REMINDER;
}

/**
 * Converts a reminder preset from RelativeDates to the format { unit: "WEEKS"/"DAYS"/etc., count: number }
 * Returns null for both values if preset does not match
 * @param {string} reminderPreset a reminder preset
 * @returns array of relative reminders in the format above
 */
function reminderPresetToUnitAndCount(reminderPreset) {
  let unit;
  let count;
  switch (reminderPreset) {
    case SAME_TIME:
      unit = UNITS_OF_TIME.get('MINUTES');
      count = 0;
      break;
    case HALF_HOUR_BEFORE:
      unit = UNITS_OF_TIME.get('MINUTES');
      count = 30;
      break;
    case HOUR_BEFORE:
      unit = UNITS_OF_TIME.get('HOURS');
      count = 1;
      break;
    case DAY_BEFORE:
      unit = UNITS_OF_TIME.get('DAYS');
      count = 1;
      break;
    case WEEK_BEFORE:
      unit = UNITS_OF_TIME.get('WEEKS');
      count = 1;
      break;
    default:
      unit = null;
      count = null;
  }
  return {
    unit,
    count
  };
}

/**
 * Converts reminders from relative labels to format
 * { unit: "WEEKS"/"DAYS"/etc., count: [number] }
 * Will filter out any reminders that do not match a preset returned by getTaskReminderPresets in RelativeDatePresets
 * @param {Array[string]} reminders Array of preset labels from RelativeDates
 * @returns Array of relative reminders in the above format
 */
export function reminderCategoryToUnitAndCount(reminders) {
  const filteredReminders = reminders.filter(preset => preset && VALID_PRESETS.includes(preset)); // null is not a valid preset, so it will get filtered out

  return filteredReminders.map(preset => reminderPresetToUnitAndCount(preset));
}

/**
 * Convert reminders from timestamps to relative reminders of format
 * { unit: "WEEKS"/"DAYS"/etc., count: [number] }
 * Will filter out any reminders that do not match a preset returned by getTaskReminderPresets in RelativeDatePresets
 * @param {Number} dueDate Due date that reminders are relative to, as a timestamp
 * @param {Array[Number]} reminders Array of timestamps representing reminders
 * @returns Array of relative reminders in the above format
 */
export function getRemindersAsUnitAndCount(dueDate, reminders) {
  const categories = reminders.map(reminder => getReminderCategoryIfMatchesPreset(dueDate, reminder));
  return reminderCategoryToUnitAndCount(categories);
}