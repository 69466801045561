import { DUE_DATE } from 'customer-data-objects/task/TaskPropertyNames';
import { STANDARD } from 'customer-data-objects/view/ViewTypes';
import unescapedText from 'I18n/utils/unescapedText';
import get from 'transmute/get';
import { DEFAULT_COLUMNS_FOR_SAVED_VIEWS } from '../constants/table';
import { getQueueMembershipIdsFilter, INCOMPLETE_TASK, OWNED_BY_CURRENT_USER } from './crmSearchFilters';
const OPEN_TASKS_ALL = 'all';
const OPEN_TASKS_DUE_TODAY = 'due-today';
const OPEN_TASKS_OVERDUE = 'overdue';
const OPEN_TASKS_UPCOMING = 'upcoming';
const MOST_ENGAGED = 'most-engaged';
const TASKS_HOME_COMPLETED_ALL = 'completed';

// These keys also correspond to the i18n keys in lang/new/features/views
export const VIEW_ID_KEYS = {
  OPEN_TASKS_ALL,
  OPEN_TASKS_DUE_TODAY,
  OPEN_TASKS_OVERDUE,
  OPEN_TASKS_UPCOMING,
  MOST_ENGAGED,
  TASKS_HOME_COMPLETED_ALL
};

/**
 * represents the state key inside a saved view. Currently only holds sort data
 */
const DEFAULT_VIEW_STATE = {
  sortKey: 'hs_timestamp',
  sortColumnName: 'hs_timestamp',
  order: -1
};
export const createClonedView = (view, isViewSharedWithNonUserTeam = false) => {
  const clonedView = {
    // Don't copy over the view ID when cloning, want a new unique one to be generated on create
    id: null,
    name: unescapedText('manageViews.cloneViewModal.clonedViewName', {
      viewName: view.name
    }),
    columns: view.columns,
    filters: view.filters,
    ownerId: null,
    private: Boolean(view.private),
    state: get('state', view),
    // If the view is shared with a team the user is not on, drop the teamId.
    teamId: !isViewSharedWithNonUserTeam ? view.teamId : null,
    type: STANDARD
  };
  if (view.permissionDetails) {
    clonedView['permissionDetails'] = view.permissionDetails;
  }
  return clonedView;
};
export function makeView() {
  return {
    id: null,
    name: '',
    columns: DEFAULT_COLUMNS_FOR_SAVED_VIEWS,
    filters: [OWNED_BY_CURRENT_USER],
    ownerId: null,
    private: true,
    state: DEFAULT_VIEW_STATE,
    teamId: null,
    type: STANDARD
  };
}
export const createViewFromQueue = ({
  viewName,
  queueId,
  isPrivate
}) => {
  return {
    id: null,
    name: viewName,
    columns: DEFAULT_COLUMNS_FOR_SAVED_VIEWS,
    filters: [INCOMPLETE_TASK, getQueueMembershipIdsFilter([queueId])],
    ownerId: null,
    private: isPrivate,
    state: {
      sortKey: DUE_DATE,
      sortColumnName: DUE_DATE,
      order: -1
    },
    type: STANDARD
  };
};