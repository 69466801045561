import { getSessionStorageKey } from '../TaskQueueBar/utils/getSessionStorageKey';
import { getTaskQueueConfig } from './getTaskQueueConfig';
import { validateStorageShape } from './validateStorageShape';
export const updateTaskQueueConfig = storage => partialConfigUpdates => {
  validateStorageShape(storage);
  const config = getTaskQueueConfig(storage)(undefined);
  const newConfig = Object.assign({}, config, partialConfigUpdates);
  const storageKey = getSessionStorageKey();
  try {
    storage.setItem(storageKey, JSON.stringify(newConfig));
  } catch (e) {
    // TODO: How should we handle surfacing local storage errors up to the consuming app?
    // I think ideally apps should pass in a storage interface that handles errors
    console.error(e);
  }
  return newConfig;
};