import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import http from 'hub-http/clients/apiClient';
export const CONTACT_TIMEZONE_PROPERTY_NAME = 'hs_timezone';
export function fetchTimezoneColumnPropertyDefinition() {
  // Use contact property definition to get options list
  return http.get(`properties/v4/${CONTACT_TYPE_ID}/named/${CONTACT_TIMEZONE_PROPERTY_NAME}`).then(response => {
    if (response && response.property) {
      return PropertyRecord.fromJS(response.property);
    }
    return null;
  });
}