import { DUE_DATE, RELATIVE_REMINDERS, REMINDERS } from 'customer-data-objects/task/TaskPropertyNames';
import apiClient from 'hub-http/clients/apiClient';
import unescapedText from 'I18n/utils/unescapedText';
import http from 'hub-http/clients/apiClient';
// @ts-expect-error untyped
import { mapTaskAPIV2ResponseToTaskRecord } from 'customer-data-objects/task/mapTaskAPIResponseToTaskRecord';
const getTitleFormatsForRequest = () => {
  return {
    titleFormat: unescapedText('taskFormsLib.followUp.taskAssociation', {
      association: '{{ASSOCIATION}}'
    }),
    titleFormatUnassociated: unescapedText('taskFormsLib.followUp.unassociatedTaskSubjectTemplateFormat', {
      taskTitle: '{{PREV_TASK_TITLE}}'
    })
  };
};
export function createTask(task) {
  const taskObj = task.toJS();
  taskObj.properties = task.get('properties').map(property =>
  // Reminders needs to be created as an array with this endpoint, but is
  // tracked as a single value on the TaskRecord.
  property.get('name') === REMINDERS && property.get('value') ? [property.get('value')] : property.get('value')).toJS();
  return apiClient.post('tasks/v2/tasks', {
    data: taskObj
  });
}

/**
 * Create a follow up task on task with taskIdToFollowUpOn
 * @param taskIdToFollowUpOn
 * @param timestamp Due date of follow up task
 * @param reminders Reminder for task
 * @param relativeReminders Reminder for task, in relative format (eg { unit: 'WEEKS', count: 1 }). If set,
 *    this value will override what is passed in through the reminders param - the returned TaskRecord will have its
 *    REMINDERS and RELATIVE_REMINDERS values both set based on relativeReminders
 * @returns Promise<TaskRecord>
 */
export function postCreateFollowUpTask({
  taskIdToFollowUpOn,
  timestamp,
  reminders = [],
  relativeReminders = []
}) {
  const {
    titleFormat,
    titleFormatUnassociated
  } = getTitleFormatsForRequest();
  return http.post(`tasks/v2/tasks/${taskIdToFollowUpOn}/follow-up`, {
    data: {
      titleFormat,
      titleFormatUnassociated,
      properties: {
        [DUE_DATE]: timestamp,
        [RELATIVE_REMINDERS]: relativeReminders,
        [REMINDERS]: reminders
      }
    },
    headers: {
      'X-Properties-Source': 'CRM_UI'
    }
  }).then(mapTaskAPIV2ResponseToTaskRecord);
}