export const ALL_ACCESSIBLE_TEAM_IDS = 'hs_all_accessible_team_ids';
export const BODY_PREVIEW = 'hs_body_preview';
export const COMPLETION_DATE = 'hs_task_completion_date';
export const CONTACT_TIMEZONE = 'hs_task_contact_timezone';
export const CREATED_BY = 'hs_created_by';
export const CREATED_DATE = 'hs_createdate';
export const DUE_DATE = 'hs_timestamp';
export const ENGAGEMENT_SOURCE = 'hs_engagement_source';
export const ENGAGEMENT_SOURCE_ID = 'hs_engagement_source_id';
export const ENGAGEMENT_TYPE = 'hs_engagement_type';
export const FOR_OBJECT_TYPE = 'hs_task_for_object_type';
export const IS_ALL_DAY = 'hs_task_is_all_day';
export const LAST_ENGAGEMENT = 'hs_task_last_sales_activity_timestamp';
export const LAST_MODIFIED_DATE = 'hs_lastmodifieddate';
export const LAST_TOUCH = 'hs_task_last_contact_outreach';
export const MENTIONED_OWNER_IDS = 'hs_at_mentioned_owner_ids';
export const MODIFIED_BY = 'hs_modified_by';
export const NOTES = 'hs_task_body';
export const OWNER_ASSIGNED_DATE = 'hubspot_owner_assigneddate';
export const OWNER_ID = 'hubspot_owner_id';
export const PRIORITY = 'hs_task_priority';
export const QUEUE_IDS = 'hs_queue_membership_ids';
export const RELATIVE_REMINDERS = 'hs_task_relative_reminders';
export const REMINDERS = 'hs_task_reminders';
export const REPEAT_INTERVAL = 'hs_task_repeat_interval';
export const REPEAT_STATUS = 'hs_repeat_status';
export const SEND_DEFAULT_REMINDER = 'hs_task_send_default_reminder';
export const SEQUENCE_STEP_ENROLLMENT_ID = 'hs_task_sequence_step_enrollment_id';
export const SEQUENCE_STEP_ORDER = 'hs_task_sequence_step_order';
export const STATUS = 'hs_task_status';
export const TEAM_ID = 'hubspot_team_id';
export const TEMPLATE_ID = 'hs_task_template_id';
export const TITLE = 'hs_task_subject';
export const TYPE = 'hs_task_type';
export const UNIQUE_ID = 'hs_unique_id';
export const TASK_FAMILY = 'hs_task_family';