import { ALL_ACCESSIBLE_TEAM_IDS, BODY_PREVIEW, COMPLETION_DATE, CONTACT_TIMEZONE, CREATED_BY, CREATED_DATE, DUE_DATE, ENGAGEMENT_SOURCE_ID, IS_ALL_DAY, LAST_ENGAGEMENT, LAST_MODIFIED_DATE, LAST_TOUCH, MENTIONED_OWNER_IDS, NOTES, OWNER_ASSIGNED_DATE, OWNER_ID, PRIORITY, QUEUE_IDS, RELATIVE_REMINDERS, REMINDERS, REPEAT_INTERVAL, REPEAT_STATUS, SEQUENCE_STEP_ORDER, STATUS, TITLE, TYPE } from 'customer-data-objects/task/TaskPropertyNames';

// For association-based columns in the table
export const CONTACT_EMAIL_PROPERTY_NAME = 'email';
export const CONTACT_JOB_TITLE_PROPERTY_NAME = 'jobtitle';
export const CONTACT_PHONE_NUMBER_PROPERTY_NAME = 'phone';
export const COMPANY_DOMAIN_PROPERTY_NAME = 'domain';
export const COMPANY_INDUSTRY_PROPERTY_NAME = 'industry';
export const COMPANY_TARGET_ACCOUNT_STATUS_PROPERTY_NAME = 'hs_is_target_account';
export const TASK_FORM_PROPERTIES = [TITLE, STATUS, TYPE, PRIORITY, OWNER_ID, QUEUE_IDS, DUE_DATE, RELATIVE_REMINDERS, REPEAT_INTERVAL, REMINDERS, NOTES, MENTIONED_OWNER_IDS, IS_ALL_DAY];
export const TASK_FORM_READ_PROPERTIES = [...TASK_FORM_PROPERTIES, ALL_ACCESSIBLE_TEAM_IDS];
export const TABLE_PROPERTIES = [BODY_PREVIEW, COMPLETION_DATE, CONTACT_TIMEZONE, CREATED_BY, CREATED_DATE, DUE_DATE, ENGAGEMENT_SOURCE_ID, IS_ALL_DAY, LAST_ENGAGEMENT, LAST_MODIFIED_DATE, LAST_TOUCH, NOTES, OWNER_ASSIGNED_DATE, OWNER_ID, PRIORITY, QUEUE_IDS, REPEAT_STATUS, SEQUENCE_STEP_ORDER, STATUS, TITLE, TYPE];

// Non-task properties that we need the full definition and options for in order to render table cells
export const TABLE_COMPANY_PROPERTIES = [COMPANY_INDUSTRY_PROPERTY_NAME];