'use es6';

import dynamicLoadingTranslations from 'i2l?query=sporks!../../../lang/bundles/taskForm/en.lyaml';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from '../context/Redux';
import { useTaskProperties } from './properties';
import I18nContext from '../context/I18nContext';
import { TASK_FORM_READ_PROPERTIES } from '../constants/properties';
import { updateTask } from '../redux/actions/tasksCRUD';
export function useLoadTaskFormI18nFiles() {
  const I18nProvider = useContext(I18nContext);
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  useEffect(() => {
    I18nProvider.register(dynamicLoadingTranslations).then(() => {
      setTranslationsLoaded(true);
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  }, [I18nProvider]);
  return translationsLoaded;
}
export function useTaskFormProperties() {
  const {
    properties,
    loading
  } = useTaskProperties(TASK_FORM_READ_PROPERTIES);
  return {
    taskProperties: properties,
    loading
  };
}
const getTaskRecordWithOnlyEditedValues = (editedTask, edits) => {
  return editedTask.update('properties', properties => properties.filter(property => edits.has(property.name))).update('associations', associations => edits.has('associations') ? associations : null);
};
export function useOnSaveEditedTask() {
  const dispatch = useDispatch();
  return useCallback((editedTask, edits, {
    updateTemplate
  } = {}) => {
    const taskPatch = getTaskRecordWithOnlyEditedValues(editedTask, edits);
    dispatch(updateTask({
      associations: taskPatch.associations,
      failureMessageI18nKey: 'tasksAlerts.bulkActions.generic.failure.one',
      objectId: taskPatch.objectId,
      propertyUpdates: taskPatch.properties,
      updateTemplate
    }));
    return Promise.resolve();
  }, [dispatch]);
}