import { MODAL_NONE } from '../../constants/modalTypes';
import { createReducer } from '@reduxjs/toolkit';
import { closeModalAction, openModalAction } from '../actions/overlay';
const initialState = {
  modalType: MODAL_NONE,
  // TODO Can type modalData more strictly based on modalType
  modalData: {}
};
export const overlay = createReducer(initialState, builder => {
  builder.addCase(openModalAction, (state, action) => {
    state.modalType = action.payload.modalType;
    state.modalData = action.payload.modalData;
  }).addCase(closeModalAction, state => {
    state.modalType = MODAL_NONE;
    state.modalData = initialState.modalData;
  });
});