import { findWrapping, ReplaceAroundStep, canSplit, liftTarget } from 'prosemirror-transform';
import { Slice, Fragment, NodeRange } from 'prosemirror-model';
import { areAttrsEqual, findNode } from './nodeUtils';
export function isBulletList(schema, node) {
  return node.type === schema.nodes.bullet_list;
}
export function isOrderedList(schema, node) {
  return node.type === schema.nodes.ordered_list;
}
export function isList(schema, node) {
  return node.type === schema.nodes.bullet_list || node.type === schema.nodes.ordered_list;
}
export function buildLiftListItemTransaction(itemType, tr, $from, $to) {
  const range = $from.blockRange($to, node => !!node.childCount && !!node.firstChild && node.firstChild.type === itemType);
  if (!range) {
    return tr;
  }
  if ($from.node(range.depth - 1).type === itemType) {
    return liftToOuterList(tr, itemType, range);
  } else {
    return liftOutOfList(tr, range) || tr;
  }
}
function liftToOuterList(tr, itemType, range) {
  const end = range.end;
  const endOfList = range.$to.end(range.depth);
  if (end < endOfList) {
    // There are siblings after the lifted items, which must become
    // children of the last item
    tr.step(new ReplaceAroundStep(end - 1, endOfList, end, endOfList, new Slice(Fragment.from(itemType.create(null, range.parent.copy())), 1, 0), 1, true));
    range = new NodeRange(tr.doc.resolve(range.$from.pos), tr.doc.resolve(endOfList), range.depth);
  }
  return tr.lift(range, liftTarget(range) || 0).scrollIntoView();
}
function liftOutOfList(tr, range) {
  const list = range.parent;
  // Merge the list items into a single big item
  for (let pos = range.end, i = range.endIndex - 1, e = range.startIndex; i > e; i--) {
    pos -= list.child(i).nodeSize;
    tr.delete(pos - 1, pos + 1);
  }
  const $start = tr.doc.resolve(range.start);
  const item = $start.nodeAfter;
  if (!item || range.end !== range.start + item.nodeSize) {
    return false;
  }
  const atStart = range.startIndex === 0;
  const atEnd = range.endIndex === list.childCount;
  const parent = $start.node(-1);
  const indexBefore = $start.index(-1);
  if (!parent.canReplace(indexBefore + (atStart ? 0 : 1), indexBefore + 1, item.content.append(atEnd ? Fragment.empty : Fragment.from(list)))) return false;
  const start = $start.pos;
  const end = start + item.nodeSize;
  // Strip off the surrounding list. At the sides where we're not at
  // the end of the list, the existing list is closed. At sides where
  // this is the end, it is overwritten to its end.
  tr.step(new ReplaceAroundStep(start - (atStart ? 1 : 0), end + (atEnd ? 1 : 0), start + 1, end - 1, new Slice((atStart ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))).append(atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))), atStart ? 0 : 1, atEnd ? 0 : 1), atStart ? 0 : 1));
  return tr.scrollIntoView();
}

/**
 * Get a style object based off the only child of the startNode,
 * and any of the only children of that node, recursively.
 */
export function getNodeChildrenStyles(startNode) {
  const styleObj = {
    font_size: null,
    color: null,
    backgroundColor: null,
    fontFamily: null
  };

  // recursive function
  const checkChildForStyle = node => {
    // childElementCount does not include text children
    const isOnlyChild = node.content.firstChild === node.content.lastChild;
    if (node.content.firstChild && isOnlyChild) {
      const childNode = node.content.firstChild;
      if (childNode.attrs.font_size) {
        styleObj.font_size = childNode.attrs.font_size;
      }
      if (childNode.attrs.color) {
        styleObj.color = childNode.attrs.color;
      }
      if (childNode.attrs.backgroundColor) {
        styleObj.backgroundColor = childNode.attrs.backgroundColor;
      }
      if (childNode.attrs.fontFamily) {
        styleObj.fontFamily = childNode.attrs.fontFamily;
      }
      for (let i = 0; i < childNode.marks.length; i++) {
        const mark = childNode.marks[i];
        switch (mark.type.name) {
          case 'font_size':
            styleObj.font_size = mark.attrs.size;
            break;
          case 'text_color':
            styleObj.color = mark.attrs.color;
            break;
          case 'highlight':
            styleObj.backgroundColor = mark.attrs.color;
            break;
          case 'font_style':
            styleObj.fontFamily = mark.attrs.style;
            break;
          default:
            break;
        }
      }
      checkChildForStyle(childNode);
    }
  };
  checkChildForStyle(startNode);
  return styleObj;
}

/**
 * Get a style object based off the only child of the startNode,
 * and any of the only children of that node, recursively.
 */
function getDomChildrenStyles(domElement) {
  const styleObj = {
    font_size: null,
    color: null,
    backgroundColor: null,
    fontFamily: null
  };
  let anyStyleFound = false;

  // recursive function
  const checkChildForStyle = element => {
    const style = element === null || element === void 0 ? void 0 : element.style;
    if (style) {
      if (style.fontSize !== '') {
        anyStyleFound = true;
        styleObj.font_size = styleObj.font_size || style.fontSize;
      }
      if (style.color !== '') {
        anyStyleFound = true;
        styleObj.color = styleObj.color || style.color;
      }
      if (style.backgroundColor !== '') {
        anyStyleFound = true;
        styleObj.backgroundColor = styleObj.backgroundColor || style.backgroundColor;
      }
      if (style.fontFamily !== '') {
        anyStyleFound = true;
        styleObj.fontFamily = styleObj.fontFamily || style.fontFamily;
      }
      const childElement = element.firstChild;
      const hasManyChildren = childElement !== element.lastChild; // childElementCount does not include text children
      if (!childElement || hasManyChildren) return;
      checkChildForStyle(childElement);
    }
  };
  checkChildForStyle(domElement);
  return anyStyleFound ? styleObj : false;
}

/**
 * For every targetNode in the doc, apply all of the styles of children which are the only child to it.
 */
export function applyChildrenStylesToNode(state, parentNode, tr, targetNodeName) {
  parentNode.content.forEach(childNode => {
    if (childNode.type.name === targetNodeName) {
      // get styles from node
      const attrs = getNodeChildrenStyles(childNode);
      const nodePos = findNode(state.doc, nodeToCheck => nodeToCheck === childNode);
      if (attrs && nodePos && !areAttrsEqual(nodePos.node.attrs, attrs)) {
        const marks = tr.storedMarks;
        // Calling tr.setNodeMarkup() in this case will clear storedMarks on the state.
        // We use storedMarks to preserve formatting when inserting list items, so it
        // must be preserved.
        // Use tr.ensureMarks() to set the marks back afterward if needed.
        tr = tr.setNodeMarkup(nodePos.pos, nodePos.node.type, attrs);
        if (marks) tr.ensureMarks(marks);
      }
    } else {
      tr = applyChildrenStylesToNode(state, childNode, tr, targetNodeName);
    }
  });
  return tr;
}

// Copied from https://github.com/ProseMirror/prosemirror-schema-list/blob/master/src/schema-list.js then edited to work in typescript

// :: NodeSpec
// An ordered list [node spec](#model.NodeSpec). Has a single
// attribute, `order`, which determines the number at which the list
// starts counting, and defaults to 1. Represented as an `<ol>`
// element.
const orderedList = {
  attrs: {
    order: {
      default: 1
    }
  },
  parseDOM: [{
    tag: 'ol',
    getAttrs: function getAttrs(dom) {
      const domElement = dom;
      return {
        order: domElement.hasAttribute('start') ? +domElement.getAttribute('start') : 1
      };
    }
  }],
  toDOM: function toDOM(node) {
    return node.attrs.order === 1 ? ['ol', {}, 0] : ['ol', {
      start: node.attrs.order
    }, 0];
  }
};

// :: NodeSpec
// A bullet list node spec, represented in the DOM as `<ul>`.
const bulletList = {
  parseDOM: [{
    tag: 'ul'
  }],
  toDOM: function toDOM() {
    return ['ul', {}, 0];
  }
};

// :: NodeSpec
// A list item (`<li>`) spec.
const listItem = {
  attrs: {
    font_size: {
      default: null
    },
    color: {
      default: null
    },
    backgroundColor: {
      default: null
    },
    fontFamily: {
      default: null
    }
  },
  parseDOM: [{
    tag: 'li',
    getAttrs: function getAttrs(dom) {
      return getDomChildrenStyles(dom);
    }
  }],
  toDOM: function toDOM(node) {
    let styleString = '';
    if (node.attrs.font_size) styleString += `font-size: ${node.attrs.font_size};`;
    if (node.attrs.color) styleString += `color: ${node.attrs.color};`;
    if (node.attrs.fontFamily) styleString += `font-family: ${node.attrs.fontFamily};`;
    if (node.attrs.backgroundColor) styleString += `background-color: ${node.attrs.backgroundColor};`;
    return styleString === '' ? ['li', 0] : ['li', {
      style: styleString
    }, 0];
  },
  defining: true
};
function add(obj, props) {
  const copy = {};
  // eslint-disable-next-line guard-for-in
  for (const prop in obj) {
    // @ts-expect-error no types
    copy[prop] = obj[prop];
  }
  // eslint-disable-next-line guard-for-in
  for (const prop$1 in props) {
    // @ts-expect-error no types
    copy[prop$1] = props[prop$1];
  }
  return copy;
}

// :: (OrderedMap<NodeSpec>, string, ?string) → OrderedMap<NodeSpec>
// Convenience function for adding list-related node types to a map
// specifying the nodes for a schema. Adds
// [`orderedList`](#schema-list.orderedList) as `"ordered_list"`,
// [`bulletList`](#schema-list.bulletList) as `"bullet_list"`, and
// [`listItem`](#schema-list.listItem) as `"list_item"`.
//
// `itemContent` determines the content expression for the list items.
// If you want the commands defined in this module to apply to your
// list structure, it should have a shape like `"paragraph block*"` or
// `"paragraph (ordered_list | bullet_list)*"`. `listGroup` can be
// given to assign a group name to the list node types, for example
// `"block"`.
function addListNodes(nodes, itemContent, listGroup) {
  return nodes.append({
    ordered_list: add(orderedList, {
      content: 'list_item+',
      group: listGroup
    }),
    bullet_list: add(bulletList, {
      content: 'list_item+',
      group: listGroup
    }),
    list_item: add(listItem, {
      content: itemContent
    })
  });
}

// :: (NodeType, ?Object) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Returns a command function that wraps the selection in a list with
// the given type an attributes. If `dispatch` is null, only return a
// value to indicate whether this is possible, but don't actually
// perform the change.
function wrapInList(listType, attrs) {
  return function (state, dispatch) {
    const ref = state.selection;
    const $from = ref.$from;
    const $to = ref.$to;
    let range = $from.blockRange($to);
    let doJoin = false;
    let outerRange = range;
    if (!range) {
      return false;
    }
    // This is at the top of an existing list item
    if (range.depth >= 2 &&
    // @ts-expect-error imported prosemirror code
    $from.node(range.depth - 1).type.compatibleContent(listType) && range.startIndex === 0) {
      // Don't do anything if this is the top of the list
      if ($from.index(range.depth - 1) === 0) {
        return false;
      }
      const $insert = state.doc.resolve(range.start - 2);
      outerRange = new NodeRange($insert, $insert, range.depth);
      if (range.endIndex < range.parent.childCount) {
        range = new NodeRange($from, state.doc.resolve($to.end(range.depth)), range.depth);
      }
      doJoin = true;
    }
    // @ts-expect-error imported prosemirror code
    const wrap = findWrapping(outerRange, listType, attrs, range);
    if (!wrap) {
      return false;
    }
    if (dispatch) {
      dispatch(doWrapInList(state.tr, range, wrap, doJoin, listType).scrollIntoView());
    }
    return true;
  };
}
function doWrapInList(tr, range, wrappers, joinBefore, listType) {
  let content = Fragment.empty;
  for (let i = wrappers.length - 1; i >= 0; i--) {
    content = Fragment.from(wrappers[i].type.create(wrappers[i].attrs, content));
  }
  tr.step(new ReplaceAroundStep(range.start - (joinBefore ? 2 : 0), range.end, range.start, range.end, new Slice(content, 0, 0), wrappers.length, true));
  let found = 0;
  for (let i$1 = 0; i$1 < wrappers.length; i$1++) {
    if (wrappers[i$1].type === listType) {
      found = i$1 + 1;
    }
  }
  const splitDepth = wrappers.length - found;
  let splitPos = range.start + wrappers.length - (joinBefore ? 2 : 0);
  const parent = range.parent;
  for (let i$2 = range.startIndex, e = range.endIndex, first = true; i$2 < e; i$2++, first = false) {
    if (!first && canSplit(tr.doc, splitPos, splitDepth)) {
      tr.split(splitPos, splitDepth);
      splitPos += 2 * splitDepth;
    }
    splitPos += parent.child(i$2).nodeSize;
  }
  return tr;
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Build a command that splits a non-empty textblock at the top level
// of a list item by also splitting that list item.
function splitListItem(getItemType) {
  return function (state, dispatch) {
    const itemType = getItemType(state.schema);
    const ref = state.selection;
    const $from = ref.$from;
    const $to = ref.$to;
    // @ts-expect-error imported prosemirror code
    const node = ref.node;
    if (node && node.isBlock || $from.depth < 2 || !$from.sameParent($to)) {
      return false;
    }
    const grandParent = $from.node(-1);
    if (grandParent.type !== itemType) {
      return false;
    }
    if ($from.parent.content.size === 0 && $from.node(-1).childCount === $from.indexAfter(-1)) {
      // In an empty block. If this is a nested list, the wrapping
      // list item should be split. Otherwise, bail out and let next
      // command handle lifting.
      if ($from.depth === 2 || $from.node(-3).type !== itemType || $from.index(-2) !== $from.node(-2).childCount - 1) {
        return false;
      }
      if (dispatch) {
        let wrap = Fragment.empty;
        const depthBefore = $from.index(-1) ? 1 : $from.index(-2) ? 2 : 3;
        // Build a fragment containing empty versions of the structure
        // from the outer list item to the parent node of the cursor
        for (let d = $from.depth - depthBefore; d >= $from.depth - 3; d--) {
          wrap = Fragment.from($from.node(d).copy(wrap));
        }
        const depthAfter = $from.indexAfter(-1) < $from.node(-2).childCount ? 1 : $from.indexAfter(-2) < $from.node(-3).childCount ? 2 : 3;
        // Add a second list item with an empty default start node
        wrap = wrap.append(Fragment.from(itemType.createAndFill() || undefined));
        const start = $from.before($from.depth - (depthBefore - 1));
        const tr = state.tr.replace(start, $from.after(-depthAfter), new Slice(wrap, 4 - depthBefore, 0));
        let sel = -1;
        // eslint-disable-next-line consistent-return
        tr.doc.nodesBetween(start, tr.doc.content.size, (betweenNode, pos) => {
          if (sel > -1) return false;
          if (betweenNode.isTextblock && betweenNode.content.size === 0) sel = pos + 1;
        });
        if (sel > -1) tr.setSelection(
        // @ts-expect-error imported prosemirror code
        state.selection.constructor.near(tr.doc.resolve(sel)));
        dispatch(tr.scrollIntoView());
      }
      return true;
    }
    const nextType = $to.pos === $from.end() ? grandParent.contentMatchAt(0).defaultType : null;
    const tr = state.tr.delete($from.pos, $to.pos);
    const types = nextType && [null, {
      type: nextType
    }];
    // @ts-expect-error imported prosemirror code
    if (!canSplit(tr.doc, $from.pos, 2, types)) return false;
    // @ts-expect-error imported prosemirror code
    if (dispatch) dispatch(tr.split($from.pos, 2, types).scrollIntoView());
    return true;
  };
}

/**
 * Split a list_item block, preserving formatting marks in the new list_item
 */
function splitListItemKeepMarks(getItemType) {
  const splitInstance = splitListItem(getItemType);
  return function (state, dispatch) {
    const originalMarks = state.selection.$anchor.marks();
    return splitInstance(state, tr => {
      if (dispatch) {
        tr = tr.ensureMarks(originalMarks);
        dispatch(tr);
      }
    });
  };
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Create a command to lift the list item around the selection up into
// a wrapping list.
function liftListItem(getItemType) {
  return function (state, dispatch) {
    const itemType = getItemType(state.schema);
    const ref = state.selection;
    const $from = ref.$from;
    const $to = ref.$to;
    const range = $from.blockRange($to, node => {
      return !!node.firstChild && node.firstChild.type === itemType;
    });
    if (!range) {
      return false;
    }
    if (!dispatch) {
      return true;
    }
    if ($from.node(range.depth - 1).type === itemType) {
      // Inside a parent list
      return prosemirrorLiftToOuterList(state, dispatch, itemType, range);
    } // Outer list node
    else {
      return prosemirrorLiftOutOfList(state, dispatch, range);
    }
  };
}
function prosemirrorLiftToOuterList(state, dispatch, itemType, range) {
  const tr = state.tr;
  const end = range.end;
  const endOfList = range.$to.end(range.depth);
  if (end < endOfList) {
    // There are siblings after the lifted items, which must become
    // children of the last item
    tr.step(new ReplaceAroundStep(end - 1, endOfList, end, endOfList, new Slice(Fragment.from(itemType.create(null, range.parent.copy())), 1, 0), 1, true));
    range = new NodeRange(tr.doc.resolve(range.$from.pos), tr.doc.resolve(endOfList), range.depth);
  }
  dispatch(tr.lift(range, liftTarget(range) || 0).scrollIntoView());
  return true;
}
function prosemirrorLiftOutOfList(state, dispatch, range) {
  const tr = state.tr;
  const list = range.parent;
  // Merge the list items into a single big item
  for (let pos = range.end, i = range.endIndex - 1, e = range.startIndex; i > e; i--) {
    pos -= list.child(i).nodeSize;
    tr.delete(pos - 1, pos + 1);
  }
  const $start = tr.doc.resolve(range.start);
  const item = $start.nodeAfter;
  const atStart = range.startIndex === 0;
  const atEnd = range.endIndex === list.childCount;
  const parent = $start.node(-1);
  const indexBefore = $start.index(-1);
  if (!parent.canReplace(indexBefore + (atStart ? 0 : 1), indexBefore + 1,
  // @ts-expect-error imported prosemirror code
  item.content.append(atEnd ? Fragment.empty : Fragment.from(list)))) {
    return false;
  }
  const start = $start.pos;
  // @ts-expect-error imported prosemirror code
  const end = start + item.nodeSize;
  // Strip off the surrounding list. At the sides where we're not at
  // the end of the list, the existing list is closed. At sides where
  // this is the end, it is overwritten to its end.
  tr.step(new ReplaceAroundStep(start - (atStart ? 1 : 0), end + (atEnd ? 1 : 0), start + 1, end - 1, new Slice((atStart ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))).append(atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))), atStart ? 0 : 1, atEnd ? 0 : 1), atStart ? 0 : 1));
  dispatch(tr.scrollIntoView());
  return true;
}

// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Create a command to sink the list item around the selection down
// into an inner list.
function sinkListItem(getItemType) {
  return function (state, dispatch) {
    const itemType = getItemType(state.schema);
    const ref = state.selection;
    const $from = ref.$from;
    const $to = ref.$to;
    const range = $from.blockRange($to, node => {
      return !!node.firstChild && node.firstChild.type === itemType;
    });
    if (!range) {
      return false;
    }
    const startIndex = range.startIndex;
    if (startIndex === 0) {
      return false;
    }
    const parent = range.parent;
    const nodeBefore = parent.child(startIndex - 1);
    if (nodeBefore.type !== itemType) {
      return false;
    }
    if (dispatch) {
      const nestedBefore = nodeBefore.lastChild && nodeBefore.lastChild.type === parent.type;
      const inner = Fragment.from(nestedBefore ? itemType.create() : undefined);
      const slice = new Slice(Fragment.from(itemType.create(null, Fragment.from(parent.type.create(null, inner)))), nestedBefore ? 3 : 1, 0);
      const before = range.start;
      const after = range.end;
      dispatch(state.tr.step(new ReplaceAroundStep(before - (nestedBefore ? 3 : 1), after, before, after, slice, 1, true)).scrollIntoView());
    }
    return true;
  };
}
export { addListNodes, bulletList, liftListItem, listItem, orderedList, sinkListItem, splitListItemKeepMarks, wrapInList };