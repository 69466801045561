import { TASK } from 'customer-data-objects/constants/ObjectTypes';
import { ALL_ACCESSIBLE_TEAM_IDS, DUE_DATE, ENGAGEMENT_TYPE, LAST_MODIFIED_DATE, MODIFIED_BY, NOTES, OWNER_ID, PRIORITY, QUEUE_IDS, REMINDERS, SEND_DEFAULT_REMINDER, SEQUENCE_STEP_ENROLLMENT_ID, STATUS, TITLE, TYPE, UNIQUE_ID } from 'customer-data-objects/task/TaskPropertyNames';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import flatten from 'transmute/flatten';
const isInteger = value => typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
export function convertToInt(value) {
  if (value === undefined || value === null) return value;
  if (isInteger(value)) return value;

  // floats, bools, objects
  if (typeof value !== 'string') return null;
  const parsed = Number(value);
  if (!isInteger(parsed)) return null;
  return parsed;
}
const getValue = property => property && property.get('value');

// copied from crm_data/engagements/inboundDbProperties/engagementInboundDbObjectHelpers
const getTypeSpecificFieldsFromInboundProperties = properties => {
  switch (getValue(properties.get(ENGAGEMENT_TYPE))) {
    case TASK:
      {
        const rawRemindersValue = getValue(properties.get(REMINDERS));
        return fromJS({
          body: getValue(properties.get(NOTES)),
          reminders: rawRemindersValue && flatten(List.of(rawRemindersValue)).map(convertToInt),
          sendDefaultReminder: getValue(properties.get(SEND_DEFAULT_REMINDER)),
          status: getValue(properties.get(STATUS)),
          subject: getValue(properties.get(TITLE)),
          taskType: getValue(properties.get(TYPE)),
          priority: getValue(properties.get(PRIORITY))
        }).filter(property => property != null);
      }
    default:
      return ImmutableMap();
  }
};
const convertCommonFieldsToInboundProperties = engagement => {
  const baseProperties = engagement.get('engagement', ImmutableMap());
  return fromJS([{
    name: UNIQUE_ID,
    value: baseProperties.get('id')
  }, {
    name: LAST_MODIFIED_DATE,
    value: baseProperties.get('lastUpdated')
  }, {
    name: MODIFIED_BY,
    value: baseProperties.get('modifiedBy')
  }, {
    name: OWNER_ID,
    value: baseProperties.get('ownerId')
  }, {
    name: DUE_DATE,
    value: baseProperties.get('timestamp')
  }, {
    name: ENGAGEMENT_TYPE,
    value: baseProperties.get('type')
  }, {
    name: ALL_ACCESSIBLE_TEAM_IDS,
    value: baseProperties.get('allAccessibleTeamIds')
  }]).filter(getValue);
};
const convertTypeSpecificFieldsToInboundProperties = (engagementType, engagement) => {
  const metadata = engagement.get('metadata', ImmutableMap());
  switch (engagementType) {
    case TASK:
      return fromJS([{
        name: NOTES,
        value: metadata.get('body')
      }, {
        name: REMINDERS,
        value: metadata.get('reminders')
      }, {
        name: SEND_DEFAULT_REMINDER,
        value: metadata.get('sendDefaultReminder')
      }, {
        name: STATUS,
        value: metadata.get('status')
      }, {
        name: TITLE,
        value: metadata.get('subject')
      }, {
        name: TYPE,
        value: metadata.get('taskType')
      }, {
        name: QUEUE_IDS,
        value: engagement.getIn(['engagement', 'queueMembershipIds', 0])
      }, {
        name: PRIORITY,
        value: metadata.get('priority')
      }, {
        name: SEQUENCE_STEP_ENROLLMENT_ID,
        value: metadata.get('sequenceStepEnrollmentId')
      }]).filter(property => property.get('value') != null);
    default:
      return List();
  }
};
export const engagementToInboundDbObject = engagement => {
  const type = engagement.getIn(['engagement', 'type']);
  const associations = engagement.get('associations', ImmutableMap());
  const convertedObject = fromJS({
    object: {
      properties: convertCommonFieldsToInboundProperties(engagement)
    },
    associations: {
      ENGAGEMENT_TO_CONTACT: associations.get('contactIds') || List(),
      ENGAGEMENT_TO_COMPANY: associations.get('companyIds') || List(),
      ENGAGEMENT_TO_DEAL: associations.get('dealIds') || List(),
      ENGAGEMENT_TO_TICKET: associations.get('ticketIds') || List()
    }
  });
  return convertedObject.updateIn(['object', 'properties'], properties => properties.concat(convertTypeSpecificFieldsToInboundProperties(type, engagement)).filter(getValue));
};
export const engagementFromInboundDbObject = inboundDbObject => {
  const properties = inboundDbObject.get('properties');
  const timestamp = convertToInt(getValue(properties.get(DUE_DATE)));
  const queueMembershipId = getValue(properties.get(QUEUE_IDS));
  const baseEngagementProperties = fromJS({
    id: inboundDbObject.get('objectId'),
    lastUpdated: getValue(properties.get(LAST_MODIFIED_DATE)),
    modifiedBy: getValue(properties.get(MODIFIED_BY)),
    ownerId: getValue(properties.get(OWNER_ID)),
    timestamp,
    type: getValue(properties.get(ENGAGEMENT_TYPE)),
    queueMembershipIds: queueMembershipId && flatten(List.of(queueMembershipId)).map(convertToInt)
  }).filter(property => property != null);
  return ImmutableMap({
    engagement: baseEngagementProperties,
    metadata: getTypeSpecificFieldsFromInboundProperties(properties)
  });
};