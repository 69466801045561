import { useEffect, useState } from 'react';
import memoize from 'transmute/memoize';
import { fetchOwnersByUserIds } from '../api/userOwners';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
const memoizedFetchOwnersByUserIds = memoize(fetchOwnersByUserIds);
export function useHubSpotUserOwnerIds(userIds) {
  const [ownerIds, setOwners] = useState([]);
  useEffect(() => {
    if (userIds && userIds.length > 0) {
      memoizedFetchOwnersByUserIds(userIds).then(ownersByUserId => {
        setOwners(Object.values(ownersByUserId).map(owner => owner.ownerId));
      }).catch(rethrowError);
    }
  }, [userIds]);
  return ownerIds;
}