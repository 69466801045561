module.exports = {
  "pmFormatting": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmLink": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "noFollow": "boolean",
      "externalLink": "boolean",
      "isImage": "boolean",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmImage": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": "string",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmToken": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": "string",
      "tokenCategory": "string",
      "tokenName": "string",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": "string",
      "watchPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmChangeColor": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "format": [
        "forecolor",
        "hilitecolor"
      ],
      "method": {
        "type": "string",
        "isOptional": true
      },
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmPayment": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmInsertEmoji": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": [
        "popover",
        "shortcut"
      ],
      "value": {
        "type": "string",
        "isOptional": true
      },
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmOpenEmojiPopover": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "pmInvoice": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "prosemirrorVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": "string",
      "watchPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  }
};