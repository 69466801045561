import { fetchTask } from '../api/fetchTask';
import { getPageStateForTask } from './getPageStateForTask';
import TaskRecord from 'customer-data-objects/task/TaskRecord';
import { Metrics } from '../TaskQueueBar/utils/Metrics';
export const fetchPageStateForTask = apiClient => (nextTaskId, onFetchTaskError = () => null, consumerFunctionName = null) => {
  if (!nextTaskId) {
    onFetchTaskError({
      consumerFunctionName,
      error: new Error(`Attempted to call fetchPageStateForTask with null or undefined task ID`),
      taskId: nextTaskId
    });
    Metrics.counter('fetch-page-state-no-task-id').increment();
    return Promise.resolve(getPageStateForTask());
  }
  return fetchTask(apiClient)(nextTaskId).then(task => {
    return getPageStateForTask(task instanceof TaskRecord ? task : TaskRecord.fromJS(task));
  }).catch(error => {
    onFetchTaskError({
      consumerFunctionName,
      error,
      taskId: nextTaskId
    });
    Metrics.counter('fetch-page-state-fetch-task-failed').increment();
    return getPageStateForTask();
  });
};