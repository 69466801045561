'use es6';

import { Map as ImmutableMap } from 'immutable';
import enviro from 'enviro';
import allSettled from 'hs-promise-utils/allSettled';
const showDevOutput = !enviro.deployed();
const makeBatch = (fetchById, apiName) => {
  return ids => {
    const numIds = ids.size || ids.length;
    if (numIds > 2) {
      const error = new Error(`makeBatch(${apiName}) called with more than 2 IDs. This can cause performance issues waiting for the requests to resolve. Look into a batch endpoint.`);
      if (showDevOutput) {
        console.error(error, ids.toJSON());
      }
    }
    const requests = ids.valueSeq().toArray().map(fetchById);
    return allSettled(requests).then(result => {
      return result.reduce((acc, imp, index) => {
        const id = ids.get(index);
        let value;
        if (imp.status === 'fulfilled') {
          value = imp.value;
        }
        return acc.set(id, value);
      }, ImmutableMap());
    });
  };
};
export default makeBatch;