import { Record, List, fromJS } from 'immutable';
import { QUOTE_ASSOCIATIONS } from './constants/properties';
const defaults = {
  [QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL]: List(),
  [QUOTE_ASSOCIATIONS.QUOTE_TO_LINE_ITEM]: List(),
  [QUOTE_ASSOCIATIONS.QUOTE_TO_CONTACT]: List(),
  [QUOTE_ASSOCIATIONS.QUOTE_TO_COMPANY]: List(),
  [QUOTE_ASSOCIATIONS.QUOTE_TO_QUOTE_TEMPLATE]: List(),
  [QUOTE_ASSOCIATIONS.QUOTE_TO_PAYMENT_SCHEDULE]: List()
};
export default class QuoteAssociations extends Record(defaults) {
  static fromJS(json) {
    return new QuoteAssociations(fromJS(json));
  }
}