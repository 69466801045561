export const DEFAULT = 'DEFAULT';
export const MINE = 'MINE';
export const OTHERS = 'OTHERS';
export const SUPPORTED_CATEGORIES = [DEFAULT, MINE, OTHERS];
export const VIEW_CATEGORY_PAGE_SIZE = 20;
export const MAX_PINNED_VIEWS = 10;

// This is the maximum length of a user-entered index page view name
// Derived from https://git.hubteam.com/HubSpot/Sales/blob/c7fccf727a86bced65a51c7ad21c52e9bf2551e8/SalesData/src/main/java/com/hubspot/sales/data/managers/ViewsManager.java#L77
export const MAX_VIEW_NAME_LENGTH = 256;
export const SHARING_OPTIONS = {
  PRIVATE: 'PRIVATE',
  TEAM_AND_USERS: 'TEAM_AND_USERS',
  PUBLIC: 'PUBLIC'
};

// keys that the temp view we store in the reducer has,
// but the view returned from the API does not have
export const TEMP_VIEW_ONLY_KEYS = ['defaultFilters', 'offset', 'searchText', 'sortState'];