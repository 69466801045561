import invariant from 'react-utils/invariant';
import { getId } from 'customer-data-objects/record/ObjectRecordAccessors';
import { getObjectType, getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
import { toString } from 'customer-data-objects/model/ImmutableModel';
import { ObjectTypesToIds, ObjectTypeFromIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { toCrmObjectKey } from 'customer-data-objects/crmObject/CrmObjectKey';
import { CONTACT, COMPANY, DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import CompanyRecord from 'customer-data-objects/company/CompanyRecord';
import DealRecord from 'customer-data-objects/deal/DealRecord';
import TicketRecord from 'customer-data-objects/ticket/TicketRecord';
import CrmObjectRecord from 'customer-data-objects/crmObject/CrmObjectRecord';
import { reportNewTasksWarning, GROUP_NAMES } from '../tracking/errors';
export function getObjectDisplayName(object, fallbackString) {
  const toStringResult = toString(object);
  if (toStringResult && toStringResult !== '-') {
    return toStringResult;
  }

  // TODO Fix any type
  const nameProperty = getProperty(object, 'name');
  return nameProperty || fallbackString || '-';
}
export function createObjectRecord(genericObjectData) {
  const {
    objectId,
    objectType: objectTypeArg,
    objectTypeId,
    properties
  } = genericObjectData;
  const objectType = objectTypeArg || ObjectTypeFromIds[objectTypeId];
  switch (objectType) {
    case CONTACT:
      return ContactRecord.fromJS({
        vid: objectId,
        properties
      });
    case COMPANY:
      return CompanyRecord.fromJS({
        companyId: objectId,
        properties
      });
    case DEAL:
      return DealRecord.fromJS({
        dealId: objectId,
        properties
      });
    case TICKET:
      return TicketRecord.fromJS({
        objectId,
        properties
      });
    default:
      return CrmObjectRecord.fromJS({
        objectId,
        objectTypeId,
        properties
      });
  }
}
export function getObjectTypeId(object) {
  if (object.objectTypeId) {
    return object.objectTypeId;
  }
  const objectType = getObjectType(object);
  if (objectType in ObjectTypesToIds) {
    return ObjectTypesToIds[objectType];
  }
  reportNewTasksWarning('Unrecognized objectType with no defined objectTypeId', {
    groupName: GROUP_NAMES.CRM_OBJECT,
    functionName: 'getObjectTypeId',
    extra: {
      objectId: object.id,
      objectType,
      object
    }
  });
  return null;
}
export function getCrmObjectKey({
  type,
  id,
  crmObjectKey
}) {
  invariant(crmObjectKey || type && id, 'expected a crmObjectKey or type and id');
  if (crmObjectKey) {
    return crmObjectKey;
  }
  invariant(type in ObjectTypesToIds, `objectType ${type} was not found in ObjectTypesToIds`);
  const objectTypeId = ObjectTypesToIds[type];
  return toCrmObjectKey({
    objectTypeId,
    objectId: id
  });
}
export const getCrmObjectKeyFromObject = object => {
  const objectTypeId = getObjectTypeId(object);
  const objectId = getId(object);
  const objectKey = toCrmObjectKey({
    objectTypeId,
    objectId
  });
  return objectKey;
};
export function toCrmObjectAssociationKey({
  associationCategoryId,
  associationTypeId
}) {
  return `${associationCategoryId}-${associationTypeId}`;
}