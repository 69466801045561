import { DOMParser } from 'prosemirror-model';
import { schema as defaultSchema } from '../ProsemirrorSchema';
import { schema as basicSchema } from 'prosemirror-schema-basic';
import Raven from 'raven-js';
export const fullParser = new DOMParser(defaultSchema, [{
  tag: 'br',
  closeParent: true,
  context: 'paragraph/'
},
// @ts-expect-error not in types
...DOMParser.schemaRules(defaultSchema)]);
export const hsBasicParser = DOMParser.fromSchema(defaultSchema);
export const basicParser = DOMParser.fromSchema(basicSchema);
const captureError = (e, dom, schemaType) => {
  const wrapperDiv = document.createElement('div');
  wrapperDiv.appendChild(dom);
  Raven.captureException(e, {
    extra: {
      html: wrapperDiv.innerHTML,
      schemaType
    }
  });
};
export class SafeParser extends DOMParser {
  parse(dom, options) {
    try {
      return super.parse(dom, options);
    } catch (e) {
      // report
      captureError(e, dom, 'Full Schema');
      try {
        return hsBasicParser.parse(dom, options);
      } catch (e2) {
        captureError(e2, dom, 'HS Basic Schema');
        throw e2;
      }
    }
  }
  parseSlice(dom, options) {
    try {
      return super.parseSlice(dom, options);
    } catch (e) {
      // report
      captureError(e, dom, 'Full Schema');
      try {
        return hsBasicParser.parseSlice(dom, options);
      } catch (e2) {
        captureError(e2, dom, 'HS Basic Schema');
        throw e2;
      }
    }
  }
}
export const getDomParser = (schema = defaultSchema) => {
  return new SafeParser(schema, [{
    tag: 'br',
    closeParent: true,
    context: 'paragraph/'
  },
  // @ts-expect-error not in types
  ...DOMParser.schemaRules(schema)]);
};