export const toCrmObjectKey = ({
  objectId,
  objectTypeId
}) => `${objectId}-${objectTypeId}`;
export const fromCrmObjectKey = crmObjectKey => {
  const splitIndex = crmObjectKey.indexOf('-');
  const objectId = crmObjectKey.slice(0, splitIndex);
  const objectTypeId = crmObjectKey.slice(splitIndex + 1);
  return {
    objectId,
    objectTypeId
  };
};