// Copied from https://git.hubteam.com/HubSpot/customer-data-properties/blob/master/customer-data-properties/static/js/input/phone/utils/phoneNumberUtils.ts

import formatPhoneNumber from 'I18n/utils/formatPhoneNumber';
// @ts-expect-error Untyped dependency
import formatPhoneNumberWithExtension from 'I18n/utils/formatPhoneNumberWithExtension';
export function parseRawPhoneNumber(rawPhoneNumber) {
  const regex = new RegExp(/^(.*) ext\D*(\d+)$/);
  const phoneNumberParts = regex.exec(rawPhoneNumber);
  const phoneNumber = phoneNumberParts ? phoneNumberParts[1] : rawPhoneNumber;
  const extension = phoneNumberParts ? phoneNumberParts[2] : '';
  return {
    phoneNumber,
    extension
  };
}
export function isValidRawPhoneNumber(rawPhoneNumber) {
  const {
    phoneNumber,
    extension
  } = parseRawPhoneNumber(rawPhoneNumber);
  const regex = /(?=[^+])\D/; // match non-digits excluding '+' character

  return !regex.test(phoneNumber) && !regex.test(extension);
}
export function formatRawPhoneNumber(rawPhoneNumber) {
  if (isValidRawPhoneNumber(rawPhoneNumber)) {
    const {
      phoneNumber,
      extension
    } = parseRawPhoneNumber(rawPhoneNumber);
    return extension ? formatPhoneNumberWithExtension(phoneNumber, extension) : formatPhoneNumber(phoneNumber, '');
  }
  return rawPhoneNumber;
}