import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
import { getFullUrl } from 'hubspot-url-utils';
export const fullHubSpotUrl = (pathname = '') => {
  const subdomain = enviro.debug('local-url') ? 'local' : 'app';
  return `${getFullUrl(subdomain)}${pathname}`;
};
export const getCrmSettingsTasksRemindersLink = () => {
  const portalId = PortalIdParser.get();
  const settingsUrl = `/settings/${portalId}/user-preferences/tasks`;
  return fullHubSpotUrl(settingsUrl);
};