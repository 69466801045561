import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
export const getHubletSubdomain = () => {
  switch (enviro.getHublet()) {
    case 'na1':
      {
        return '';
      }
    case 'eu1':
      {
        return '-eu1';
      }
    default:
      {
        return '';
      }
  }
};
export const getHost = () => {
  return `https://app${getHubletSubdomain()}.hubspot${enviro.isQa() ? 'qa' : ''}.com`;
};
export const getBrandVoiceUrl = brandKitId => {
  return `${getHost()}/settings/${PortalIdParser.get()}/account-defaults/branding/brand-kit/${brandKitId || 'primary'}/brand-voice`;
};