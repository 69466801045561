module.exports = {
  "/view/:viewId": {
    "name": "viewId",
    "success": {
      "table": [
        "TASKS_TABLE_LOADED"
      ],
      "empty": [
        "TASKS_TABLE_EMPTY"
      ]
    },
    "error": [
      "TASKS_TABLE_ERROR"
    ]
  },
  "/queues": {
    "name": "queues_table",
    "success": {
      "manage_queues_table": [
        "MANAGE_QUEUES_LOADED"
      ]
    },
    "error": [
      "MANAGE_QUEUES_PANEL_ERROR"
    ]
  },
  "/queues/new": {
    "name": "new_queue",
    "success": {
      "create_queue_modal": [
        "CREATE_QUEUE_PANEL_LOADED"
      ]
    },
    "error": [
      "CREATE_QUEUE_PANEL_ERROR"
    ]
  },
  "/queues/:queueId": {
    "name": "edit_queue",
    "success": {
      "edit_queue_modal": [
        "EDIT_QUEUE_PANEL_LOADED"
      ]
    },
    "error": [
      "CREATE_QUEUE_PANEL_ERROR"
    ]
  },
  "/view/:viewId/task/new": {
    "name": "new_task",
    "success": {
      "create_form_and_table_populated": [
        "CREATE_TASK_PANEL_LOADED",
        "TASKS_TABLE_LOADED"
      ],
      "create_form_and_empty_table": [
        "CREATE_TASK_PANEL_LOADED",
        "TASKS_TABLE_EMPTY"
      ]
    },
    "error": [
      "CREATE_TASK_PANEL_ERROR",
      "TASKS_TABLE_ERROR"
    ]
  },
  "/view/:viewId/filters": {
    "name": "view_filters",
    "success": {
      "filters_panel_and_table_populated": [
        "FILTERS_PANEL_LOADED",
        "TASKS_TABLE_LOADED"
      ],
      "filters_panel_and_empty_table": [
        "FILTERS_PANEL_LOADED",
        "TASKS_TABLE_EMPTY"
      ]
    },
    "error": [
      "FILTERS_PANEL_ERROR",
      "TASKS_TABLE_ERROR"
    ]
  },
  "/view/:viewId/task/:taskId": {
    "name": "task_panel",
    "success": {
      "edit_form_and_table_populated": [
        "EDIT_TASK_PANEL_LOADED",
        "TASKS_TABLE_LOADED"
      ],
      "edit_form_and_empty_table": [
        "EDIT_TASK_PANEL_LOADED",
        "TASKS_TABLE_EMPTY"
      ]
    },
    "error": [
      "EDIT_TASK_PANEL_ERROR",
      "TASKS_TABLE_ERROR"
    ]
  },
  "/board": {
    "name": "board_view",
    "success": {
      "board": [
        "TASKS_BOARD_COLUMN"
      ]
    }
  },
  "/board/task/:taskId": {
    "name": "board_task_panel",
    "success": {
      "form_and_board_populated": [
        "TASKS_BOARD_COLUMN",
        "EDIT_TASK_PANEL_LOADED"
      ]
    },
    "error": [
      "EDIT_TASK_PANEL_ERROR"
    ]
  },
  "/": {
    "name": "legacy_root_redirect"
  },
  "/task/:taskId": {
    "name": "legacy_task_redirect"
  },
  "/tasks/list": {
    "name": "legacy_task_list_redirect"
  },
  "/tasks/board": {
    "name": "legacy_task_board_redirect"
  },
  "/tasks/agenda": {
    "name": "legacy_agenda_redirect"
  },
  "/tasks/view/:view": {
    "name": "legacy_view_redirect"
  },
  "/tasks": {
    "name": "legacy_tasks_redirect"
  },
  "/tasks/from-recommendation/:recommendationId": {
    "name": "legacy_recommendation_redirect"
  },
  "/tasks/agenda/view/:view": {
    "name": "legacy_agenda_view_redirect"
  },
  "/tasks/list/view/:view": {
    "name": "legacy_list_view_redirect"
  },
  "/tasks/board/view/:view": {
    "name": "legacy_board_view_redirect"
  }
};