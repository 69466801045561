import getIn from 'transmute/getIn';
import { SELECTION_METHOD_BY_IDS } from '../constants/selectionMethods';
import { useBulkActionsRawCache } from './useBulkActionsRawCache';
import { useBulkActionsSelectionState } from './useBulkActionsSelectionState';
export const useBulkActionsPermissionsForCurrentSelection = permissionsKey => {
  const selectionState = useBulkActionsSelectionState();
  const cache = useBulkActionsRawCache();
  const selectionMethod = selectionState.selectionMethod;
  if (selectionMethod === SELECTION_METHOD_BY_IDS) {
    const selectedObjectIds = selectionState.selectionIds;
    const objectTypeId = selectionState.selectionObjectTypeId;
    const cacheForObjectTypeId = cache.byObjectTypeId[objectTypeId];
    return selectedObjectIds.every(selectedObjectId => {
      const permission = getIn(['byObjectId', selectedObjectId, 'permissions', permissionsKey], cacheForObjectTypeId);
      return Boolean(permission);
    });
  } else {
    const selectionQuery = selectionState.selectionCrmSearchQuery;
    const cacheForQuery = cache.byCrmSearchQueryObject[JSON.stringify(selectionQuery)];
    return Boolean(getIn(['permissions', permissionsKey], cacheForQuery));
  }
};