import TaskRecord from 'customer-data-objects/task/TaskRecord';
import { getTaskQueueConfig, getTaskQueueConfigByKey } from '../storage/getTaskQueueConfig';
import { getCurrentTaskIdQueryParam } from '../TaskQueueBar/utils/navigation';
import { getNextTaskId } from './getNextTaskId';
const EMPTY_QUEUE_STATE = {
  isActive: false,
  currentTaskId: null,
  total: 0
};

/**
 * Gets a simple representaion of the current task queue state.
 * For use in the consuming app in order to properly handle displaying the task queue.
 */
export const getState = storage => storageOptions => {
  const config = getTaskQueueConfig(storage)(storageOptions);
  if (!config) {
    return EMPTY_QUEUE_STATE;
  }
  const {
    currentTask,
    currentTaskId,
    queueType,
    redirectUrl,
    taskIds,
    total
  } = config;
  const nextTaskId = getNextTaskId(storage);
  const currentTaskIdQueryParam = getCurrentTaskIdQueryParam();
  const parsedCurrentTaskIdQueryParam = currentTaskIdQueryParam ? parseInt(String(getCurrentTaskIdQueryParam()), 10) : null;
  const state = {
    isActive: true,
    isLastTask: !nextTaskId,
    currentTask: currentTask instanceof TaskRecord ? currentTask : TaskRecord.fromJS(currentTask),
    // In case currentTaskId is not up to date, prioritize the ID in query params
    currentTaskId: parsedCurrentTaskIdQueryParam || currentTaskId,
    nextTaskId,
    total,
    taskIds,
    redirectUrl
  };
  return Object.assign({}, state, {
    queueType
  });
};
export const getStateByKey = (storage, storageKey) => {
  const config = getTaskQueueConfigByKey(storage, storageKey);
  if (!config) {
    return EMPTY_QUEUE_STATE;
  }
  const {
    currentTask,
    currentTaskId,
    total,
    taskIds,
    redirectUrl
  } = config;
  const currentTaskIdQueryParam = getCurrentTaskIdQueryParam();
  const parsedCurrentTaskIdQueryParam = currentTaskIdQueryParam ? parseInt(String(getCurrentTaskIdQueryParam()), 10) : null;
  return {
    isActive: true,
    currentTask: currentTask instanceof TaskRecord ? currentTask : TaskRecord.fromJS(currentTask),
    // In case currentTaskId is not up to date, prioritize the ID in query params. This should be the only instance where the returned currentTaskId
    // is out of sync with currentTask.
    currentTaskId: parsedCurrentTaskIdQueryParam || currentTaskId,
    total,
    taskIds,
    redirectUrl
  };
};