import getIn from 'transmute/getIn';
import { SELECTION_METHOD_BY_IDS } from '../constants/selectionMethods';
import { useBulkActionsRawCache } from './useBulkActionsRawCache';
import { useBulkActionsSelectionState } from './useBulkActionsSelectionState';
export const useBulkActionsNumberOfSelectedObjects = () => {
  const selectionState = useBulkActionsSelectionState();
  const cache = useBulkActionsRawCache();
  const selectionMethod = selectionState.selectionMethod;
  if (selectionMethod === SELECTION_METHOD_BY_IDS) {
    return Number(selectionState.selectionIds.length);
  }
  const selectionQuery = selectionState.selectionCrmSearchQuery;
  const cacheForQuery = cache.byCrmSearchQueryObject[JSON.stringify(selectionQuery)];
  return Number(getIn(['totalNumberOfObjectsInQuery'], cacheForQuery)) || 0;
};