// @ts-expect-error untyped
import { getPropertyInputResolverCreators } from 'customer-data-properties/resolvers/PropertyInputResolvers';
import { CUSTOM_OBJECT, SEQUENCE, TASK_LAST_ENGAGEMENT, TASK_LAST_TOUCH
// @ts-expect-error untyped
} from 'reference-resolvers/constants/ReferenceObjectTypes';
// @ts-expect-error untyped
import ProvideReferenceResolvers from 'reference-resolvers/ProvideReferenceResolvers';
import { TaskLastEngagementPropertyReferenceResolver, TaskLastTouchPropertyReferenceResolver
// @ts-expect-error untyped
} from 'reference-resolvers/resolvers/ABMPropertyHydrationReferenceResolver';
// @ts-expect-error untyped
import CustomObjectReferenceResolver from 'reference-resolvers/resolvers/CustomObjectReferenceResolver';
// @ts-expect-error untyped
import { createOwnerReferenceResolver } from 'reference-resolvers/resolvers/OwnerReferenceResolver';
// @ts-expect-error untyped
import SequencesWithPermissionsReferenceResolver from 'reference-resolvers/resolvers/SequencesWithPermissionsReferenceResolver';
import { INBOUND_DB_IMPORT } from 'customer-data-objects/property/ExternalOptionTypes';
// @ts-expect-error untyped
import InboundDbTaskImportReferenceResolver from 'reference-resolvers/resolvers/InboundDbTaskImportReferenceResolver';
import { TASK_FORM_VALIDATION_OWNER_REFERENCE_TYPE } from '../constants/owners';
const resolvers = Object.assign({}, getPropertyInputResolverCreators(), {
  [CUSTOM_OBJECT]: resolversCache => () => ({
    resolversCache,
    resolverFactory: CustomObjectReferenceResolver
  }),
  [INBOUND_DB_IMPORT]: InboundDbTaskImportReferenceResolver,
  [TASK_LAST_TOUCH]: TaskLastTouchPropertyReferenceResolver,
  [TASK_LAST_ENGAGEMENT]: TaskLastEngagementPropertyReferenceResolver,
  [SEQUENCE]: SequencesWithPermissionsReferenceResolver,
  // We want to use OwnerPagedReferenceResolver (provided in getPropertyInputResolverCreators) for filters so that deactivated users can show up as options,
  // but want to use OwnerReferenceResolver for task form validation so that we can fetch all owners at once
  // Override cacheKey so it isn't using the same 'owners' key as OwnerPagedReferenceResolver
  [TASK_FORM_VALIDATION_OWNER_REFERENCE_TYPE]: createOwnerReferenceResolver({
    cacheKey: TASK_FORM_VALIDATION_OWNER_REFERENCE_TYPE
  })
});
export const provideReferenceResolvers = ProvideReferenceResolvers(resolvers);