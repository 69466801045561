module.exports = {
  "indexPageView": {
    "name": "Index Pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "tab": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "tasksHomePageView": {
    "name": "Home Pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "tab": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "tasksInteraction": {
    "name": "Interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "activityFeedViewed",
        "backToTasksClicked",
        "bulkEdited",
        "bulkDeleted",
        "columnsEdited",
        "editColumnsClicked",
        "scheduleSidebarViewed",
        "calendarEventPopoverOpened",
        "calendarEventAttendeeClicked",
        "calendarLaunchMeetingClicked",
        "calendarMeetingLinkClicked",
        "createQueue",
        "editQueue",
        "deleteQueue",
        "rightSidebarToggled",
        "filterApplied",
        "filterDeleted",
        "taskHistoryClicked",
        "taskSyncBannerDismissed",
        "taskSyncBannerLinkClicked"
      ],
      "id": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "allItems": {
        "type": "boolean",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "stepNumber": {
        "type": "number",
        "isOptional": true
      },
      "added": {
        "type": "array",
        "isOptional": true
      },
      "removed": {
        "type": "array",
        "isOptional": true
      },
      "selected": {
        "type": "array",
        "isOptional": true
      },
      "operator": {
        "type": "string",
        "isOptional": true
      },
      "highValue": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "startTaskQueue": {
    "name": "Start Task Queue",
    "class": "interaction",
    "properties": {
      "source": {
        "type": "string"
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "id": {
        "type": "number",
        "isOptional": true
      },
      "completed": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "unassociatedTaskModalInteraction": {
    "name": "Unassociated Task Modal Interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": {
        "type": [
          "redirectedAfterLoad",
          "viewed",
          "associationsAdded",
          "dismissed",
          "markedCompleted",
          "skipped"
        ]
      },
      "associationCount": {
        "type": "number",
        "isOptional": true
      },
      "taskType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "savedViewsInteraction": {
    "name": "Saved Views Interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "savedViewCreation",
        "savedViewPinned",
        "savedViewUnpinned",
        "savedViewFiltersColumnsUpdated",
        "savedViewRenamed",
        "savedViewSharingManaged",
        "savedViewDeleted",
        "savedViewsReordered",
        "savedViewsStartTasksClicked",
        "savedViewsAddViewClicked"
      ],
      "id": {
        "type": "string",
        "isOptional": true
      },
      "triggeredFrom": {
        "type": [
          "manageViews",
          "dropdown",
          "saveViewButton",
          "addViewButton"
        ],
        "isOptional": true
      },
      "isPrivate": {
        "type": "boolean",
        "isOptional": true
      },
      "teamId": {
        "type": "string",
        "isOptional": true
      },
      "filters": {
        "type": "array",
        "isOptional": true
      },
      "columns": {
        "type": "array",
        "isOptional": true
      },
      "order": {
        "type": "array",
        "isOptional": true
      },
      "isClone": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "tasks"
  }
};