import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
import keySeq from 'transmute/keySeq';
import { cleanAssociations } from '../utils/taskForms';
// @ts-expect-error untyped

// @ts-expect-error untyped

// TODO add support for custom object IDs, can use isObjectTypeId https://git.hubteam.com/HubSpot/customer-data-objects/blob/master/static/js/constants/ObjectTypeIds.ts#L83
export const VALID_ASSOCIATIONS = [ObjectTypesToIds.CONTACT, ObjectTypesToIds.COMPANY, ObjectTypesToIds.DEAL, ObjectTypesToIds.TICKET];
export function formatUniversalAssociationWithObjectIds(universalAssociationsForObjectType) {
  const objectIds = universalAssociationsForObjectType.get('associationOptionRecords').map(associationOption => get('objectId', associationOption)).toList();
  return ImmutableMap(universalAssociationsForObjectType).set('objectIds', objectIds);
}

// Simple Associations only have a map of objectTypeIds to an object with an associationSpec and objectIds
// E.g. {
//  '0-1': {
//    associationSpec: { associationTypeId: 1, toObjectTypeId: 1 },
//    objectIds: [1, 2, 3]
//  }
//}
export function formatUniversalAssociationsAsSimpleAssociations(associations) {
  const simpleAssociations = keySeq(associations).reduce((simpleAssociationsObj, objectTypeId) => {
    const universalAssociationWithObjectIds = formatUniversalAssociationWithObjectIds(associations.get(objectTypeId));
    const objectIds = universalAssociationWithObjectIds.get('objectIds');
    const associationCategory = universalAssociationWithObjectIds.get('associationCategory');
    const associationTypeId = universalAssociationWithObjectIds.get('associationTypeId');
    return simpleAssociationsObj.set(objectTypeId, ImmutableMap({
      associationSpec: {
        associationCategory,
        associationTypeId
      },
      objectIds
    }));
  }, ImmutableMap());
  return cleanAssociations(simpleAssociations);
}