import enviro from 'enviro';
import { getFullUrl } from 'hubspot-url-utils';
import { parse, stringify } from 'hub-http/helpers/params';
export function fullHubSpotUrl(pathname = '') {
  const subdomain = enviro.deployed() ? 'app' : 'local';
  return `${getFullUrl(subdomain)}${pathname}`;
}
export const navigateToURL = url => {
  window.location.href = url;
};
export const defaultNavigateToRecord = relativePathToRecord => {
  const fullUrl = fullHubSpotUrl(relativePathToRecord);
  navigateToURL(fullUrl);
};
const getWindowSearch = () => window.location.search;
const getWindowHref = () => window.location.href;

// copied from updateQueryParams in crm-timeline/navigation/navigationEventHandler
const addQueryParams = ({
  params
}) => {
  const existingParams = parse(getWindowSearch().substring(1));
  const newParams = Object.assign({}, existingParams, params);
  const urlWithoutParams = getWindowHref().split('?')[0];
  const newUrl = `${urlWithoutParams}?${stringify(newParams)}`;
  return newUrl;
};
export const updateUrlWithoutRefresh = url => {
  window.history.pushState({
    previousTaskUrlPath: window.location.pathname + window.location.search
  }, '', url);
};
export const setToPreviousTaskUrl = () => {
  const {
    previousTaskUrlPath
  } = window.history.state;
  if (previousTaskUrlPath) {
    window.history.replaceState({}, '', previousTaskUrlPath);
  }
};
export const replaceUrlWithoutRefresh = url => {
  window.history.replaceState({}, '', url);
};
export function replaceQueryParam(param, newval) {
  const urlWithNewParam = addQueryParams({
    params: {
      [param]: newval
    }
  });
  return urlWithNewParam;
}
export const updateTaskIdQueryParam = newTaskId => {
  const newUrl = addQueryParams({
    params: {
      taskId: newTaskId
    }
  });
  updateUrlWithoutRefresh(newUrl);
};
export const getQueryParam = (paramName, getSearch = getWindowSearch) => {
  const existingParams = parse(getSearch().substring(1));
  return existingParams[paramName];
};
export const getCurrentTaskIdQueryParam = (getSearch = getWindowSearch) => {
  const taskIdQueryParam = getQueryParam('taskId', getSearch);
  // we're expecting only one taskId to be in the URL so return undefined if not
  if (Array.isArray(taskIdQueryParam)) {
    return undefined;
  }
  return taskIdQueryParam;
};