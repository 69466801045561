import { AssociationCategoryIdsToTypes, AssociationCategoryTypesToIds } from 'customer-data-objects/associations/AssociationCategoryTypes';
import http from 'hub-http/clients/apiClient';
import get from 'transmute/get';
import { COLUMN_TO_HYDRATION_ASSOCIATION_SPECS } from '../constants/table';
import { associationColumnNameToAssociationSpec, isCustomObjectAssociationColumnName } from '../utils/associationColumnNames';
import { createObjectRecord, getObjectDisplayName, toCrmObjectAssociationKey } from '../utils/crmObjectHelpers';
function createRequestAssociationSpecs(columnName) {
  const columnToAssociationSpecs = COLUMN_TO_HYDRATION_ASSOCIATION_SPECS[columnName];
  if (columnToAssociationSpecs) {
    return Object.values(columnToAssociationSpecs);
  }

  // custom object association
  const {
    associationCategoryId,
    associationTypeId
  } = associationColumnNameToAssociationSpec(columnName);
  const associationTypeToHydrate = {
    associationCategory: get(associationCategoryId, AssociationCategoryIdsToTypes),
    associationTypeId,
    includeDisplayName: true
  };
  return [associationTypeToHydrate];
}

/**
 * Merges the properties of the two passed in association specs
 * @param {Object} associationSpec1 - { associationCategory, associationTypeId, properties }
 * @param {Object} associationSpec2
 */
function mergeAssociationPropertiesForAssociationType(associationSpec1, associationSpec2) {
  const associationSpec1Properties = get('properties', associationSpec1) || [];
  const associationSpec2Properties = get('properties', associationSpec2) || [];
  return Object.assign({}, associationSpec1, {
    properties: Array.from(new Set([...associationSpec1Properties, ...associationSpec2Properties]))
  });
}

/**
 *
 * @param {Array} columnNames
 *
 * @returns An array containing the associationCategories, associationTypeIds
 * properties, and indication of whether to return a displayName, for each association type.
 * Example:
 * [
 *   {
 *     associationCategory: HUBSPOT_DEFINED
 *     associationTypeId: 204,
 *     includeDisplayName: true
 *     properties: ['firstname', 'lastname', 'email']
 *   }
 * ]
 */
export function columnNamesToRequestFormat(columnNames = []) {
  // remove any columns that don't have associations
  const filteredColumnNames = columnNames.filter(columnName => COLUMN_TO_HYDRATION_ASSOCIATION_SPECS[columnName] || isCustomObjectAssociationColumnName(columnName));

  // combine the association specs for each column into one list
  const columnsToListOfAssociationSpecs = filteredColumnNames.reduce((aggregatedAssociationSpecs, columnName) => {
    const associationSpecs = createRequestAssociationSpecs(columnName);
    return [...aggregatedAssociationSpecs, ...associationSpecs];
  }, []);

  // map over association specs and combine properties for the same association type into one spec
  const aggregatedAssociationTypes = columnsToListOfAssociationSpecs.reduce((associationTypeToSpecMap, associationSpec) => {
    const {
      associationCategory,
      associationTypeId
    } = associationSpec;
    const associationTypeKey = toCrmObjectAssociationKey({
      associationCategoryId: get(associationCategory, AssociationCategoryTypesToIds),
      associationTypeId
    });
    if (associationTypeToSpecMap[associationTypeKey]) {
      return Object.assign({}, associationTypeToSpecMap, {
        [associationTypeKey]: mergeAssociationPropertiesForAssociationType(associationTypeToSpecMap[associationTypeKey], associationSpec)
      });
    }
    return Object.assign({}, associationTypeToSpecMap, {
      [associationTypeKey]: associationSpec
    });
  }, {});
  return Object.values(aggregatedAssociationTypes);
}
function associationsFromResponse(associations) {
  const transformedAssociations = associations.reduce((associationsByTypeId, associationsResult) => {
    const {
      associationTypeId,
      associationCategory,
      associatedObjects,
      hasMore
    } = associationsResult;
    const associationCategoryId = AssociationCategoryTypesToIds[associationCategory];
    // This will be used as the unique key for this association in the map we return
    const associationTypeKey = toCrmObjectAssociationKey({
      associationCategoryId,
      associationTypeId
    });
    const parsedAssociationLabelsAndRecords = associatedObjects.map(({
      objectId,
      objectTypeId,
      properties,
      displayName: fallbackDisplayName
    }) => {
      // This will parse the data into the correct Object Record based on objectType
      const objectRecord = createObjectRecord({
        objectId,
        objectTypeId,
        properties
      });
      // This should parse the correct "name" based on properties or our hydrated displayName
      const label = getObjectDisplayName(objectRecord, fallbackDisplayName);

      // The label being separate from the objectRecord is key for the reference resolvers using this
      return {
        label,
        objectRecord
      };
    });
    return Object.assign({}, associationsByTypeId, {
      [associationTypeKey]: {
        associationTypeId: associationTypeKey,
        associatedObjects: parsedAssociationLabelsAndRecords,
        hasMore
      }
    });
  }, {});
  return transformedAssociations;
}
export const createGetAssociationsByTaskIds = ({
  httpClient = http,
  fetchOptions: {
    associationSpecs
  }
}) => taskIds => {
  if (!associationSpecs.length) {
    return Promise.resolve([]);
  }
  return httpClient.post('tasks/v2/associations/hydrated', {
    data: {
      taskIds,
      associationTypesToHydrate: associationSpecs
    }
  }).then(results => {
    const transformedResults = results.map(result => {
      const {
        taskId,
        associations: unformattedAssociations
      } = result;
      return {
        taskId,
        associations: associationsFromResponse(unformattedAssociations)
      };
    });
    return transformedResults;
  });
};